import { Col, Input, Row } from "antd";

import { useTypedSelector } from "@hooks";
import CollapsibleCard from "@/components/CollapsibleCard";

const JobInfo = () => {
  const { offer } = useTypedSelector(state => state?.offers);
  const { offer_candidate_detail, offer_position_detail } = offer || {};

  const handleUpdateClick = () => {
    console.log("Update button clicked");
  };

  const handleSaveClick = () => {
    console.log("Save button clicked");
  };
  return (
    <>
      <CollapsibleCard
        onUpdateClick={handleUpdateClick}
        onSaveClick={handleSaveClick}
        hideUpdateBtn={true}
        header={
          <div className="flex flex-row items-center">
            <h2 className="text-xl font-semibold">Job Info Details</h2>
          </div>
        }
      >
        <h1 className="font-semibold text-lg uppercase">Job Info Details</h1>
        <div className="flex flex-row justify-between mb-6">
          {/* Section 1 */}
          <div className="flex flex-col space-y-4 w-1/2">
            <div className="flex flex-row items-center mt-6">
              <h4 className="font-semibold text-sm text-themeInputTitleColor w-1/3">
                Name
              </h4>
              <p className="font-medium text-sm text-themeInputTitleColor">
                {offer_candidate_detail?.candidate_name
                  ? offer_candidate_detail?.candidate_name
                  : "N/A"}
              </p>
            </div>
            <div className="flex flex-row items-center">
              <h4 className="font-semibold text-sm text-themeInputTitleColor w-1/3">
                Country
              </h4>
              <p className="font-medium text-sm text-themeInputTitleColor">
                {offer_candidate_detail?.country?.name
                  ? offer_candidate_detail?.country?.name
                  : "N/A"}
              </p>
            </div>

            <div className="flex flex-row items-center">
              <h4 className="font-semibold text-sm text-themeInputTitleColor w-1/3">
                Location
              </h4>
              <p className="font-medium text-sm text-themeInputTitleColor">
                {offer_candidate_detail?.location
                  ? offer_candidate_detail?.location
                  : "N/A"}
              </p>
            </div>

            <div className="flex flex-row items-center">
              <h4 className="font-semibold text-sm text-themeInputTitleColor w-1/3">
                Company Name
              </h4>
              <p className="font-medium text-sm text-themeInputTitleColor">
                {offer_candidate_detail?.company_name
                  ? offer_candidate_detail?.company_name
                  : "N/A"}
              </p>
            </div>

            <div className="flex flex-row items-center">
              <h4 className="font-semibold text-sm text-themeInputTitleColor w-1/3">
                Title
              </h4>
              <p className="font-medium text-sm text-themeInputTitleColor">
                {offer_candidate_detail?.current_title
                  ? offer_candidate_detail?.current_title
                  : "N/A"}
              </p>
            </div>

            <div className="flex flex-row items-center">
              <h4 className="font-semibold text-sm text-themeInputTitleColor w-1/3">
                Job Title
              </h4>
              <p className="font-medium text-sm text-themeInputTitleColor">
                {offer_position_detail?.job_title
                  ? offer_position_detail?.job_title
                  : "N/A"}
              </p>
            </div>

            <div className="flex flex-row items-center">
              <h4 className="font-semibold text-sm text-themeInputTitleColor w-1/3">
                BU
              </h4>
              <p className="font-medium text-sm text-themeInputTitleColor">
                {offer_position_detail?.business_unit?.name
                  ? offer_position_detail?.business_unit?.name
                  : "N/A"}
              </p>
            </div>
            <div className="flex flex-row items-center">
              <h4 className="font-semibold text-sm text-themeInputTitleColor w-1/3">
                Grade
              </h4>
              <p className="font-medium text-sm text-themeInputTitleColor">
                {offer_position_detail?.grade
                  ? offer_position_detail?.grade
                  : "N/A"}
              </p>
            </div>
          </div>

          {/* Section 2 */}
          <div className="flex flex-col space-y-4 w-1/2">
            <div className="flex flex-row items-center">
              <h4 className="font-semibold text-sm text-themeInputTitleColor w-1/3">
                Region
              </h4>
              <p className="font-medium text-sm text-themeInputTitleColor">
                {offer_position_detail?.region?.name
                  ? offer_position_detail?.region?.name
                  : "N/A"}
              </p>
            </div>
            <div className="flex flex-row items-center">
              <h4 className="font-semibold text-sm text-themeInputTitleColor w-1/3">
                Country
              </h4>
              <p className="font-medium text-sm text-themeInputTitleColor">
                {offer_position_detail?.country?.name
                  ? offer_position_detail?.country?.name
                  : "N/A"}
              </p>
            </div>
            <div className="flex flex-row items-center">
              <h4 className="font-semibold text-sm text-themeInputTitleColor w-1/3">
                Type
              </h4>
              <p className="font-medium text-sm text-themeInputTitleColor">
                {offer_position_detail?.grade_type
                  ? offer_position_detail?.grade_type
                  : "N/A"}
              </p>
            </div>

            <div className="flex flex-row items-center">
              <h4 className="font-semibold text-sm text-themeInputTitleColor w-1/3">
                Job Function
              </h4>
              <p className="font-medium text-sm text-themeInputTitleColor">
                {offer_position_detail?.job_function?.name
                  ? offer_position_detail?.job_function?.name
                  : "N/A"}
              </p>
            </div>

            <div className="flex flex-row items-center">
              <h4 className="font-semibold text-sm text-themeInputTitleColor w-1/3">
                Job Sub Function
              </h4>
              <p className="font-medium text-sm text-themeInputTitleColor">
                {offer_position_detail?.sub_job_function?.name
                  ? offer_position_detail?.sub_job_function?.name
                  : "N/A"}
              </p>
            </div>

            <div className="flex flex-row items-center">
              <h4 className="font-semibold text-sm text-themeInputTitleColor w-1/3">
                City
              </h4>
              <p className="font-medium text-sm text-themeInputTitleColor">
                {offer_position_detail?.city
                  ? offer_position_detail?.city
                  : "N/A"}
              </p>
            </div>

            {/* Add more rows for Section 2 as needed */}
          </div>
        </div>
      </CollapsibleCard>
    </>
  );
};

export default JobInfo;
