import moment from "moment";
import { LoadingOutlined } from "@ant-design/icons";
import { FC, useEffect, useState } from "react";
import { Button, Col, DatePicker, Input, Row, Select } from "antd";
import Checkbox from "antd/lib/checkbox/Checkbox";

import {
  ICountry,
  ICurrency,
  IJobGrade,
  ILongTermPlan,
  IModal,
  IPlanType,
} from "@types";
import Modal from "@components/Modal";
import { getDaysOfMonth, showSuccessPopup } from "@utils";
import {
  installment_types,
  grant_types,
  vesting_types,
  grant_based_types,
  holding_periods,
} from "../config";
import {
  ErrorServices,
  useCreateLongTermPlanMutation,
  useFetchAllCompanyJobGradesQuery,
  useFetchCompanyCountriesQuery,
  useFetchCurrenciesQuery,
  useFetchLongTermPlanTypesQuery,
  useUpdateLongTermPlanMutation,
} from "@services";
import { useTypedSelector } from "@hooks";
import CustomModal from "@/components/customModel";

const { Option } = Select;

interface IAddLongTermPlan extends IModal {
  selectedLongTermPlan: ILongTermPlan | null;
  setSelectedLongTermPlan: React.Dispatch<
    React.SetStateAction<ILongTermPlan | null>
  >;
}

interface IFormValues {
  name: string;
  plan_type_id: number | null;
  currency_id: number | null;
  country_ids: number[];
  grades: string[];
  holding_period: boolean;
  is_installment: boolean;
  is_amount: boolean;
  is_adhoc: boolean;
  date_of_grant: number | null;
  month_of_grant: number | null;
  installment_type: string;
  year_of_grant?: number | null;
  grant_type: string;
  vesting_type: string;
  grant_based_type: string;
  holding_period_no: number | null;
}

const AddLongTermPlan: FC<IAddLongTermPlan> = ({
  isVisible,
  setIsVisible,
  selectedLongTermPlan,
  setSelectedLongTermPlan,
}) => {
  const [global, setGlobal] = useState(false);
  const { company } = useTypedSelector(state => state.auth?.user!);
  const { id: company_id } = company!;
  const { data: planTypesData, isLoading: isFetchingTypes } =
    useFetchLongTermPlanTypesQuery(null);
  const { data: planTypes } = planTypesData || {};
  const { data: currenciesData, isLoading: isFetchingCurrencies } =
    useFetchCurrenciesQuery(null);
  const { data: currencies } = currenciesData || {};
  const { data: jobGradesData, isLoading: isFetchingGrades } =
    useFetchAllCompanyJobGradesQuery({ company_id });
  const { data: jobGrades } = jobGradesData || {};
  const { data: countriesData, isLoading: isFetchingCountries } =
    useFetchCompanyCountriesQuery({
      company_id,
    });
  const { data: countries } = countriesData || {};
  const [createLongTermPlan, { isLoading: isCreating }] =
    useCreateLongTermPlanMutation();
  const [updateLongTermPlan, { isLoading: isUpdating }] =
    useUpdateLongTermPlanMutation();
  const [longTermPlan, setLongTermPlan] = useState<IFormValues>({
    name: "",
    plan_type_id: null,
    currency_id: null,
    country_ids: [],
    grades: [],
    holding_period: false,
    is_installment: false,
    is_amount: false,
    is_adhoc: false,
    date_of_grant: null,
    month_of_grant: null,
    installment_type: "",
    year_of_grant: null,
    grant_type: "",
    vesting_type: "",
    grant_based_type: "",
    holding_period_no: null,
  });

  useEffect(() => {
    if (selectedLongTermPlan) {
      const {
        countries,
        currency: currency_id,
        holding_period,
        type,
        is_installment,
        is_amount,
        is_adhoc,
      } = selectedLongTermPlan;
      const longTermPlan: any = {
        ...selectedLongTermPlan,
        country_ids: countries?.map(({ id }) => id),
        currency_id,
        holding_period: !!holding_period,
        plan_type_id: type?.id,
        year_of_grant: moment().year(),
        vesting_type: is_installment ? "INSTALLMENT" : "CLIFF",
        grant_based_type: is_amount ? "VALUE" : "UNIT",
        grant_type: is_adhoc ? "ADHOC" : "ANNUAL",
      };

      delete longTermPlan?.countries;
      delete longTermPlan?.currency;
      delete longTermPlan?.id;
      delete longTermPlan?.is_global;
      delete longTermPlan?.is_all_grade;
      delete longTermPlan?.type;
      setLongTermPlan(longTermPlan);
    }
    return () => {
      setSelectedLongTermPlan(null);
    };
  }, [selectedLongTermPlan, setSelectedLongTermPlan]);

  const handleSubmit = async () => {
    try {
      const body = {
        ...longTermPlan,
        month_of_grant: longTermPlan?.month_of_grant || 1,
        date_of_grant: longTermPlan?.date_of_grant || 1,
        installment_type:
          longTermPlan?.vesting_type == "CLIFF"
            ? "MONTHLY"
            : longTermPlan?.installment_type,
        country_ids: global ? [] : longTermPlan?.country_ids,
      };
      delete body.year_of_grant;
      if (selectedLongTermPlan) {
        await editShortTermPlan(body);
      } else {
        await addLongTermPlan(body);
      }
      setIsVisible(false);

      showSuccessPopup({
        title: `Long Team Incentives Plan ${
          selectedLongTermPlan ? "Updated!" : "Created!"
        }`,
        desc: `You have successfully ${
          selectedLongTermPlan ? "updated the" : "created new"
        } long term plan.`,
        role: "client",
      });
    } catch (error) {
      ErrorServices(error);
      console.log(error);
    }
  };

  const addLongTermPlan = async (body: IFormValues) =>
    await createLongTermPlan({ company_id, body }).unwrap();

  const editShortTermPlan = async (body: IFormValues) =>
    await updateLongTermPlan({
      company_id,
      id: selectedLongTermPlan?.id,
      body,
    }).unwrap();

  return (
    <CustomModal
      width="w-8/12"
      height="650px"
      footer={
        <>
          <button
            className="bg-themeGrey text-themeBlack font-medium text-lg py-2 px-14 rounded-md"
            onClick={() => setIsVisible(false)}
          >
            Cancel
          </button>
          <button
            className={`bg-themeBlue text-white font-medium text-lg py-2 px-14 rounded-md ${
              isCreating || isUpdating ? "opacity-50 cursor-not-allowed" : ""
            }`}
            disabled={
              !longTermPlan?.name ||
              !longTermPlan?.plan_type_id ||
              !longTermPlan?.currency_id
              // !longTermPlan?.month_of_grant ||
              // !longTermPlan?.date_of_grant ||
              // !longTermPlan?.installment_type ||
            }
            onClick={handleSubmit}
          >
            {isCreating || isUpdating ? (
              <LoadingOutlined className="spinner" />
            ) : selectedLongTermPlan ? (
              "Update"
            ) : (
              "Create"
            )}
          </button>
        </>
      }
      title={`${selectedLongTermPlan ? "Update" : "Create"} LTI Plan`}
      isVisible={isVisible}
    >
      <>
        <Row justify="space-between">
          <Col span={11}>
            <label className="font-medium text-themeInputTitleColor text-lg leading-10 mt-4 ">
              Plan Name
            </label>
            <Input
              className={`w-full shadow-sm my-2 font-semibold text-sm outline-none border border-themeInputBorder bg-white px-3 py-3 rounded-md`}
              value={longTermPlan?.name}
              onChange={event =>
                setLongTermPlan(prev => ({
                  ...prev,
                  name: event?.target.value,
                }))
              }
              size="large"
              placeholder="Enter plan name here..."
            />
          </Col>
          <Col span={11}>
            <label className="font-medium text-themeInputTitleColor text-lg leading-10 mt-4 ">
              Vesting Type
            </label>
            <Select
              size="large"
              className="custom-select"
              showArrow={true}
              showSearch={false}
              value={longTermPlan?.vesting_type || undefined}
              onChange={vesting_type => {
                if (vesting_type == "INSTALLMENT") {
                  setLongTermPlan(prev => ({ ...prev, is_installment: true }));
                } else {
                  setLongTermPlan(prev => ({ ...prev, is_installment: false }));
                }
                setLongTermPlan(prev => ({ ...prev, vesting_type }));
              }}
              placeholder="Select installment type from here..."
            >
              {vesting_types.map(type => (
                <Option key={type.value} value={type.value}>
                  {type.label}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>
        <Row justify="space-between">
          <Col span={11}>
            <label className="font-medium text-themeInputTitleColor text-lg leading-10 mt-4 ">
              Holding Period
            </label>
            <Select
              size="large"
              className="custom-select"
              showArrow={true}
              showSearch={false}
              value={longTermPlan?.holding_period_no || undefined}
              onChange={count => {
                setLongTermPlan(prev => ({
                  ...prev,
                  holding_period_no: count,
                  holding_period: true,
                }));
              }}
              placeholder="Select grant based type from here..."
            >
              {holding_periods.map(type => (
                <Option key={type.value} value={type.value}>
                  {type.label}
                </Option>
              ))}
            </Select>
          </Col>
          <Col span={11}>
            <label className="font-medium text-themeInputTitleColor text-lg leading-10 mt-4 ">
              Installment Schedule
            </label>
            <Select
              size="large"
              className="custom-select"
              showArrow={true}
              showSearch={false}
              disabled={longTermPlan?.vesting_type == "CLIFF"}
              value={longTermPlan?.installment_type || undefined}
              onChange={installment_type =>
                setLongTermPlan(prev => ({ ...prev, installment_type }))
              }
              placeholder="Select installment type from here..."
            >
              {installment_types.map(type => (
                <Option key={type.value} value={type.value}>
                  {type.label}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>
        <Row justify="space-between">
          <Col span={11}>
            <label className="font-medium text-themeInputTitleColor text-lg leading-10 mt-4 ">
              Type of Stock
            </label>
            <Select
              loading={isFetchingTypes}
              size="large"
              className="custom-select"
              showArrow={true}
              showSearch={false}
              value={longTermPlan?.plan_type_id || undefined}
              onChange={plan_type_id =>
                setLongTermPlan(prev => ({ ...prev, plan_type_id }))
              }
              placeholder="Select stock type from here..."
            >
              {planTypes?.map((type: IPlanType) => (
                <Option key={type.id} value={type.id}>
                  {type.name}
                </Option>
              ))}
            </Select>
          </Col>
          <Col span={11}>
            <label className="font-medium text-themeInputTitleColor text-lg leading-10 mt-4 ">
              Currency
            </label>
            <Select
              className="custom-select"
              showArrow={true}
              showSearch={true}
              loading={isFetchingCurrencies}
              filterOption={(input: any, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              value={longTermPlan?.currency_id || undefined}
              onChange={currency_id =>
                setLongTermPlan(prev => ({
                  ...prev,
                  currency_id,
                }))
              }
              size="large"
              placeholder="Select currency from here..."
            >
              {currencies?.map(({ id, name, code }: ICurrency) => (
                <Option key={id} value={id}>
                  {`${name} (${code})`}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>
        <Row justify="space-between">
          {/* <Col span={11}>
            <label className="font-medium text-themeInputTitleColor text-lg leading-10 mt-4 ">
              Month of Grant
            </label>
            <br />
            <DatePicker
              className={`w-full shadow-sm my-2 font-semibold text-sm outline-none border border-themeInputBorder bg-white px-3 py-3 rounded-md`}
              size="large"
              picker="month"
              value={
                selectedLongTermPlan
                  ? moment(
                      `${longTermPlan?.year_of_grant}-${longTermPlan?.month_of_grant}`
                    )
                  : undefined
              }
              onChange={date => {
                setLongTermPlan(prev => ({
                  ...prev,
                  month_of_grant: moment(date).month() + 1,
                  year_of_grant: moment(date).year(),
                }));
              }}
              placeholder="Select month of grant from here..."
            />
          </Col>
          <Col span={11}>
            <label className="font-medium text-themeInputTitleColor text-lg leading-10 mt-4 ">
              Date of Grant
            </label>

            <Select
              loading={isFetchingGrades}
              showArrow={true}
              className="custom-select"
              showSearch={true}
              size="large"
              value={longTermPlan?.date_of_grant || undefined}
              onChange={date_of_grant =>
                setLongTermPlan(prev => ({ ...prev, date_of_grant }))
              }
              placeholder="Select date of grant from here..."
            >
              {getDaysOfMonth({
                month: longTermPlan?.month_of_grant || undefined,
                year: longTermPlan?.year_of_grant || undefined,
              })?.map(day => (
                <Option key={day} value={day}>
                  {day}
                </Option>
              ))}
            </Select>
          </Col> */}

          <Col span={11}>
            <label className="font-medium text-themeInputTitleColor text-lg leading-10 mt-4 ">
              Grades
            </label>
            <Select
              className="custom-select"
              value={longTermPlan?.grades}
              // onChange={grades =>
              //   setLongTermPlan(prev => ({ ...prev, grades }))
              // }

              onChange={selectedGrades => {
                if (selectedGrades.includes("All")) {
                  if (longTermPlan?.grades?.length === jobGrades.length) {
                    setLongTermPlan(prev => ({ ...prev, grades: [] }));
                  } else {
                    setLongTermPlan(prev => ({
                      ...prev,
                      grades: jobGrades.map(({ grade }) => grade),
                    }));
                  }
                } else {
                  // Normal selection
                  setLongTermPlan(prev => ({
                    ...prev,
                    grades: selectedGrades,
                  }));
                }
              }}
              loading={isFetchingGrades}
              showArrow={true}
              showSearch={true}
              size="large"
              mode="multiple"
              placeholder="Select grades from here..."
            >
              <Option key="all" value="All">
                Select All
              </Option>
              {jobGrades?.map(({ id, grade }: IJobGrade) => (
                <Option key={id} value={grade}>
                  {grade}
                </Option>
              ))}
            </Select>
          </Col>

          <Col span={11}>
            <label className="font-medium text-themeInputTitleColor text-lg leading-10 mt-4 ">
              Countries
            </label>
            {/* <Select
              className="custom-select"
              disabled={global}
              filterOption={(input: any, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              loading={isFetchingCountries}
              showArrow={true}
              showSearch={true}
              mode="multiple"
              size="large"
              onChange={country_ids =>
                setLongTermPlan(prev => ({ ...prev, country_ids }))
              }
              value={longTermPlan?.country_ids}
              placeholder="Select countries from here..."
            >
              {countries?.map(({ id, name }: ICountry) => (
                <Option key={id} value={id}>
                  {name}
                </Option>
              ))}
            </Select> */}

            <Select
              className="custom-select"
              filterOption={(input: any, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              loading={isFetchingCountries}
              showArrow={true}
              showSearch={true}
              mode="multiple"
              size="large"
              onChange={selectedCountryIds => {
                if (selectedCountryIds.includes("Global")) {
                  setLongTermPlan(prev => ({
                    ...prev,
                    country_ids: countries.map(({ id }) => id),
                  }));
                  setGlobal(true);
                } else {
                  setLongTermPlan(prev => ({
                    ...prev,
                    country_ids: selectedCountryIds,
                  }));
                  setGlobal(false);
                }
              }}
              value={global ? ["Global"] : longTermPlan?.country_ids} // If Global is selected, show "All", otherwise show selected countries
              placeholder="Select countries from here..."
            >
              {/* Add "All" option */}
              <Option key="all" value="Global">
                Global
              </Option>

              {/* Render country options */}
              {countries?.map(({ id, name }: ICountry) => (
                <Option key={id} value={id}>
                  {name}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>

        <Row justify="space-between">
          <Col span={11}>
            <label className="font-medium text-themeInputTitleColor text-lg leading-10 mt-4 ">
              Grant Type
            </label>
            <Select
              size="large"
              className="custom-select"
              showArrow={true}
              showSearch={false}
              value={longTermPlan?.grant_type || undefined}
              onChange={grant_type => {
                if (grant_type == "ADHOC") {
                  setLongTermPlan(prev => ({ ...prev, is_adhoc: true }));
                } else {
                  setLongTermPlan(prev => ({ ...prev, is_adhoc: false }));
                }
                setLongTermPlan(prev => ({ ...prev, grant_type }));
              }}
              placeholder="Select grant type from here..."
            >
              {grant_types.map(type => (
                <Option key={type.value} value={type.value}>
                  {type.label}
                </Option>
              ))}
            </Select>
          </Col>
          <Col span={11}>
            <label className="font-medium text-themeInputTitleColor text-lg leading-10 mt-4">
              Date of Grant
            </label>
            <br />
            <DatePicker
              className={`w-full shadow-sm my-2 font-semibold text-sm outline-none border border-themeInputBorder bg-white px-3 py-3 rounded-md`}
              size="large"
              disabled={longTermPlan?.grant_type === "ADHOC"}
              picker="date"
              value={
                longTermPlan?.month_of_grant && longTermPlan?.date_of_grant
                  ? moment(
                      `${new Date().getFullYear()}-${
                        longTermPlan.month_of_grant
                      }-${longTermPlan.date_of_grant}`,
                      "YYYY-MM-DD"
                    )
                  : undefined
              }
              onChange={date => {
                if (date) {
                  setLongTermPlan(prev => ({
                    ...prev,
                    month_of_grant: moment(date).month() + 1, // Update month (1-based index)
                    date_of_grant: moment(date).date(), // Update day
                  }));
                }
              }}
              placeholder="Select date of grant from here..."
              format="MM/DD" // Show only the month and day format
            />
          </Col>
        </Row>

        <Row justify="space-between">
          <Col span={11}>
            <label className="font-medium text-themeInputTitleColor text-lg leading-10 mt-4 ">
              Grant Based Type
            </label>
            <Select
              size="large"
              className="custom-select"
              showArrow={true}
              showSearch={false}
              value={longTermPlan?.grant_based_type || undefined}
              onChange={grant_based_type => {
                if (grant_based_type === "VALUE") {
                  setLongTermPlan(prev => ({ ...prev, is_amount: true }));
                }
                if (grant_based_type === "UNIT") {
                  setLongTermPlan(prev => ({ ...prev, is_amount: false }));
                }
                setLongTermPlan(prev => ({ ...prev, grant_based_type }));
              }}
              placeholder="Select grant based type from here..."
            >
              {grant_based_types.map(type => (
                <Option key={type.value} value={type.value}>
                  {type.label}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>

        {/* <Row justify="space-between" className="my-6">
          <Col className="align-center-vertically" span={24}>
            <Checkbox
              checked={global}
              onChange={e => setGlobal(e.target.checked)}
            >
              <span className={"font-medium text-lg text-themeInputTitleColor"}>
                Global
              </span>
            </Checkbox>
            <Checkbox
              checked={longTermPlan?.holding_period}
              onChange={e =>
                setLongTermPlan(prev => ({
                  ...prev,
                  holding_period: e.target.checked,
                }))
              }
            >
              <span className={"font-medium text-lg text-themeInputTitleColor"}>
                Period
              </span>
            </Checkbox>
            <Checkbox
              checked={longTermPlan?.is_installment}
              onChange={e =>
                setLongTermPlan(prev => ({
                  ...prev,
                  is_installment: e.target.checked,
                }))
              }
            >
              <span className={"font-medium text-lg text-themeInputTitleColor"}>
                Installment
              </span>
            </Checkbox>
            <Checkbox
              checked={longTermPlan?.is_amount}
              onChange={e =>
                setLongTermPlan(prev => ({
                  ...prev,
                  is_amount: e.target.checked,
                }))
              }
            >
              <span className={"font-medium text-lg text-themeInputTitleColor"}>
                Amount
              </span>
            </Checkbox>
            <Checkbox
              checked={longTermPlan?.is_adhoc}
              onChange={e =>
                setLongTermPlan(prev => ({
                  ...prev,
                  is_adhoc: e.target.checked,
                }))
              }
            >
              <span className={"font-medium text-lg text-themeInputTitleColor"}>
                Adhoc
              </span>
            </Checkbox>
          </Col>
        </Row> */}
      </>
    </CustomModal>
  );
};

export default AddLongTermPlan;
