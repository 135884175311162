import { Col, message, Row } from "antd";
import { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import Table from "@components/Table";
import { getColumns } from "./config";
import AddCashAllowance from "./AddCashAllowance";
import {
  useFetchCashAllowancesQuery,
  useDeleteCashAllowanceMutation,
  ErrorServices,
  useUploadCashAllowancesMutation,
  cashAllowancesApi,
} from "@services";
import { ICashAllowance } from "@types";
import CashAllowanceVersions from "./Versions";
import Upload from "@components/Upload";
import { useTypedSelector } from "@hooks";
import { checkPermission } from "@/utils";
import { permissions } from "@/router";
import { MdOutlineFileDownload, MdOutlineFileUpload } from "react-icons/md";
import CustomButton from "@/components/customButton";
import SearchBox from "@/components/searchInput";

const CashAllowances = () => {
  const { company } = useTypedSelector(state => state.auth?.user!);
  const { id: company_id } = company!;
  const dispatch = useDispatch();
  const cashAllowance_id = useRef<any>(null);
  const [page, setPage] = useState(1);
  const [isVisible, setIsVisible] = useState(false);
  const [searchInput, setSearchInput] = useState("");

  const [isUploadModal, setIsUploadModal] = useState(false);
  const [isVersionsModal, setIsVersionsModal] = useState(false);
  const [selectedCashAllowance, setSelectedCashAllowance] =
    useState<null | ICashAllowance>(null);
  const { data: cashAllowancesData, isLoading } = useFetchCashAllowancesQuery({
    company_id,
    page,
  });
  const [deleteCashAllowance, { isLoading: isDeleting }] =
    useDeleteCashAllowanceMutation();
  const { data, pagination } = cashAllowancesData || {};
  const [uploadFile, setUploadFile] = useState({
    file: null,
    active: false,
  });
  const [uploadJobGrades, { isLoading: isUploading }] =
    useUploadCashAllowancesMutation();

  const removeCashAllowance = async (id: number) => {
    try {
      cashAllowance_id.current = id;
      await deleteCashAllowance({ company_id, id }).unwrap();
      message.success("Cash allowance deleted successfully!");
    } catch (error) {
      cashAllowance_id.current = null;
      ErrorServices(error);
      console.log(error);
    }
  };

  const editCashAllowance = (cashAllowance: ICashAllowance) => {
    setSelectedCashAllowance(cashAllowance);
    setIsVisible(true);
  };

  const handleUpload = async () => {
    const { file, active }: { file: any; active: boolean } = uploadFile;
    setIsUploadModal(false);
    try {
      const formData = new FormData();
      formData.append("attachment", file!, file?.name!);
      const res = await uploadJobGrades({
        company_id,
        active,
        body: formData,
      }).unwrap();
      message.success(res?.message);
    } catch (error) {
      message.error(error?.message);
      console.log(error);
    }
  };

  const download = (id?: number) => {
    dispatch(
      cashAllowancesApi.endpoints.downloadCashAllowances.initiate(
        {
          company_id,
          version_id: id || null,
        },
        {
          subscribe: false,
          forceRefetch: true,
        }
      )
    );
  };

  const columns = getColumns({
    removeCashAllowance,
    isDeleting,
    cashAllowance_id,
    editCashAllowance,
  });

  return (
    <>
      {isUploadModal && (
        <Upload
          title="Upload Cash Allowances"
          onSubmit={handleUpload}
          isVisible={isUploadModal}
          setIsVisible={setIsUploadModal}
          file={uploadFile}
          setFile={setUploadFile}
        />
      )}
      {isVisible && (
        <AddCashAllowance
          selectedCashAllowance={selectedCashAllowance}
          setSelectedCashAllowance={setSelectedCashAllowance}
          isVisible={isVisible}
          setIsVisible={setIsVisible}
        />
      )}
      {isVersionsModal && (
        <CashAllowanceVersions
          isVisible={isVersionsModal}
          setIsVisible={setIsVersionsModal}
          download={download}
        />
      )}

      <div className="bg-themeCore ">
        <div className="jobCreationBar bg-white px-4 py-6 rounded-lg flex justify-between items-center ">
          <div className="title text-themeBlue  font-medium text-3xl">
            Cash Allowances
          </div>

          <div className="flex flex-row justify-between">
            {checkPermission(permissions.UPDATE_CASH_ALLOWANCE) ||
            checkPermission(permissions.CREATE_CASH_ALLOWANCE) ? (
              <CustomButton
                isLoading={isUploading}
                onClick={() => setIsUploadModal(true)}
                btnName="Upload"
                customClasses="shadow-md font-medium border py-1 px-6 !border-themeBorder"
                iconPosition="left"
              >
                <MdOutlineFileUpload
                  size={20}
                  className="text-white font-bold"
                />
              </CustomButton>
            ) : null}

            <CustomButton
              onClick={() => download()}
              btnName="Download"
              customClasses="shadow-md font-medium border py-1 px-6 !border-themeBorder ml-4"
              iconPosition="right"
            >
              <MdOutlineFileDownload
                size={20}
                className="text-white font-bold"
              />
            </CustomButton>

            <CustomButton
              onClick={() => setIsVersionsModal(true)}
              btnName="Versions"
              customClasses="shadow-md font-medium border py-1 px-6 !border-themeBorder ml-4"
              iconPosition="left"
            ></CustomButton>
            {checkPermission(permissions.CREATE_CASH_ALLOWANCE) && (
              <CustomButton
                onClick={() => setIsVisible(true)}
                btnName="Create Cash Allowance"
                customClasses="shadow-md font-medium border py-1 px-6 !border-themeBorder ml-4"
                iconPosition="left"
              ></CustomButton>
            )}
          </div>
        </div>
      </div>
      <div className="jobCreationListing mt-10 bg-white px-14 py-6 rounded-lg">
        <SearchBox
          placeholder="Type to search..."
          value={searchInput}
          onChange={value => setSearchInput(value)}
        />
        <Table
          data={data}
          columns={columns}
          isLoading={isLoading}
          pagination={true}
          count={pagination?.count}
          onChangePage={setPage}
          page={page}
        />
      </div>
    </>
  );
};
export default CashAllowances;
