import {
  DeleteOutlined,
  EditOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { TableColumnsType } from "antd";
import _ from "lodash";

import { ILongTermPlan } from "@types";
import { checkPermission } from "@/utils";
import { permissions } from "@/router";

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const installment_types = [
  {
    value: "MONTHLY",
    label: "Monthly",
  },
  {
    value: "QUARTERLY",
    label: "Quarterly",
  },
  { value: "SEMI_ANNUALLY", label: "Semi Annually" },
  {
    value: "ANNUALLY",
    label: "Annually",
  },
];

export const grant_types = [
  {
    value: "ADHOC",
    label: "Adhoc",
  },
  {
    value: "ANNUAL",
    label: "Annual",
  },
];

export const vesting_types = [
  {
    value: "CLIFF",
    label: "Cliff",
  },
  {
    value: "INSTALLMENT",
    label: "Installment",
  },
];

export const grant_based_types = [
  {
    value: "VALUE",
    label: "Value",
  },
  {
    value: "UNITS",
    label: "Units",
  },
];

export const holding_periods = [
  {
    value: 1,
    label: 1,
  },
  {
    value: 2,
    label: 2,
  },
  {
    value: 3,
    label: 3,
  },
  {
    value: 4,
    label: 4,
  },
  {
    value: 5,
    label: 5,
  },
];

export const options = [
  { id: "holding_period", label: "Period" },
  { id: "is_installment", label: "Installment" },
  { id: "is_amount", label: "Amount" },
  { id: "is_adhoc", label: "Adhoc" },
];

export const getColumns = ({
  removeLongTermPlan,
  longTermPlan_id,
  isDeleting,
  editLongTermPlan,
}: {
  removeLongTermPlan: (id: number) => Promise<void>;
  longTermPlan_id: React.MutableRefObject<any>;
  isDeleting: boolean;
  editLongTermPlan: (longTermPlan: ILongTermPlan) => void;
}): TableColumnsType<any> => [
  {
    title: "type of stock",
    dataIndex: "type",
    key: "type",
    width: 300,
    render: (type: { id: number; name: string }) => (
      <div className="text-wrap">{type?.name}</div>
    ),
  },
  {
    title: "name",
    dataIndex: "name",
    key: "name",
    width: 200,
  },
  {
    title: "grades",
    key: "grades",
    width: 250,
    render: (plan: ILongTermPlan) => (
      <div className="text-wrap">
        {plan?.is_all_grade ? "All" : plan?.grades.join(", ")}
      </div>
    ),
  },
  {
    title: "holding period",
    dataIndex: "holding_period",
    key: "holding_period",
    width: 200,
  },
  {
    title: "vesting type",
    dataIndex: "is_installment",
    key: "is_installment",
    width: 200,
    render: (is_installment: boolean) =>
      is_installment ? "Installment" : "Cliff",
  },
  {
    title: "installment type",
    dataIndex: "installment_type",
    key: "installment_type",
    width: 200,
    render: (type: string) => _.replace(_.startCase(_.toLower(type)), "_", " "),
  },
  {
    title: "date of grant",
    key: "date",
    width: 200,
    render: (plan: ILongTermPlan) =>
      `${plan?.date_of_grant} ${months[plan?.month_of_grant - 1]}`,
  },
  {
    title: "equity type",
    dataIndex: "is_amount",
    key: "is_amount",
    width: 200,
    render: (is_amount: boolean) => (is_amount ? "Amount" : "Unit"),
  },
  ...(!checkPermission([
    permissions.DELETE_COMPANY_LTI,
    permissions.UPDATE_COMPANY_LTI,
  ])
    ? []
    : ([
        {
          title: "Actions",
          key: "actions",
          width: 160,
          fixed: "right",
          render: (longTermPlan: ILongTermPlan) => (
            <>
              {checkPermission(permissions.UPDATE_COMPANY_LTI) && (
                <div
                  onClick={event => {
                    event.stopPropagation();
                    editLongTermPlan(longTermPlan);
                  }}
                  className="table__action__btn table__action__btn--client"
                >
                  <EditOutlined style={{ color: "#1A8245" }} /> Edit
                </div>
              )}
              {checkPermission(permissions.DELETE_COMPANY_LTI) && (
                <div
                  onClick={event => {
                    event.stopPropagation();
                    removeLongTermPlan(longTermPlan?.id);
                  }}
                  className="table__action__btn table__action__btn--delete"
                >
                  {isDeleting &&
                  longTermPlan?.id === longTermPlan_id?.current ? (
                    <LoadingOutlined color="#B62424" className="spinner" />
                  ) : (
                    <>
                      <DeleteOutlined style={{ color: "#B62424" }} /> Delete
                    </>
                  )}
                </div>
              )}
            </>
          ),
        },
      ] as any)),
];
