import { Button, Col, Input, message, Row, Select } from "antd";
import { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import {
  CheckOutlined,
  LoadingOutlined,
  RightCircleFilled,
} from "@ant-design/icons";
import { FaChevronDown, FaChevronUp, FaEdit } from "react-icons/fa";

import { handleCandidateDetails, handleStage } from "@store/offers";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { useTypedSelector } from "@hooks";
import {
  ErrorServices,
  useCreateCandidateDetailsMutation,
  useFetchAllJobFunctionsQuery,
  useFetchAllSectorsQuery,
  useFetchCompanyCountriesQuery,
  useFetchCurrenciesQuery,
} from "@services";
import {
  ICountry,
  ICurrency,
  IIndustry,
  IJobFunctionItem,
  ISector,
  ISubIndustry,
  ISubJobFunctionItem,
} from "@types";
import CustomButton from "@/components/customButton";
import CollapsibleCard from "@/components/CollapsibleCard";
import { useHistory } from "react-router-dom";

const { Option } = Select;

const CandidateDetails = ({
  offerModeller,
  onResponse,
  detailedView,
}: {
  offerModeller?: boolean;
  onResponse: any;
  detailedView: any;
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const draft = useRef<boolean>(false);
  const { offer } = useTypedSelector(state => state?.offers);
  const [isOpen, setIsOpen] = useState(false);

  const [customView, setCustomView] = useState(detailedView);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };
  const readOnly =
    offerModeller || (offer && offer?.status !== "DRAFTED") ? true : false;
  const { company } = useTypedSelector(state => state.auth?.user!);
  const { id: company_id } = company!;
  const is_create = window.location.pathname.includes("/create");
  const [subJobFunctions, setSubJobFunctions] = useState<ISubJobFunctionItem[]>(
    []
  );
  const [industries, setIndustries] = useState<IIndustry[]>([]);
  const [subIndustries, setSubIndustries] = useState<ISubIndustry[]>([]);
  const { candidateDetails } = useTypedSelector(state => state.offers);

  const { data: sectorsData, isLoading: isLoadingSectors } =
    useFetchAllSectorsQuery(null);
  const { data: sectors } = sectorsData || {};
  const { countries } = useTypedSelector(state => state.countries);
  const { data: jobFunctionsData, isLoading: isLoadingJobFunctions } =
    useFetchAllJobFunctionsQuery(null);
  const { data: jobFunctions } = jobFunctionsData || {};
  const { data: currenciesData, isLoading: isFetchingCurrencies } =
    useFetchCurrenciesQuery(null);
  const { data: currencies } = currenciesData || {};
  const [createCandidateDetails, { isLoading: isCreating }] =
    useCreateCandidateDetailsMutation();
  const {
    candidate_name,
    company_name,
    current_title,
    current_grade,
    business_unit,
    region,
    location,
    relevant_experience,
    total_experience,
    is_critical_skills,
    critical_skills,
    is_referral,
    referral_employees,
    country_id,
    currency_id,
    current_job_function,
    current_sub_job_function_id,
    sector,
    industry,
    sub_industry_id,
  } = candidateDetails;

  const disabled =
    !candidate_name ||
    !company_name ||
    !current_title ||
    !location ||
    !total_experience ||
    !country_id ||
    !currency_id ||
    !current_job_function ||
    !industry;

  let country_name = countries && countries.filter(x => x.id === country_id);
  let currency_current =
    currencies && currencies.filter(x => x.id === currency_id);
  let sub_function =
    subJobFunctions &&
    subJobFunctions.filter(x => x.id === current_sub_job_function_id);
  let sub_industry_name =
    subIndustries && subIndustries.filter(x => x.id === sub_industry_id);

  useEffect(() => {
    // if (!is_create) {
      const jobFunction = jobFunctions?.find(
        (func: Partial<IJobFunctionItem>) =>
          func.id === current_job_function?.id
      );
      const selected_sector = sectors?.find(
        (x: Partial<ISector>) => x.id === sector?.id
      );
      setSubJobFunctions(jobFunction?.job_sub_functions);
      setIndustries(selected_sector?.industries);
      setSubIndustries(
        selected_sector?.industries?.find(
          (x: Partial<IIndustry>) => x?.id === industry?.id
        )?.sub_industries
      );
    // }
  }, [
    current_job_function?.id,
    industry?.id,
    is_create,
    jobFunctions,
    sector?.id,
    sectors,
  ]);

  // const handleChange = (event: any) => {
  //   if (readOnly) return;

  //   console.log(event.target.value);
  //   console.log( event.target.name + '==> vasu');
  //   if (
  //     event.target.value >= 0 && event.target.value >  total_experience &&
  //     event.target.name == "relevant_experience"
  //   ) {
  //     message.error("Total Experience Must be geater!");
  //   } else {
  //     dispatch(
  //       handleCandidateDetails({
  //         [event.target.name]: event.target.value,
  //       })
  //     );
  //   }
  // };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (readOnly) return;

    const { name, value } = event.target;
    console.log(value);

    // Parse the value to ensure numeric comparison works as expected
    const numericValue = parseFloat(value);

    if (
      name === "relevant_experience" &&
      numericValue >= 0 &&
      numericValue > total_experience
    ) {
      message.error(
        "Total Experience must be greater than or equal to Relevant Experience!"
      );
      return;
    }

    dispatch(
      handleCandidateDetails({
        [name]: value,
      })
    );
  };

  const handleSubmit = async (is_draft: boolean) => {
    try {
      draft.current = is_draft;
      const body: any = {
        ...candidateDetails,
        current_job_function_id: current_job_function?.id,
        industry_id: industry?.id,
        sector_id: sector?.id,
        critical_skills:
          !is_critical_skills || !critical_skills || !critical_skills?.length
            ? null
            : critical_skills,
        referral_employees: is_referral ? referral_employees : null,
        is_draft,
      };
      delete body["current_job_function"];
      delete body["industry"];
      delete body["sector"];
      await createCandidateDetails({
        company_id,
        id: offer?.id,
        body,
      }).unwrap();
      message.success("Candidate details saved successfully");
    } catch (err) {
      console.log(err);
      ErrorServices(err);
    }
  };

  const handleUpdateClick = () => {
    onResponse(1);
    // setCustomView(!customView);
  };

  const handleSaveClick = () => {
    console.log("Save button clicked");
  };

  useEffect(() => {
    if (currency_id === null) {
      let value = company?.currency?.id;
      handleChange({
        target: { name: "currency_id", value },
      });
    }
  }, []);

  return (
    <>
      {customView ? (
        <CollapsibleCard
          onUpdateClick={handleUpdateClick}
          onSaveClick={handleSaveClick}
          header={
            <div className="flex flex-row items-center">
              <h2 className="text-xl font-semibold">Candidate Details</h2>
              {candidate_name && (
                <p className="text-xl font-medium text-themeBlue mx-4">
                  <span className="text-themeBlack">(</span>
                  {candidate_name} <span className="text-themeBlack">)</span>
                </p>
              )}
            </div>
          }
        >
          <h1 className="font-semibold text-lg uppercase">Candidate Details</h1>
          <div className="flex flex-row justify-between mb-6">
            {/* Section 1 */}
            <div className="flex flex-col space-y-4 w-1/2">
              <div className="flex flex-row items-center mt-6">
                <h4 className="font-semibold text-sm text-themeInputTitleColor w-1/3">
                  Candidate Name
                </h4>
                <p className="font-medium text-sm text-themeInputTitleColor">
                  {candidate_name ? candidate_name : "N/A"}
                </p>
              </div>
              <div className="flex flex-row items-center">
                <h4 className="font-semibold text-sm text-themeInputTitleColor w-1/3">
                  Current Title
                </h4>
                <p className="font-medium text-sm text-themeInputTitleColor">
                  {current_title ? current_title : "N/A"}
                </p>
              </div>

              <div className="flex flex-row items-center">
                <h4 className="font-semibold text-sm text-themeInputTitleColor w-1/3">
                  Candidate Company Name
                </h4>
                <p className="font-medium text-sm text-themeInputTitleColor">
                  {company_name ? company_name : "N/A"}
                </p>
              </div>

              <div className="flex flex-row items-center">
                <h4 className="font-semibold text-sm text-themeInputTitleColor w-1/3">
                  Region
                </h4>
                <p className="font-medium text-sm text-themeInputTitleColor">
                  {region ? region : "N/A"}
                </p>
              </div>

              <div className="flex flex-row items-center">
                <h4 className="font-semibold text-sm text-themeInputTitleColor w-1/3">
                  Country
                </h4>
                <p className="font-medium text-sm text-themeInputTitleColor">
                  {country_id ? country_name && country_name[0]?.name : "N/A"}
                </p>
              </div>

              <div className="flex flex-row items-center">
                <h4 className="font-semibold text-sm text-themeInputTitleColor w-1/3">
                  Location
                </h4>
                <p className="font-medium text-sm text-themeInputTitleColor">
                  {location ? location : "N/A"}
                </p>
              </div>

              <div className="flex flex-row items-center">
                <h4 className="font-semibold text-sm text-themeInputTitleColor w-1/3">
                  Current Grade
                </h4>
                <p className="font-medium text-sm text-themeInputTitleColor">
                  {current_grade ? current_grade : "N/A"}
                </p>
              </div>
              <div className="flex flex-row items-center">
                <h4 className="font-semibold text-sm text-themeInputTitleColor w-1/3">
                  Business Unit
                </h4>
                <p className="font-medium text-sm text-themeInputTitleColor">
                  {business_unit ? business_unit : "N/A"}
                </p>
              </div>

              <div className="flex flex-row items-center">
                <h4 className="font-semibold text-sm text-themeInputTitleColor w-1/3">
                  Current Job Function
                </h4>
                <p className="font-medium text-sm text-themeInputTitleColor">
                  {current_job_function?.name
                    ? current_job_function?.name
                    : "N/A"}
                </p>
              </div>
              <div className="flex flex-row items-center">
                <h4 className="font-semibold text-sm text-themeInputTitleColor w-1/3">
                  Current Sub-Job Function
                </h4>
                <p className="font-medium text-sm text-themeInputTitleColor">
                  {current_sub_job_function_id
                    ? sub_function && sub_function[0]?.name
                    : "N/A"}
                </p>
              </div>
            </div>

            {/* Section 2 */}
            <div className="flex flex-col space-y-4 w-1/2">
              <div className="flex flex-row items-center">
                <h4 className="font-semibold text-sm text-themeInputTitleColor w-1/3">
                  Current Currency
                </h4>
                <p className="font-medium text-sm text-themeInputTitleColor">
                  {currency_id
                    ? currency_current && currency_current[0]?.name
                    : "N/A"}
                </p>
              </div>

              <div className="flex flex-row items-center">
                <h4 className="font-semibold text-sm text-themeInputTitleColor w-1/3">
                  Sector
                </h4>
                <p className="font-medium text-sm text-themeInputTitleColor">
                  {sector?.name ? sector?.name : "N/A"}
                </p>
              </div>

              <div className="flex flex-row items-center">
                <h4 className="font-semibold text-sm text-themeInputTitleColor w-1/3">
                  Industry
                </h4>
                <p className="font-medium text-sm text-themeInputTitleColor">
                  {industry?.name ? industry?.name : "N/A"}
                </p>
              </div>

              <div className="flex flex-row items-center">
                <h4 className="font-semibold text-sm text-themeInputTitleColor w-1/3">
                  Sub-Industry
                </h4>
                <p className="font-medium text-sm text-themeInputTitleColor">
                  {sub_industry_id
                    ? sub_industry_name && sub_industry_name[0]?.name
                    : "N/A"}
                </p>
              </div>

              <div className="flex flex-row items-center">
                <h4 className="font-semibold text-sm text-themeInputTitleColor w-1/3">
                  Total Experience
                </h4>
                <p className="font-medium text-sm text-themeInputTitleColor">
                  {total_experience ? total_experience : "N/A"}
                </p>
              </div>

              <div className="flex flex-row items-center">
                <h4 className="font-semibold text-sm text-themeInputTitleColor w-1/3">
                  Relevant Experience
                </h4>
                <p className="font-medium text-sm text-themeInputTitleColor">
                  {relevant_experience ? relevant_experience : "N/A"}
                </p>
              </div>

              <div className="flex flex-row items-center">
                <h4 className="font-semibold text-sm text-themeInputTitleColor w-1/3">
                  Critical Skills
                </h4>
                <p className="font-medium text-sm text-themeInputTitleColor">
                  {is_critical_skills ? "Yes" : "No"}
                </p>
              </div>

              <div className="flex flex-row items-center">
                <h4 className="font-semibold text-sm text-themeInputTitleColor w-1/3">
                  Is Referral
                </h4>
                <p className="font-medium text-sm text-themeInputTitleColor">
                  {is_referral ? "Yes" : "No"}
                </p>
              </div>

              <div className="flex flex-row items-center">
                <h4 className="font-semibold text-sm text-themeInputTitleColor w-1/3">
                  Critical Skills
                </h4>
                <p className="font-medium text-sm text-themeInputTitleColor">
                  {critical_skills ? critical_skills : "N/A"}
                </p>
              </div>

              <div className="flex flex-row items-center">
                <h4 className="font-semibold text-sm text-themeInputTitleColor w-1/3">
                  Referral
                </h4>
                <p className="font-medium text-sm text-themeInputTitleColor">
                  {referral_employees ? referral_employees : "N/A"}
                </p>
              </div>

              {/* Add more rows for Section 2 as needed */}
            </div>
          </div>
        </CollapsibleCard>
      ) : (
        <>
          <div className="offers__form__fields">
            <Row justify="space-between" className="mb-2">
              <Col span={11}>
                <label className=" leading-10 mt-4 ">
                  <span className="font-medium text-themeBlue text-lg">
                    Candidate Name
                  </span>
                </label>
                <Input
                  readOnly={readOnly}
                  onChange={handleChange}
                  className={`w-full shadow-md my-2 font-semibold text-sm outline-none border border-themeInputBorder bg-white px-3 py-3 rounded-md`}
                  value={candidate_name || ""}
                  placeholder="Enter candidate name here..."
                  size="large"
                  name="candidate_name"
                />
              </Col>
              <Col span={11}>
                <label className=" leading-10 mt-4 ">
                  <span className="font-medium text-themeBlue text-lg">
                    Current Title
                  </span>
                </label>
                <Input
                  readOnly={readOnly}
                  className={`w-full shadow-md my-2 font-semibold text-sm outline-none border border-themeInputBorder bg-white px-3 py-3 rounded-md`}
                  name="current_title"
                  onChange={handleChange}
                  value={current_title || ""}
                  placeholder="Enter current title here..."
                  size="large"
                />
              </Col>
            </Row>

            <Row justify="space-between" className="mb-2">
              <Col span={11}>
                <label className=" leading-10 mt-4 ">
                  <span className="font-medium text-themeBlue text-lg">
                    Candidate Company Name
                  </span>
                </label>
                <Input
                  className={`w-full shadow-md my-2 font-semibold text-sm outline-none border border-themeInputBorder bg-white px-3 py-3 rounded-md`}
                  readOnly={readOnly}
                  onChange={handleChange}
                  name="company_name"
                  value={company_name || ""}
                  size="large"
                  placeholder="Enter candidate company name here..."
                />
              </Col>
              <Col span={11}>
                <label className=" leading-10 mt-4 ">
                  <span className="font-medium text-themeBlue text-lg">
                    Region
                  </span>
                </label>
                <Input
                  className={`w-full shadow-md my-2 font-semibold text-sm outline-none border border-themeInputBorder bg-white px-3 py-3 rounded-md`}
                  readOnly={readOnly}
                  onChange={handleChange}
                  name="region"
                  value={region || ""}
                  size="large"
                  placeholder="Enter region here..."
                />
              </Col>
            </Row>

            <Row justify="space-between" className="mb-2">
              <Col span={11}>
                <label className=" leading-10 mt-4 ">
                  <span className="font-medium text-themeBlue text-lg">
                    Country
                  </span>
                </label>
                <Select
                  className="custom-select"
                  value={country_id || undefined}
                  onChange={value =>
                    !readOnly &&
                    handleChange({ target: { name: "country_id", value } })
                  }
                  size="large"
                  showArrow
                  placeholder="Select country from here..."
                  showSearch={true}
                  filterOption={(input: any, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {countries?.map((country: ICountry) => (
                    <Option key={country?.id} value={country?.id}>
                      {country?.name}
                    </Option>
                  ))}
                </Select>
              </Col>
              <Col span={11}>
                <label className=" leading-10 mt-4 ">
                  <span className="font-medium text-themeBlue text-lg">
                    Location
                  </span>
                </label>
                <Input
                  className={`w-full shadow-md my-2 font-semibold text-sm outline-none border border-themeInputBorder bg-white px-3 py-3 rounded-md`}
                  readOnly={readOnly}
                  name="location"
                  size="large"
                  placeholder="Enter location here..."
                  onChange={handleChange}
                  value={location || ""}
                />
              </Col>
            </Row>
            <Row justify="space-between" className="mb-2">
              <Col span={11}>
                <label className=" leading-10 mt-4 ">
                  <span className="font-medium text-themeBlue text-lg">
                    Current Grade
                  </span>
                </label>
                <Input
                  className={`w-full shadow-md my-2 font-semibold text-sm outline-none border border-themeInputBorder bg-white px-3 py-3 rounded-md`}
                  readOnly={readOnly}
                  size="large"
                  placeholder="Enter grade here..."
                  name="current_grade"
                  onChange={handleChange}
                  value={current_grade || ""}
                />
              </Col>

              <Col span={11}>
                <label className=" leading-10 mt-4 ">
                  <span className="font-medium text-themeBlue text-lg">
                    Business Unit
                  </span>
                </label>
                <Input
                  className={`w-full shadow-md my-2 font-semibold text-sm outline-none border border-themeInputBorder bg-white px-3 py-3 rounded-md`}
                  readOnly={readOnly}
                  name="business_unit"
                  size="large"
                  placeholder="Enter business unit here..."
                  onChange={handleChange}
                  value={business_unit || ""}
                />
              </Col>
            </Row>
            <Row justify="space-between" className="mb-2">
              <Col span={11}>
                <label className=" leading-10 mt-4 ">
                  <span className="font-medium text-themeBlue text-lg">
                    Current Job Function
                  </span>
                </label>
                <Select
                  className="custom-select"
                  loading={isLoadingJobFunctions}
                  size="large"
                  showArrow
                  placeholder="Select job function from here..."
                  showSearch={false}
                  onChange={(serialized_jobFunction: string) => {
                    if (readOnly) return;
                    const jobFunction = JSON.parse(serialized_jobFunction);
                    handleChange({
                      target: {
                        name: "current_job_function",
                        value: { id: jobFunction?.id, name: jobFunction?.name },
                      },
                    });
                    dispatch(
                      handleCandidateDetails({
                        current_sub_job_function_id: null,
                      })
                    );
                    setSubJobFunctions(jobFunction?.job_sub_functions);
                  }}
                  value={current_job_function?.name || undefined}
                >
                  {jobFunctions?.map(
                    (jobFunction: Partial<IJobFunctionItem>) => (
                      <Option
                        key={jobFunction?.id}
                        value={JSON.stringify(jobFunction)}
                      >
                        {jobFunction?.name}
                      </Option>
                    )
                  )}
                </Select>
              </Col>
              <Col span={11}>
                <label className=" leading-10 mt-4 ">
                  <span className="font-medium text-themeBlue text-lg">
                    Current Sub-Job Function
                  </span>
                </label>
                <Select
                  className="custom-select"
                  disabled={!current_job_function?.id}
                  size="large"
                  showArrow
                  placeholder="Select sub-job function from here..."
                  showSearch={false}
                  onChange={value =>
                    !readOnly &&
                    handleChange({
                      target: { name: "current_sub_job_function_id", value },
                    })
                  }
                  value={current_sub_job_function_id || undefined}
                >
                  {subJobFunctions?.map(({ id, name }: ISubJobFunctionItem) => (
                    <Option key={id} value={id}>
                      {name}
                    </Option>
                  ))}
                </Select>
              </Col>
            </Row>

            <Row justify="space-between" className="mb-2">
              <Col span={11}>
                <label className=" leading-10 mt-4 ">
                  <span className="font-medium text-themeBlue text-lg">
                    Current Currency
                  </span>
                </label>
                <Select
                  className="custom-select"
                  value={currency_id || company?.currency?.id}
                  loading={isFetchingCurrencies}
                  onChange={value =>
                    !readOnly &&
                    handleChange({
                      target: { name: "currency_id", value },
                    })
                  }
                  showArrow={true}
                  showSearch={true}
                  size="large"
                  filterOption={(input: any, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder="Select currency from here..."
                >
                  {currencies?.map(({ id, name, code }: ICurrency) => (
                    <Option key={id} value={id}>
                      {`${name} (${code})`}
                    </Option>
                  ))}
                </Select>
              </Col>
              <Col span={11}>
                <label className=" leading-10 mt-4 ">
                  <span className="font-medium text-themeBlue text-lg">
                    Sector
                  </span>
                </label>
                <Select
                  className="custom-select"
                  loading={isLoadingSectors}
                  size="large"
                  showArrow
                  placeholder="Select sector from here..."
                  showSearch={false}
                  onChange={(serialized_sector: string) => {
                    if (readOnly) return;
                    const sector = JSON.parse(serialized_sector);
                    handleChange({
                      target: {
                        name: "sector",
                        value: { id: sector?.id, name: sector?.name },
                      },
                    });
                    dispatch(
                      handleCandidateDetails({
                        industry: null,
                        sub_industry_id: null,
                      })
                    );
                    setIndustries(sector?.industries);
                  }}
                  value={sector?.name || undefined}
                >
                  {sectors?.map((sector: Partial<ISector>) => (
                    <Option key={sector?.id} value={JSON.stringify(sector)}>
                      {sector?.name}
                    </Option>
                  ))}
                </Select>
              </Col>
            </Row>

            <Row justify="space-between" className="mb-2">
              <Col span={11}>
                <label className=" leading-10 mt-4 ">
                  <span className="font-medium text-themeBlue text-lg">
                    Industry
                  </span>
                </label>
                <Select
                  className="custom-select"
                  disabled={!sector?.id}
                  size="large"
                  showArrow
                  placeholder="Select industry from here..."
                  showSearch={false}
                  onChange={(serialized_industry: string) => {
                    if (readOnly) return;
                    const industry = JSON.parse(serialized_industry);
                    handleChange({
                      target: {
                        name: "industry",
                        value: { id: industry?.id, name: industry?.name },
                      },
                    });
                    dispatch(
                      handleCandidateDetails({
                        sub_industry_id: null,
                      })
                    );
                    setSubIndustries(industry?.sub_industries);
                  }}
                  value={industry?.name || undefined}
                >
                  {industries?.map((industry: Partial<IIndustry>) => (
                    <Option key={industry?.id} value={JSON.stringify(industry)}>
                      {industry?.name}
                    </Option>
                  ))}
                </Select>
              </Col>
              <Col span={11}>
                <label className=" leading-10 mt-4 ">
                  <span className="font-medium text-themeBlue text-lg">
                    Sub-Industry
                  </span>
                </label>
                <Select
                  className="custom-select"
                  disabled={!industry?.id}
                  size="large"
                  showArrow
                  placeholder="Select sub-industry from here..."
                  showSearch={false}
                  value={sub_industry_id || undefined}
                  onChange={value =>
                    !readOnly &&
                    handleChange({
                      target: { name: "sub_industry_id", value },
                    })
                  }
                >
                  {subIndustries?.map((subIndustry: Partial<IIndustry>) => (
                    <Option key={subIndustry?.id} value={subIndustry?.id!}>
                      {subIndustry?.name}
                    </Option>
                  ))}
                </Select>
              </Col>
            </Row>
            <Row justify="space-between" className="mb-2">
              <Col span={11}>
                <label className=" leading-10 mt-4 ">
                  <span className="font-medium text-themeBlue text-lg">
                    Total Experience
                  </span>
                </label>
                <Input
                  className={`w-full shadow-md my-2 font-semibold text-sm outline-none border border-themeInputBorder bg-white px-3 py-3 rounded-md`}
                  readOnly={readOnly}
                  type="number"
                  onChange={handleChange}
                  name="total_experience"
                  size="large"
                  placeholder="Enter total experience here..."
                  value={total_experience || ""}
                />
              </Col>
              <Col span={11}>
                <label className=" leading-10 mt-4 ">
                  <span className="font-medium text-themeBlue text-lg">
                    Relevant Experience
                  </span>
                </label>
                <Input
                  className={`w-full shadow-md my-2 font-semibold text-sm outline-none border border-themeInputBorder bg-white px-3 py-3 rounded-md`}
                  readOnly={readOnly}
                  type="number"
                  onChange={handleChange}
                  name="relevant_experience"
                  size="large"
                  placeholder="Enter relevant experience here..."
                  value={relevant_experience || ""}
                />
              </Col>
            </Row>

            <br />

            <div className="flex flex-row items-center mb-8">
              <Checkbox
                checked={is_critical_skills}
                onChange={event =>
                  handleChange({
                    target: {
                      name: "is_critical_skills",
                      value: event.target.checked,
                    },
                  })
                }
                className="offers__form__fields__checkbox  mt-4"
              >
                <span className={"font-medium text-lg text-themeBlue"}>
                  Critical Skills
                </span>
              </Checkbox>
              <Checkbox
                checked={is_referral}
                onChange={event =>
                  handleChange({
                    target: {
                      name: "is_referral",
                      value: event.target.checked,
                    },
                  })
                }
                className="offers__form__fields__checkbox mt-4"
              >
                <span className={"font-medium text-lg text-themeBlue"}>
                  Is Referral
                </span>
              </Checkbox>
            </div>
            <Row justify="space-between" className="mb-2">
              <Col span={11}>
                <label className=" leading-10 mt-4 ">
                  <span className="font-medium text-themeBlue text-lg">
                    Critical Skills
                  </span>
                </label>
                <Input
                  className={`w-full shadow-md my-2 font-semibold text-sm outline-none border border-themeInputBorder bg-white px-3 py-3 rounded-md`}
                  readOnly={readOnly}
                  onChange={handleChange}
                  name="critical_skills"
                  size="large"
                  placeholder="Enter critical skills here..."
                  value={critical_skills || ""}
                  disabled={!is_critical_skills}
                />
              </Col>

              <Col span={11}>
                <label className=" leading-10 mt-4 ">
                  <span className="font-medium text-themeBlue text-lg">
                    Referral
                  </span>
                </label>
                <Input
                  className={`w-full shadow-md my-2 font-semibold text-sm outline-none border border-themeInputBorder bg-white px-3 py-3 rounded-md`}
                  readOnly={readOnly}
                  disabled={!is_referral}
                  size="large"
                  placeholder="Enter referral employee name here..."
                  onChange={handleChange}
                  name="referral_employees"
                  value={referral_employees || ""}
                />
              </Col>
            </Row>
            {/* {offerModeller ? null : (
          <Row className="offers__form__fields__btns">
            <Button
              disabled={disabled}
              onClick={() => handleSubmit(false)}
              size="large"
              type="primary"
            >
              {isCreating && !draft?.current ? (
                <LoadingOutlined className="spinner" />
              ) : (
                "Next"
              )}
            </Button>
            <Button
              disabled={disabled}
              onClick={() => handleSubmit(true)}
              size="large"
              className="secondary-btn"
            >
              {isCreating && draft?.current ? (
                <LoadingOutlined className="spinner" />
              ) : (
                "Save as draft"
              )}
            </Button>
            <Button
              onClick={() => {
                dispatch(handleStage("POSITION_DETAILS"));
              }}
              size="large"
              className="secondary-btn"
            >
              Cancel
            </Button>
          </Row>
        )} */}
            {offerModeller ? null : (
              <div className="flex items-center   justify-around p-6 border-t border-solid border-blueGray-200 rounded-b">
                <CustomButton
                  customClasses={`!px-10  py-3 w-3/12  font-semibold ${
                    disabled
                      ? "bg-themeButtonGray text-themeBlack  cursor-not-allowed"
                      : "text-white bg-themeBlue"
                  }`}
                  btnName={"Next"}
                  disabledProp={disabled}
                  onClick={() => handleSubmit(false)}
                >
                  {isCreating && !draft?.current ? (
                    <LoadingOutlined className="spinner" />
                  ) : null}
                </CustomButton>

                <CustomButton
                  customClasses={`!px-10  py-3 w-3/12 font-semibold ${
                    disabled
                      ? "bg-themeButtonGray text-themeBlack  cursor-not-allowed"
                      : "text-white bg-themeBlue"
                  }`}
                  btnName={"Save as draft"}
                  disabledProp={disabled}
                  onClick={() => handleSubmit(true)}
                >
                  {isCreating && draft?.current ? (
                    <LoadingOutlined className="spinner" />
                  ) : null}
                </CustomButton>

                <CustomButton
                  btnName="Go Back"
                  onClick={() => dispatch(handleStage("POSITION_DETAILS"))}
                  customClasses={`!px-10 py-3  w-3/12 ${
                    disabled
                      ? "bg-themeBlue  text-white"
                      : "bg-themeButtonGray  text-themeBlack"
                  } font-semibold`}
                  textStyles="font-semibold"
                ></CustomButton>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default CandidateDetails;
