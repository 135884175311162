import { Col, message, Row } from "antd";
import { useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import Table from "@components/Table";
import Button from "@components/Button";
import { getColumns } from "./config";
import AddLongTermPlan from "./AddLongTermPlan";
import {
  ErrorServices,
  useDeleteLongTermPlanMutation,
  useFetchLongTermPlansQuery,
} from "@services";
import { ILongTermPlan } from "@types";
import { paths, permissions } from "@router";
import { useTypedSelector } from "@hooks";
import { checkPermission } from "@/utils";
import CustomButton from "@/components/customButton";
import { MdOutlineFileDownload } from "react-icons/md";
import SearchBox from "@/components/searchInput";

const LongTermPlans = () => {
  const { company } = useTypedSelector(state => state.auth?.user!);
  const { id: company_id } = company!;
  const history = useHistory();
  const longTermPlan_id = useRef<any>(null);
  const [page, setPage] = useState(1);
  const [isVisible, setIsVisible] = useState(false);
  const [searchInput, setSearchInput] = useState("");

  const [selectedLongTermPlan, setSelectedLongTermPlan] =
    useState<ILongTermPlan | null>(null);
  const [deleteLongTermPlan, { isLoading: isDeleting }] =
    useDeleteLongTermPlanMutation();
  const { data: plansData, isLoading } = useFetchLongTermPlansQuery({
    company_id,
    page,
  });
  const { data, pagination } = plansData || {};

  const onRowClick = (data: any) => {
    history.push(`${window.location.pathname}/${data?.id}`);
  };

  const removeLongTermPlan = async (id: number) => {
    try {
      longTermPlan_id.current = id;
      await deleteLongTermPlan({ company_id, id }).unwrap();
      message.success("Long term incentive plan deleted successfully!");
    } catch (error) {
      ErrorServices(error);
      console.log(error);
    }
  };

  const editLongTermPlan = (selectedLongTermPlan: ILongTermPlan) => {
    setSelectedLongTermPlan(selectedLongTermPlan);
    setIsVisible(true);
  };

  const columns = getColumns({
    isDeleting,
    longTermPlan_id,
    removeLongTermPlan,
    editLongTermPlan,
  });

  return (
    <>
      {isVisible && (
        <AddLongTermPlan
          selectedLongTermPlan={selectedLongTermPlan}
          setSelectedLongTermPlan={setSelectedLongTermPlan}
          isVisible={isVisible}
          setIsVisible={setIsVisible}
        />
      )}

      <div className="bg-themeCore">
        <div className="jobCreationBar bg-white px-4 py-6 rounded-lg flex justify-between items-center ">
          <div className="title text-themeBlue  font-medium text-3xl">
            Long Term Incentive Plans
          </div>

          <div className="flex flex-row justify-between">
            <CustomButton
              onClick={() => history.push(paths.client.long_term_ip.incentives)}
              btnName="LTI Data"
              customClasses="shadow-md font-medium border py-1 px-6 !border-themeBorder"
              iconPosition="left"
            ></CustomButton>

            {checkPermission(permissions.CREATE_COMPANY_LTI) && (
              <CustomButton
                onClick={() => setIsVisible(true)}
                btnName="Create LTI Plan"
                customClasses="shadow-md font-medium border py-1 px-6 !border-themeBorder ml-4"
                iconPosition="right"
              >
                <MdOutlineFileDownload
                  size={20}
                  className="text-white font-bold"
                />
              </CustomButton>
            )}
          </div>
        </div>
      </div>
      <div className="jobCreationListing mt-10 bg-white px-14 py-6 rounded-lg">
        <SearchBox
          placeholder="Type to search..."
          value={searchInput}
          onChange={value => setSearchInput(value)}
        />
        <Row>
          <Table
            scroll={1800}
            data={data}
            columns={columns}
            isLoading={isLoading}
            pagination={true}
            count={pagination?.count}
            onChangePage={setPage}
            page={page}
            onRowClick={onRowClick}
          />
        </Row>
      </div>
    </>
  );
};

export default LongTermPlans;
