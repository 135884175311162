import { paths } from "@router";
import { permissions } from "@/router";

export const tabs = [
  {
    id: 1,
    title: "Company details",
    path: paths.client.companies.profile.details,
  },
  {
    id: 2,
    title: "Business units",
    path: paths.client.companies.profile.business_units.listing,
    permission : permissions.VIEW_BUSINESS_UNIT,
  },
  {
    id: 3,
    title: "Regions",
    path: paths.client.companies.profile.regions,
    permission : permissions.VIEW_REGION,
  },
];
