import React, { useEffect, useState } from "react";
import StepperComponent from "@/components/stepper";
import { FaAddressCard, FaMoneyBillWave, FaUser } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import CandidateDetails from "./CandidateDetails";
import OfferDetails from "./OfferDetails";
import OfferModeller from "./OfferModeller";
import { useTypedSelector } from "@hooks";
import { offersApi, useFetchOfferByIdQuery } from "@/services";
import { useParams } from "react-router-dom";
import { handleStage } from "@/store/offers";

const steps = [
  {
    title: "Offer Details",
    icon: <FaAddressCard size={24} />,
  },
  {
    title: "Candidate Details",
    icon: <FaUser size={24} />,
  },
  {
    title: "Salary Offer Modeler",
    icon: <FaMoneyBillWave size={24} />,
  },
];

const components: any = {
  POSITION_DETAILS: <OfferDetails />,
  CANDIDATE_DETAILS: <CandidateDetails />,
  OFFER_MODELLER: <OfferModeller />,
};

const stageToStepIndex = {
  POSITION_DETAILS: 0,
  CANDIDATE_DETAILS: 1,
  OFFER_MODELLER: 2,
};

const AddOffer: React.FC<{ visible: boolean }> = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [visible, setVisible] = useState(true);

  const { offer_id: id } = useParams<{ offer_id: string }>();
  const { company } = useTypedSelector(state => state.auth?.user!);
  const { id: company_id } = company!;
  const { offer: localOffer } = useTypedSelector(state => state.offers);
  const stage = localOffer?.stage;
  const is_create = window.location.pathname.includes("/create");


  useFetchOfferByIdQuery(
    {
      company_id,
      id,
    },
    { skip: is_create }
  );

  useEffect(() => {
    if (!is_create) {
      dispatch(
        offersApi.endpoints.fetchOfferById.initiate(
          {
            company_id,
            id,
          },
          {
            subscribe: false,
            forceRefetch: true,
          }
        )
      );
    }
    // return () => {
    //   dispatch(resetOffers());
    // };
  }, [company_id, dispatch, id, is_create]);

  const initialStep = stage ? stageToStepIndex[stage] : 0;
  const [currentStep, setCurrentStep] = useState(initialStep);

  const handleStepClick = (stepIndex: any) => {
    setCurrentStep(stepIndex);
    if (stepIndex === 0) {
      dispatch(handleStage("POSITION_DETAILS"));
    } else if (stepIndex === 1) {
      dispatch(handleStage("CANDIDATE_DETAILS"));
    }else if (stepIndex === 2) {
      dispatch(handleStage("OFFER_MODELLER"));
    }
  };

  const renderComponent = () => {
    switch (stage) {
      case "POSITION_DETAILS":
        return <OfferDetails onResponse={handleStepClick} />;
      case "CANDIDATE_DETAILS":
        return <CandidateDetails onResponse={handleStepClick} />;
      case "OFFER_MODELLER":
        return <OfferModeller onResponse={handleStepClick} />;
      default:
        return null;
    }
  };

  return (
    <>
      {visible && (
        <div className="overflow-x-hidden bg-themeCore transition-all delay-75 overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none px-16 no-scrollbar">
        <div className="relative my-20 mx-6 py-10 w-full">
            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-themeCore outline-none focus:outline-none">
              <div className="relative py-6 px-24 flex-auto">
                <div className="Stepper w-6/12 mx-auto">
                  <StepperComponent
                    steps={steps}
                    currentStep={currentStep}
                    onStepClick={handleStepClick}
                  />
                </div>
                <div className="content mt-12 p-5 ">
                  {currentStep === 0 ? (
                    <div>
                      <p className="font-bold text-2xl  text-center text-themeBlue mb-8">
                        Offered Position Details
                      </p>
                      {localOffer ? renderComponent() : <OfferDetails />}
                    </div>
                  ) : currentStep === 1 ? (
                    <div>
                      <p className="font-bold text-2xl  text-center text-themeBlue mb-8">
                        Candidate Details
                      </p>
                      {localOffer ? renderComponent() : <CandidateDetails />}
                    </div>
                  ) : currentStep === 2 ? (
                    <div>
                      {localOffer ? (
                        renderComponent()
                      ) : (
                        <OfferModeller
                          offerModeller={false}
                          detailedView={false}
                        />
                      )}
                    </div>
                  ) : null}
                </div>
              </div>
              {/* <div className="flex items-center   justify-around p-6 border-t border-solid border-blueGray-200 rounded-b">
                <CustomButton
                  customClasses="!px-10  py-3 w-3/12 font-semibold"
                  btnName={"Continue"}
                  // onClick={current === 0 ? next : onSubmit}
                  onClick={handleNext}
                />
                <CustomButton
                  btnName="Go Back"
                  onClick={handlePrevious}
                  customClasses="!px-10 py-3  w-3/12 bg-themeButtonGray text-themeBlack font-semibold"
                  textStyles="font-semibold"
                />
              </div> */}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AddOffer;
