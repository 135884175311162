import { Col, Row } from "antd";
import { matchPath, useParams } from "react-router-dom";
import { FC } from "react";

import "./companyProfile.less";
import { IRoute, paths, Routes } from "@router";
import Tabs from "@components/Tabs";
import { tabs } from "./config";
import { checkPermission } from "@/utils";
import { permissions } from "@/router";

interface ICompanyProfile {
  routes: IRoute[];
}

const ClientCompanyProfile: FC<ICompanyProfile> = ({ routes }) => {
  const { company_id } = useParams<{ company_id: string }>();
  const match = matchPath(window.location.pathname, {
    path: paths.client.companies.profile.business_units.regions,
  });
  return (
    <div >
      {checkPermission([
        permissions.VIEW_BUSINESS_UNIT,
        permissions.VIEW_REGION,
      ]) ? (
        <div className="companyProfile">
          {match ? null : (
            <>
              <Row>
                <Col span={24}>
                  <div className="main-heading mb-16">Company details</div>
                </Col>
              </Row>
              <Tabs
                param=":company_id"
                route={true}
                paramValue={company_id}
                tabs={tabs}
              />
            </>
          )}
          <div className="companyProfile__content">
            <Routes routes={routes} />
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default ClientCompanyProfile;
