import React, { useEffect, useState } from "react";
import {
  Layout as AntdLayout,
  Menu,
  Breadcrumb,
  Dropdown,
  Avatar,
  message,
  Button,
} from "antd";
import { Link, NavLink, useLocation } from "react-router-dom";
import Logo from "@assets/images/logoD.png";
import { IoNotifications, IoApps, IoClose } from "react-icons/io5";
import LogoM from "@assets/images/logoM.png";
import _ from "lodash";

import "./layout.less";
import { paths, admin_routeConfig, client_routeConfig } from "@router";
// import { ReactComponent as Logo } from "@assets/images/logo.svg";
import { ReactComponent as Down } from "@assets/images/arrow-down.svg";
import { ReactComponent as Bell } from "@assets/images/bell.svg";
import { IConfig, admin_config, client_config } from "./sidebar-config";
import profilePic from "@assets/images/avatar.png";
import { useBreadcrumbs, useTypedSelector } from "@hooks";

import {
  ErrorServices,
  loadRefreshToken,
  saveTokens,
  useFetchCompaniesQuery,
  useFetchCompanyJobGradesQuery,
  useLogoutMutation,
  useVerifyMutation,
} from "@services";
import { checkPermission, isAdmin } from "@utils";
import { appsPageUrl, ssoUrl } from "@/constants";
import Cookies from "universal-cookie";
import { cookiesDomain } from "@/constants";
import {
  getSelectedCompanyFromStorage,
  getTokenFromSharedStorage,
} from "@/services/cookies";
import { ICompany } from "@/types";

const { Header, Content, Sider } = AntdLayout;

const Layout = ({ children }: { children: React.ReactNode }) => {
  const { user } = useTypedSelector(state => state.auth);
  const [collapsed, setCollapsed] = useState(true);
  const [currentPath, setCurrentPath] = React.useState("");

  const { id: company_id } = user?.company || {};
  const { data: jobGradesData } = useFetchCompanyJobGradesQuery(
    { company_id },
    { skip: !!company_id === false }
  );
  const { data: jobGrades } = jobGradesData || {};
  const [isMenuVisible, setIsMenuVisible] = React.useState(false);
  const [onLogout] = useLogoutMutation();
  const { pathname } = useLocation();

  // manage model start

  const [searchString, setSearchString] = useState<string>("");
  const [selectedCompany, setselectedCompany] = useState<ICompany>();
  const { data: companiesData, isLoading: isFetching } =
    useFetchCompaniesQuery(1);
  const { data } = companiesData || {};

  const [verifyUser, { status }] = useVerifyMutation();

  const setSelectedCompanyInStorage = (data: any) => {
    cookies.set("selected_company", data, { path: "/", domain: cookiesDomain });
  };
  let handleCompanySelect = async (app: any) => {
    setselectedCompany(app);
    setSelectedCompanyInStorage(app);
    const ssoTokens = await getTokenFromSharedStorage();
    if (ssoTokens) {
      saveTokens(ssoTokens);
      const companyId = await getSelectedCompanyFromStorage();
      await verifyUser({
        selected_application: process.env.REACT_APP_APPLICATION || "TOM",
        companyId,
      });
    }

    closeModal();
    // onPressNext();
  };

  useEffect(() => {
    let currentCompnay = getSelectedCompanyFromStorage();
  }, [data]);

  // sagars integtation work for modal ends

  const getCompanyLogoUrl = () => {
    const selectedCompanyId = getSelectedCompanyFromStorage();

    const company = data.find((company: any) => {
      return company.id === selectedCompanyId && company.large_logo_url;
    });

    return company ? company.large_logo_url : null;
  };
  //manage model end

  const config = isAdmin(user?.role!)
    ? admin_config
    : client_config(jobGrades, company_id?.toString() || "");

  const handleLinkClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    path: string | Array<string>,
    disabled?: boolean | undefined,
    fallbackMessage?: string | undefined,
    d?: string
  ) => {
    const currentPath = Array.isArray(path) ? path.join("/") : path;
   
      setCurrentPath(currentPath || "");

    if (Array.isArray(path)) {
      event.preventDefault();
      if (disabled) message.error(fallbackMessage);
    }
  };

  const getRoute = (path: string | Array<string>): string => {
   
    if (typeof path == "string") return path;
    else if (
      Array.isArray(path) &&
      path.includes(pathname) &&
      typeof pathname == "string"
    ) {
      return pathname;
    } else return "";
  };

  const breadcrumbs = useBreadcrumbs(
    isAdmin(user?.role!) ? admin_routeConfig : client_routeConfig,
    {
      disableDefaults: true,
    }
  );

  const breadcrumbs_list = breadcrumbs.map(({ breadcrumb }) => breadcrumb);
  const breadcrumbList = breadcrumbs_list?.map((breadcrumb: any) => {
    const path = breadcrumb?.key;
    const titles = breadcrumb?.props?.children?.split(" / ");
    const title = titles[titles?.length - 1];
    return <Link to={path}>{title}</Link>;
  });

  const onLogoutFromServer = async () => {
    try {
      const refresh = loadRefreshToken();
      await onLogout({ refresh }).unwrap();
      window.open(ssoUrl, "_self"); // open SSO page after logout
    } catch (error) {
      ErrorServices(error);
    }
  };

  const openAppsPage = () => {
    window.open(appsPageUrl, "_self");
  };

  const [isModalApp, setIsModalApp] = useState(false);
  const openModalApp = () => setIsModalApp(false);
  const closeModalApp = () => setIsModalApp(false);

  const menu = (
    <Menu>
      <Menu.Item key="1" onClick={openAppsPage}>
        Apps
      </Menu.Item>
      <Menu.Item key="2" onClick={onLogoutFromServer}>
        Log out
      </Menu.Item>
    </Menu>
  );

  // select application modal starts
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
    children: React.ReactNode;
  }

  const cookies = new Cookies();

  const Modal: React.FC<ModalProps> = ({ isOpen, onClose, children }) => {
    if (!isOpen) return null;

    return (
      <div className="fixed inset-0 flex items-center justify-center z-50 ">
        <div
          className="fixed inset-0 bg-black opacity-50"
          onClick={onClose}
        ></div>
        <div className="bg-white  shadow-lg p-8 z-10 min-w-[760px] rounded-lg mx-auto relative">
          <IoClose
            className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 cursor-pointer text-xl"
            onClick={onClose}
          />
          <div className="contenr overflow-y-scroll p-5">{children}</div>
        </div>
      </div>
    );
  };

  if (
    Object.values(paths.admin.auth).some(path =>
      window.location.pathname.includes(path as string)
    )
  )
    return <div>{children}</div>;

  return (
    <AntdLayout style={{ minHeight: "100vh" }}>
      <AntdLayout>
        <Sider
          trigger={null}
          collapsible
          collapsed={collapsed}
          onCollapse={value => setCollapsed(value)}
          className="bg-themeCore shadow-md"
          width={255}
        >
          {/* <div className="sider__logo__container"></div> */}

          <div className="demo-logo-vertical" />
          <div className="mb-6">
            <img
              onClick={() => setCollapsed(!collapsed)}
              src={collapsed ? LogoM : Logo}
              className=" w-full h-full px-4 py-4 cursor-pointer"
            />
          </div>
          {!collapsed && (
            <div
              className={`group  flex gap-1 items-center w-full h-12 px-3 mt-2 rounded `}
            >
              <span className="ml-2 text-xs font-semibold text-themeGrey">
                Talent Offer Modeller
              </span>

              <div className="line  ml-4 border h-[10px] bg-themeGrey w-16"></div>
            </div>
          )}
          {config.map((config: IConfig, idx) => (
            <div className="px-4" key={idx}>
              <NavLink
                exact
                key={idx}
                to={getRoute(config.path)}
                className={`group font-medium hover:no-underline visited:text-black mt-2 flex items-center w-full py-3 px-3 rounded hover:bg-themeBlue hover:text-white ${
                  getRoute(config.path) !== currentPath
                    ? "text-sm font-medium text-themeGrey"
                    : "bg-themeBlue text-white"
                } 
            ${collapsed ? "flex justify-center" : ""}
            transition-all delay-[1ms]`}
                activeClassName="bg-themeBlue font-medium text-white visited:text-white"
                onClick={event =>
                  handleLinkClick(
                    event,
                    config.path,
                    config?.disabled,
                    config?.fallbackMessage,
                    getRoute(config.path)
                  )
                }
              >
                <div
                  className={`${collapsed ? "" : "mr-4"} ${
                    config?.client && ""
                  }`}
                >
                  <config.icon
                    className={`${
                      getRoute(config.path) !== currentPath
                        ? "text-themeGrey"
                        : "text-white"
                    }`}
                  />
                </div>
                <p>{!collapsed && config.title}</p>
              </NavLink>

              {!config?.disabled &&
                config.sub?.map((subLink: any, i: number) =>
                  checkPermission(subLink?.permission) ? (
                    <NavLink
                      key={i}
                      to={subLink.path || ""}
                      className={`group w-max font-medium  ${
                        collapsed ? "ml-1" : "ml-7"
                      } hover:no-underline visited:text-black flex items-center py-2 px-3 mt-1 rounded hover:bg-themeBlue hover:text-white ${
                        getRoute(subLink.path) === currentPath
                          ? "bg-themeBlue text-white"
                          : "text-sm font-medium text-themeGrey"
                      } 
                  ${collapsed ? "flex justify-center" : ""}
                  transition-all delay-[1ms]`}
                      activeClassName="bg-themeBlue font-medium text-white visited:text-white"
                      onClick={event =>
                        handleLinkClick(
                          event,
                          subLink.path,
                          undefined,
                          undefined,
                          getRoute(subLink.path)
                        )
                      }
                    >
                      <div
                        className={`${collapsed ? "" : "mr-4"} ${
                          config?.client && ""
                        }`}
                      >
                        <subLink.icon
                          className={`${
                            getRoute(subLink.path) !== currentPath
                              ? "text-themeGrey"
                              : "text-white"
                          }`}
                        />
                      </div>
                      {!collapsed && subLink.title}
                    </NavLink>
                  ) : null
                )}
            </div>
          ))}
          {/* <div className="absolute bottom-4 items-center justify-center flex w-full">
            <Button
              type="text"
              icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              onClick={() => setCollapsed(!collapsed)}
              style={{
                fontSize: "16px",
                width: 64,
                height: 64,
              }}
            />
          </div> */}
        </Sider>

        <AntdLayout className="bg-themeCore">
          <Header className="bg-themeCore flex justify-end items-center shadow-sm">
            {/* <Bell className="header__notif__icon" /> */}
            <div className="notification relative mr-4">
              <div
                style={{
                  backgroundColor: "#00519d",
                }}
                className="notificationDot rounded-full  p-1 w-1 h-1 absolute top-0 right-1 border-2"
              ></div>
              <div className="bell">
                <IoNotifications
                  style={{
                    color: "#6b6d70",
                  }}
                  className="text-3xl "
                />
              </div>
            </div>
            <div className="app mr-4">
              <IoApps
                style={{
                  color: "#6b6d70",
                }}
                className="text-3xl cursor-pointer"
                onClick={openModal}
              />

              <Modal isOpen={isModalOpen} onClose={closeModal}>
                <div className="companiesList flex justify-around items-center flex-wrap gap-4">
                  {data &&
                    data.map((company: any, index: any) => (
                      <div
                        className={`companyCard cursor-pointer p-5 hover:bg-[#dfecfe] transition-all delay-0  border border-white hover:border-gray-100 hover:shadow-lg rounded ${
                          getSelectedCompanyFromStorage() == company.id &&
                          "bg-[#dfecfe] shadow-lg"
                        }`}
                        onClick={() => handleCompanySelect(company)}
                      >
                        <div className="logo w-full">
                          <img
                            src={company?.large_logo_url}
                            className="w-40 h-40 object-contain scale-105"
                            alt={`${company?.name} logo`}
                          />
                        </div>
                        <div className="title text-center font-semibold">
                          {company?.name}
                        </div>
                      </div>
                    ))}
                </div>
              </Modal>
            </div>
            <div className="selectedCompnay mr-4 bg-white rounded-full">
              <img
                src={(data && getCompanyLogoUrl()) || "default-logo-url"} // Replace 'default-logo-url' with a placeholder or default image URL
                className="w-9 h-9 object-contain "
                alt="logo"
              />
            </div>
            <Dropdown
              overlay={menu}
              onVisibleChange={open => setIsMenuVisible(open)}
              visible={isMenuVisible}
              trigger={["click"]}
            >
              <div className="profile__menu__toggle">
                <Avatar size={32} src={profilePic} />
                <div className="flex flex-col mr-6 ml-3">
                  <span className="leading-17.1 font-semibold text-sm">{`${user?.first_name} ${user?.last_name}`}</span>
                  <span className="leading-14.6 font-medium text-xs">
                    {_.replace(_.startCase(_.toLower(user?.role)), "_", " ")}
                  </span>
                </div>
                <div className="h-auto leading-none">
                  <Down className="" />
                </div>
              </div>
            </Dropdown>
            <Modal isOpen={isModalApp} onClose={closeModalApp}>
              <div className="companiesList flex justify-around items-center flex-wrap gap-4"></div>
            </Modal>
          </Header>

          <div className="ml-7 flex flex-row">
            <p className="text-themeBlack font-sans mr-2 font-semibold text-sm">
              <Breadcrumb className="mt-10">
                {breadcrumbList?.map((breadcrumb: any) => (
                  <Breadcrumb.Item key={breadcrumb}>
                    {breadcrumb}
                  </Breadcrumb.Item>
                ))}
              </Breadcrumb>
            </p>
          </div>
          <Content
            style={{
              padding: "20px 16px",
              width: "100%",
              height: "calc(100vh - 64px)",
            }}
          >
            <div style={{ overflowY: "scroll", height: "100%" }}>
              <div className=" mt-[0%]">{children}</div>
            </div>
          </Content>
        </AntdLayout>
      </AntdLayout>
    </AntdLayout>
  );
};

export default Layout;
