import { Button, Tabs, message, Row } from "antd";
import { useState, useRef } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import "./salaryOfferModeller.less";
import { panels } from "./config";
import {
  ErrorServices,
  useCreateOfferModellerMutation,
  useFetchAutoPopulateDataQuery,
  useUpdateOfferStatusMutation,
} from "@services";
import { useTypedSelector } from "@hooks";
import { paths } from "@router";
import { handleStage, offerModellerInitialState } from "@store/offers";
import CustomButton from "@/components/customButton";

const { TabPane } = Tabs;

const SalaryOfferModeller = () => {
  const [activeKey, setActiveKey] = useState<string>("1");
  const dispatch = useDispatch();
  const draft = useRef<boolean>(false);
  const accept = useRef<boolean>(false);
  const { offer } = useTypedSelector(state => state?.offers);
  const { company } = useTypedSelector(state => state.auth?.user!);
  const { id: company_id } = company!;
  const history = useHistory();
  const { offerModeller } = useTypedSelector(state => state.offers);
  const [createOfferModeller, { isLoading: isCreating }] =
    useCreateOfferModellerMutation();
  const [updateOfferStatus, { isLoading: isUpdating }] =
    useUpdateOfferStatusMutation();



  useFetchAutoPopulateDataQuery(
    {
      company_id,
      offer_id: offer?.id,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !offer?.id,
    }
  );

  const handleChange = (key: string) => {
    setActiveKey(key);
  };

  const handleSubmit = async (is_draft: boolean) => {
    try {
      draft.current = is_draft;
      await createOfferModeller({
        company_id,
        id: offer?.id,
        body: { ...offerModeller, is_draft },
      }).unwrap();
      message.success(`Offer ${is_draft ? "saved" : "placed"} successfully`);
      if (!is_draft) {
        history.push(paths.client.offers.active);
      }
    } catch (err) {
      console.log(err);
      // ErrorServices(err);
    }
  };

  const update = async (is_accepted: boolean) => {
    try {
      accept.current = is_accepted;
      await updateOfferStatus({
        company_id,
        id: offer?.id,
        status: is_accepted ? "ACCEPTED" : "REJECTED",
      }).unwrap();
      message.success(`Offer ${is_accepted ? "Accepted" : "Rejected"}!`);
      history.push(paths.client.offers.active);
    } catch (err) {
      console.log(err);
      ErrorServices(err);
    }
  };

  return (
    <>
      <div
        style={{
          backgroundColor: "#F9FAFD",
          border: "1px solid #DDE5EF",
        }}
      >
        <Tabs
          tabBarStyle={{
            backgroundColor: "#F9FAFD",
            padding: "13px",
            borderWidth: "1px",
            borderColor: "#DDE5EF",
          }}
          activeKey={activeKey}
          onChange={handleChange}
        >
          {panels?.map(
            ({
              id,
              header,
              component,
            }: {
              id: string;
              header: string;
              component: any;
            }) => (
              <TabPane
                style={{
                  marginTop: "-15px",
                }}
                tab={header}
                key={id}
              >
                {component}
              </TabPane>
            )
          )}
        </Tabs>
        <div className="transparent height-40" />
        <Row className="offers__form__fields__btns salary-offer__btns transparent"></Row>
      </div>
      {window.location.pathname?.includes("/create") ||
      offer?.status === "DRAFTED" ? (
        <div className="flex items-center   justify-around p-6 border-t border-solid border-blueGray-200 rounded-b">
          <CustomButton
            customClasses={`!px-10  py-3 w-3/12 font-semibold 
            "bg-themeButtonGray text-white  cursor-not-allowed"
          `}
            onClick={() => handleSubmit(false)}
            btnName=""
          >
            {isCreating && !draft?.current ? (
              <LoadingOutlined className="spinner" />
            ) : (
              "Place Offer"
            )}
          </CustomButton>
          <CustomButton
            customClasses={`!px-10  py-3 w-3/12 font-semibold 
              "bg-themeButtonGray text-white  cursor-not-allowed"
            `}
            onClick={() => handleSubmit(true)}
            btnName=""
          >
            {isCreating && draft?.current ? (
              <LoadingOutlined className="spinner" />
            ) : (
              "Save as draft"
            )}
          </CustomButton>
          <CustomButton
            onClick={() => dispatch(handleStage("CANDIDATE_DETAILS"))}
            btnName=""
            customClasses={`!px-10 py-3  w-3/12 
              "bg-themeButtonGray  text-themeBlack"
            font-semibold`}
          >
            Cancel
          </CustomButton>
        </div>
      ) : (
        <>
          {offer?.status === "PLACED" ? (
            <div className="flex items-center   justify-around p-6 border-t border-solid border-blueGray-200 rounded-b">
              <CustomButton
                customClasses={`!px-10  py-3 w-3/12 font-semibold 
                "bg-themeButtonGray text-white  cursor-not-allowed"
              `}
                btnName=""
                onClick={() => update(true)}
              >
                {isUpdating && accept?.current ? (
                  <LoadingOutlined className="spinner" />
                ) : (
                  "Accept"
                )}
              </CustomButton>
              <CustomButton
                customClasses={`!px-10  py-3 w-3/12 font-semibold 
                "bg-themeButtonGray text-white  cursor-not-allowed"
              `}
                onClick={() => update(false)}
                btnName=""
              >
                {isUpdating && !accept?.current ? (
                  <LoadingOutlined className="spinner" />
                ) : (
                  "Reject"
                )}
              </CustomButton>
              <CustomButton
                customClasses={`!px-10 py-3  w-3/12 
                "bg-themeButtonGray  text-themeBlack"
              font-semibold`}
                onClick={() => history.goBack()}
                btnName=""
              >
                Cancel
              </CustomButton>
            </div>
          ) : (
            <CustomButton
              customClasses={`!px-10 py-3  w-3/12 
          "bg-themeButtonGray  text-themeBlack"
        font-semibold`}
              onClick={() => history.goBack()}
              btnName=""
            >
              Cancel
            </CustomButton>
          )}
        </>
      )}
    </>
  );
};

export default SalaryOfferModeller;
