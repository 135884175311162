import React, { FC } from "react";

interface CustomModalProps {
  isVisible: boolean;
  title: string;
  onClose: () => void;
  footer: React.ReactNode;
  children: React.ReactNode;
  width: string;
  height?: string; // Optional height prop
}

const CustomModal: FC<CustomModalProps> = ({
  isVisible,
  title,
  onClose,
  footer,
  children,
  width,
  height = "auto", // Default height is auto
}) => {
  if (!isVisible) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div
        className="fixed inset-0 bg-black opacity-50"
        onClick={onClose}
      ></div>
      <div
        className={`bg-themeCore shadow-lg z-10 rounded-lg ${width} flex flex-col`}
        style={{ maxHeight: height }}
      >
        <div className="flex justify-between border-b-2 rounded-tl-lg rounded-tr-lg px-10 border-themeCardBorder bg-white items-center py-4 mb-3">
          <h2 className="text-xl font-semibold">{title}</h2>
        </div>
        <div className="px-10 py-6 overflow-y-auto flex-grow">
          <div className="mb-4">{children}</div>
        </div>
        <div className="px-10 py-4 bg-white flex justify-end space-x-4 border-t border-themeCardBorder rounded-bl-lg rounded-br-lg">
          {footer}
        </div>
      </div>
    </div>
  );
};

export default CustomModal;
