import { Switch, TableColumnsType } from "antd";
import moment from "moment";

import { IVersion } from "@types";
import { permissions } from "@/router";
import { checkPermission } from "@/utils";
import { dateFormat } from "@/constants";
import { DownloadOutlined } from "@ant-design/icons";

export const getVersionsColumns = ({
  handleActive,
  currentId,
  download,
}: {
  handleActive: (id: number) => Promise<void>;
  currentId: null | number;
  download: (id?: number | undefined) => void;
}): TableColumnsType<any> => [
  {
    title: "created at",
    dataIndex: "created_at",
    key: "created_at",
    width: "25%",
    render: (date: string) => <div>{moment(date).format(dateFormat)}</div>,
  },
  ...(!checkPermission(permissions.CREATE_INTERNAL_PAYROLL)
    ? []
    : ([
        {
          title: "Active",
          key: "active",
          width: `calc(100% - 55%)`,
          render: (version: IVersion) => (
            <Switch
              onChange={checked => {
                if (!checked) return;
                handleActive(version?.id);
              }}
              checked={
                currentId === version?.id ||
                (version?.is_active === "TRUE" && currentId === version?.id)
              }
              defaultChecked={version?.is_active === "TRUE"}
            />
          ),
        },
      ] as any)),
  {
    title: "action",
    key: "action",
    width: "15%",
    render: (version: IVersion) => (
      <div
        onClick={() => download(version?.id)}
        className="table__action__btn table__action__btn--client"
      >
          <DownloadOutlined style={{ color: "#1A8245" }} /> Download

      </div>
    ),
  },
];
