import { TableColumnsType } from "antd";
import _ from "lodash";

import { ICandidateDetails, IPositionDetails, Stage } from "@types";

export const getColumns = ({
  handleRowClick,
}: {
  handleRowClick: (id: number, stage: Stage) => void;
}): TableColumnsType<any> => [
  {
    title: "candidate name",
    dataIndex: "offer_candidate_detail",
    key: "name",
    width: 200,
    render: (offer_candidate_detail: ICandidateDetails) =>
      offer_candidate_detail?.candidate_name,
  },
  {
    title: "Stage",
    dataIndex: "stage",
    key: "title",
    width: 200,
    render: (stage: string) => stage,
  },
  {
    title: "Job title",
    dataIndex: "offer_position_detail",
    key: "title",
    width: 200,
    render: (offer_position_detail: IPositionDetails) =>
      offer_position_detail?.job_title,
  },
  {
    title: "business unit",
    dataIndex: "offer_position_detail",
    key: "business_unit",
    width: 250,
    render: (offer_position_detail: IPositionDetails) =>
      offer_position_detail?.business_unit?.name,
  },
  {
    title: "region",
    dataIndex: "offer_position_detail",
    key: "region",
    width: 200,
    render: (offer_position_detail: IPositionDetails) =>
      offer_position_detail?.region?.name,
  },
  {
    title: "country",
    dataIndex: "offer_position_detail",
    key: "id",
    width: 200,
    render: (offer_position_detail: IPositionDetails) =>
      offer_position_detail?.country?.name,
  },
  {
    title: "status",
    dataIndex: "status",
    key: "id",
    width: 200,
    render: (status: string) => _.capitalize(status),
  },

  {
    title: "Action",
    key: "id",
    width: 160,
    fixed: "right",
    render: offer => (
      <>
        <div
          onClick={() => handleRowClick(offer?.id, offer?.stage)}
          className="table__action__btn table__action__btn--client"
        >
          View
        </div>
      </>
    ),
  },
];
