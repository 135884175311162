import { FC, useState, useEffect } from "react";

import Table from "@components/Table/gradeTable";
import Modal from "@components/Modal";
import { IModal, IVersion } from "@types";
import {
  ErrorServices,
  useFetchSalaryRangeVersionsQuery,
  useUpdateSalaryRangeVersionMutation,
} from "@services";
import { getVersionsColumns } from "../config";
import { useTypedSelector } from "@hooks";

interface ISalaryRangeVersions extends IModal {
  download: (id?: number | undefined) => void;
}

const SalaryRangeVersions: FC<ISalaryRangeVersions> = ({
  isVisible,
  setIsVisible,
  download,
}) => {
  const { company } = useTypedSelector(state => state.auth?.user!);
  const { id: company_id } = company!;
  const { data: versions, isLoading } = useFetchSalaryRangeVersionsQuery({
    page: 1,
    company_id,
  });
  const [updateSalaryRangeVersion] = useUpdateSalaryRangeVersionMutation();
  const { data } = versions || {};
  const [versionId, setVersionId] = useState<number | null>(null);

  useEffect(() => {
    if (data?.length) {
      const { id } = data?.find(
        (version: IVersion) => version?.is_active === "TRUE"
      );
      setVersionId(id);
    }
  }, [data]);

  const handleActive = async (id: number) => {
    try {
      setVersionId(id);
      await updateSalaryRangeVersion({ id, company_id }).unwrap();
    } catch (error) {
      ErrorServices(error);
      console.log(error);
    }
  };

  const versionsColumns = getVersionsColumns({
    handleActive,
    versionId,
    download,
  });
  return (
    <Modal
      mode="versions"
      footer={null}
      title="Previous Versions of Salary Ranges"
      isVisible={isVisible}
      width={855}
      setIsVisible={setIsVisible}
    >
      <div className="py-12">
        <Table
          isLoading={isLoading}
          data={data}
          columns={versionsColumns}
          pagination={false}
          customStyles="m-4"
        />
      </div>
    </Modal>
  );
};

export default SalaryRangeVersions;
