import {
  EditOutlined,
  DeleteOutlined,
  LoadingOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import { Switch, TableColumnsType } from "antd";
import moment from "moment";
import { ICountry, IJobGrade, IVersion } from "@types";
import { checkPermission } from "@utils";
import { permissions } from "@router";
import { dateFormat } from "@constants";

export const getColumns = ({
  deleteJobGrade,
  jobGrade_id,
  isDeleting,
  editJobGrade,
}: {
  isDeleting: boolean;
  deleteJobGrade: (id: number) => Promise<void>;
  jobGrade_id: React.MutableRefObject<any>;
  editJobGrade: (jobGrade: IJobGrade) => void;
}): TableColumnsType<any> => [
  {
    title: "job grade",
    dataIndex: "grade",
    key: "grade",
    width: "15%",
  },
  {
    title: "countries",
    key: "countries",
    width: "40%",
    render: (jobGrade: IJobGrade) =>
      jobGrade?.is_global! ? (
        "Global"
      ) : (
        <div>
          {jobGrade?.countries?.map(({ name }: ICountry) => name).join(", ")}
        </div>
      ),
  },
  {
    title: "type",
    dataIndex: "type",
    key: "type",
    width: "20%",
  },
  ...(!checkPermission([
    permissions.DELETE_JOB_GRADE,
    permissions.UPDATE_JOB_GRADE,
  ])
    ? []
    : ([
        {
          title: "actions",
          key: "actions",
          width: "25%",
          fixed: "right",
          render: (jobGrade: IJobGrade) => (
            <>
              {checkPermission(permissions.UPDATE_JOB_GRADE) && (
                <div
                  onClick={() => editJobGrade(jobGrade)}
                  className="table__action__btn table__action__btn--client"
                >
                  <EditOutlined style={{ color: "#1A8245" }} /> Edit
                </div>
              )}
              {checkPermission(permissions.DELETE_JOB_GRADE) && (
                <div
                  onClick={() => deleteJobGrade(jobGrade?.id!)}
                  className="table__action__btn table__action__btn--delete"
                >
                  {isDeleting && jobGrade?.id === jobGrade_id?.current ? (
                    <LoadingOutlined color="#B62424" className="spinner" />
                  ) : (
                    <>
                      <DeleteOutlined style={{ color: "#B62424" }} /> Delete
                    </>
                  )}
                </div>
              )}
            </>
          ),
        },
      ] as any)),
];

export const getVersionsColumns = ({
  handleActive,
  versionId,
  download,
}: {
  handleActive: (id: number) => Promise<void>;
  versionId: number | null;
  download: (id?: number | undefined) => void;
}): TableColumnsType<any> => [
  {
    title: "created at",
    dataIndex: "created_at",
    key: "created_at",
    width: "35%",
    render: (date: string) => {
      return <div>{moment(date).format(dateFormat)}</div>;
    },
  },
  ...(!checkPermission(permissions.CREATE_JOB_GRADE)
    ? []
    : ([
        {
          title: "Active",
          key: "active",
          width: "15%",
          align: "center",
          render: (version: IVersion) => (
            <Switch
              onChange={checked => {
                if (!checked) return;
                handleActive(version?.id);
              }}
              checked={
                versionId === version?.id ||
                (version?.is_active === "TRUE" && versionId === version?.id)
              }
              defaultChecked={version?.is_active === "TRUE"}
            />
          ),
        },
      ] as any)),
  {
    title: "action",
    key: "action",
    width: "15%",
    render: (version: IVersion) => (
      <div
        onClick={() => download(version?.id)}
        className="table__action__btn table__action__btn--client"
      >
      <DownloadOutlined style={{ color: "#1A8245" }} />   Download
      </div>
    ),
  },
];
