import { useHistory } from "react-router-dom";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Row } from "antd";
import {
  AppstoreOutlined,
  HistoryOutlined,
  EditOutlined,
} from "@ant-design/icons";
import Button from "@components/Button";
import Table from "@components/Table";
import { getColumns } from "./config";
import { paths, permissions } from "@router";
import { useFetchOffersQuery } from "@services";
import { useTypedSelector } from "@hooks";
import { handleStage, resetOffers } from "@store/offers";
import { Stage } from "@types";
import { checkPermission } from "@/utils";
import CustomButton from "@/components/customButton";
import { MdOutlineFileUpload } from "react-icons/md";
import SearchBox from "@/components/searchInput";
import CustomTabs from "@/components/customTabs";
import "./index.less";

const Offers = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const [searchInput, setSearchInput] = useState("");
  const [size, setSize] = useState("small");
  const [currentTab, setCurrentTab] = useState("1");

  const { company } = useTypedSelector(state => state.auth?.user!);
  const { id: company_id } = company!;
  const { data: offersData, isFetching: isLoading } = useFetchOffersQuery({
    company_id,
    page,
    status:
      currentTab === "3"
        ? "DRAFTED"
        : currentTab === "1"
        ? "PLACED"
        : "ACCEPTED&statuses=REJECTED",
  });
  const { data: offers, pagination } = offersData || {};

  const handleRowClick = (id: number, stage: Stage) => {
    dispatch(handleStage(stage));
    history.push(`/client/offers/offer/${id}`);
  };

  const columns = getColumns({ handleRowClick });

  return (
    <div>
      <div className="jobCreationBar bg-white px-4 py-6 rounded-lg flex justify-between items-center">
        <div className="title text-themeBlue font-medium text-3xl">
          {currentTab === "3"
            ? "Drafts"
            : currentTab === "1"
            ? "Offers"
            : "Past Offers"}
        </div>
        <div className="flex flex-row justify-between">
          {currentTab === "1" && checkPermission(permissions.CREATE_OFFER) ? (
            <CustomButton
              onClick={() => {
                dispatch(resetOffers());
                history.push(paths.client.offers.create);
              }}
              btnName="Create Offer"
              customClasses="shadow-md font-medium border py-1 px-6 !border-themeBorder"
              iconPosition="left"
            >
              <MdOutlineFileUpload size={20} className="text-white font-bold" />
            </CustomButton>
          ) : (
            <div className="mb-20" />
          )}
        </div>
      </div>
      <div className="jobCreationListing   mt-6 bg-white px-14 py-4 rounded-lg">
        <SearchBox
          placeholder="Type to search..."
          value={searchInput}
          onChange={value => setSearchInput(value)}
        />

        <CustomTabs currentTab={currentTab} onTabChange={setCurrentTab}>
          <div tabKey="1">
            <Row>
              <Table
                isLoading={isLoading}
                data={offers}
                columns={columns}
                scroll={1300}
                pagination={true}
                count={pagination?.count}
                onChangePage={setPage}
                page={page}
              />
            </Row>
          </div>
          <div tabKey="2">
            <Row>
              <Table
                isLoading={isLoading}
                data={offers}
                columns={columns}
                scroll={1300}
                pagination={true}
                count={pagination?.count}
                onChangePage={setPage}
                page={page}
              />
            </Row>
          </div>
          <div tabKey="3">
            <Row>
              <Table
                isLoading={isLoading}
                data={offers}
                columns={columns}
                scroll={1300}
                pagination={true}
                count={pagination?.count}
                onChangePage={setPage}
                page={page}
              />
            </Row>
          </div>
        </CustomTabs>
      </div>
    </div>
  );
};

export default Offers;
