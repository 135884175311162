import FixedCash from "./FixedCash";
import LongTermIncentives from "./LongTermIncentives";
import ShortTermIncentives from "./ShortTermIncentives";
import SignOnBonus from "./SignOnBonus";
import OfferBenefits from "./Benefits";
import Summary from "./Summary";

export const panels = [
  {
    id: "1",
    header: "Fixed Cash / Guaranteed Cash",
    component: <FixedCash />,
  },
  {
    id: "2",
    header: "Short Term Incentives (STI)",
    component: <ShortTermIncentives />,
  },
  {
    id: "3",
    header: "Long Term Incentives (LTI)",
    component: <LongTermIncentives />,
  },

  {
    id: "4",
    header: "Benefits",
    component: <OfferBenefits />,
  },
  {
    id: "5",
    header: "Sign On / One time payment",
    component: <SignOnBonus />,
  },

  {
    id: "6",
    header: "Summary",
    component: <Summary />,
  },
];
