import React from "react";
import { UploadOutlined, LoadingOutlined } from "@ant-design/icons"; // Import your icons here

interface CustomButtonProps {
  btnName?: string;
  onClick?: () => void;
  customClasses?: string;
  disabledProp?: boolean;
  children?: React.ReactNode;
  iconPosition?: "left" | "right"; // Define iconPosition prop
  isLoading?: boolean;
  textStyles?: string;
  styles?: any;
}

const CustomButton: React.FC<CustomButtonProps> = ({
  btnName = "Custom Button",
  onClick,
  customClasses = "",
  disabledProp,
  children,
  iconPosition = "left",
  isLoading = false,
  textStyles,
  styles,
}) => {
  return (
    <button
      style={styles}
      disabled={disabledProp || isLoading} // Disable button when loading
      onClick={onClick}
      className={`flex items-center justify-center bg-themeBlue text-white py-2 px-5 rounded-md  ${
        disabledProp ? "bg-gray-400" : ""
      } ${customClasses}`}
    >
      {iconPosition === "left" && !isLoading && children && (
        <span className="mr-2">{children}</span>
      )}

      {isLoading ? (
        <LoadingOutlined style={{ fontSize: 16 }} spin /> // Show loading spinner
      ) : (
        <>
          {btnName}
          {iconPosition === "right" && !isLoading && children && (
            <span className={`ml-2  ${textStyles} text-black`}>{children}</span>
          )}
        </>
      )}
    </button>
  );
};

export default CustomButton;
