import { useDispatch } from "react-redux";
import { useState, useRef, useEffect } from "react";
import Checkbox from "antd/lib/checkbox/Checkbox";
import TextArea from "antd/lib/input/TextArea";
import { Button, Col, Input, message, Row, Select } from "antd";
import { useHistory } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";

import { useTypedSelector } from "@hooks";
import { types } from "./config";
import {
  ErrorServices,
  useCreateOfferDetailsMutation,
  useFetchAllBusinessUnitsQuery,
  useFetchAllCompanyJobGradesQuery,
  useFetchAllJobFunctionsQuery,
  useFetchCurrenciesQuery,
  useUpdateOfferDetailsMutation,
  useFetchCompanyCountriesQuery,
} from "@services";
import {
  handlePositionDetails,
  handleStage,
  resetOfferModeller,
  resetOffers,
} from "@store/offers";
import {
  IBusinessUnitItem,
  ICountry,
  ICurrency,
  IJobFunctionItem,
  IJobGrade,
  IRegion,
  ISubJobFunctionItem,
} from "@types";
import CustomButton from "@/components/customButton";
import CollapsibleCard from "@/components/CollapsibleCard";

const { Option } = Select;

const OfferDetails = ({
  offerModeller,
  onResponse,
  detailedView,
}: {
  offerModeller?: boolean;
  onResponse: any;
  detailedView: any;
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const draft = useRef<boolean>(false);
  const is_create = window.location.pathname.includes("/create");
  const { offer } = useTypedSelector(state => state?.offers);
  const [customView, setCustomView] = useState(detailedView);

  const readOnly =
    offerModeller || (offer && offer?.status !== "DRAFTED") ? true : false;
  const { company } = useTypedSelector(state => state.auth?.user!);
  const { id: company_id } = company!;
  const { positionDetails } = useTypedSelector(state => state?.offers);
  const {
    grade,
    job_title,
    type,
    region,
    country_id,
    job_function,
    sub_job_function_id,
    reporting_grade,
    position_owner,
    city,
    grade_type,
    reporting_title,
    cost_centre,
    is_critical,
    position_comment,
    business_unit,
    currency_id,
  } = positionDetails;

  const { data: gradesData, isLoading: isLoadingGrades } =
    useFetchAllCompanyJobGradesQuery({ company_id });
  const { data: countriesData } = useFetchCompanyCountriesQuery({
    company_id,
  });
  const { data: companyCountries } = countriesData || {};
  const { data: grades } = gradesData || {};
  const { data: jobFunctionsData, isLoading: isLoadingJobFunctions } =
    useFetchAllJobFunctionsQuery(null);
  const { data: jobFunctions } = jobFunctionsData || {};
  const { data: businessUnitsData, isLoading: isLoadingBusinessUnits } =
    useFetchAllBusinessUnitsQuery({ company_id });
  const { data: businessUnits } = businessUnitsData || {};
  const [subJobFunctions, setSubJobFunctions] = useState<ISubJobFunctionItem[]>(
    []
  );
  const { data: currenciesData, isLoading: isFetchingCurrencies } =
    useFetchCurrenciesQuery(null);
  const { data: currencies } = currenciesData || {};
  const [regions, setRegions] = useState<Partial<IRegion>[]>([]);
  const [countries, setCountries] = useState<ICountry[]>([]);
  const [createOfferDetails, { isLoading: isCreatingOfferDetails }] =
    useCreateOfferDetailsMutation();
  const [updateOfferDetails, { isLoading: isUpdatingOfferDetails }] =
    useUpdateOfferDetailsMutation();

  const disabled =
    !type ||
    !grade_type ||
    !grade ||
    !job_function ||
    !sub_job_function_id ||
    !country_id ||
    !region ||
    !business_unit ||
    !job_title ||
    !currency_id;

  useEffect(() => {
    // if (!is_create) {
      const jobFunction = jobFunctions?.find(
        (func: Partial<IJobFunctionItem>) => func.id === job_function?.id
      );
      const selected_grade = grades?.find(
        (x: Partial<IJobGrade>) => x.grade === grade
      );
      const bu = businessUnits?.find(
        (businessUnit: Partial<IBusinessUnitItem>) =>
          businessUnit.id === business_unit?.id
      );
      setSubJobFunctions(jobFunction?.job_sub_functions);
      setRegions(bu?.regions);
      setCountries(
        selected_grade?.is_global ? companyCountries : selected_grade?.countries
      );
    // }
  }, [
    businessUnits,
    business_unit?.id,
    companyCountries,
    grade,
    grades,
    is_create,
    jobFunctions,
    job_function?.id,
  ]);

  useEffect(() => {
    if (currency_id === null) {
      let value = company?.currency?.id;
      handleChange({
        target: { name: "currency_id", value },
      });
    }
  }, []);

  const handleChange = (event: any) => {
    if (readOnly) return;
    dispatch(
      handlePositionDetails({
        [event.target.name]: event.target.value,
      })
    );
  };

  const handleSubmit = async (is_draft: boolean) => {
    if (offer && offer.status !== "DRAFTED") {
      return dispatch(handleStage("CANDIDATE_DETAILS"));
    }
    try {
      draft.current = is_draft;
      const body: any = {
        ...positionDetails,
        business_unit_id: business_unit?.id,
        job_function_id: job_function?.id,
        region_id: region?.id,
        is_draft,
      };
      delete body["business_unit"];
      delete body["job_function"];
      delete body["region"];
      delete body["currency"];

      if (offer?.status === "DRAFTED") {
        await updateOfferDetails({ company_id, id: offer?.id, body }).unwrap();
      } else {
        await createOfferDetails({ company_id, body }).unwrap();
      }

      dispatch(resetOfferModeller());
      message.success("Offer details saved successfully");
    } catch (err) {
      console.log(err);
      ErrorServices(err);
    }
  };

  const handleUpdateClick = () => {
    onResponse(0);
    setCustomView(!customView);
  };

  const handleSaveClick = () => {
    console.log("Save button clicked");
  };

  let country_name = countries && countries.filter(x => x.id === country_id);
  let currency_current =
    currencies && currencies.filter(x => x.id === currency_id);
  let sub_function =
    subJobFunctions &&
    subJobFunctions.filter(x => x.id === sub_job_function_id);

  return (
    <>
      {customView ? (
        <CollapsibleCard
          onUpdateClick={handleUpdateClick}
          onSaveClick={handleSaveClick}
          header={
            <div className="flex flex-row items-center">
              <h2 className="text-xl font-semibold">Offer Details</h2>
            </div>
          }
        >
          <h1 className="font-semibold text-lg uppercase">Offer Details</h1>
          <div className="flex flex-row justify-between mb-6">
            {/* Section 1 */}
            <div className="flex flex-col space-y-4 w-1/2">
              <div className="flex flex-row items-center mt-6">
                <h4 className="font-semibold text-sm text-themeInputTitleColor w-1/3">
                  Type
                </h4>
                <p className="font-medium text-sm text-themeInputTitleColor">
                  {type ? type : "N/A"}
                </p>
              </div>
              <div className="flex flex-row items-center">
                <h4 className="font-semibold text-sm text-themeInputTitleColor w-1/3">
                  Job Function
                </h4>
                <p className="font-medium text-sm text-themeInputTitleColor">
                  {job_function?.name ? job_function?.name : "N/A"}
                </p>
              </div>

              <div className="flex flex-row items-center">
                <h4 className="font-semibold text-sm text-themeInputTitleColor w-1/3">
                  Sub-Job Function
                </h4>
                <p className="font-medium text-sm text-themeInputTitleColor">
                  {sub_job_function_id
                    ? sub_function && sub_function[0]?.name
                    : "N/A"}
                </p>
              </div>

              <div className="flex flex-row items-center">
                <h4 className="font-semibold text-sm text-themeInputTitleColor w-1/3">
                  Business Unit
                </h4>
                <p className="font-medium text-sm text-themeInputTitleColor">
                  {business_unit?.name ? business_unit?.name : "N/A"}
                </p>
              </div>

              <div className="flex flex-row items-center">
                <h4 className="font-semibold text-sm text-themeInputTitleColor w-1/3">
                  Region
                </h4>
                <p className="font-medium text-sm text-themeInputTitleColor">
                  {region?.name ? region?.name : "N/A"}
                </p>
              </div>

              <div className="flex flex-row items-center">
                <h4 className="font-semibold text-sm text-themeInputTitleColor w-1/3">
                  Current Currency
                </h4>
                <p className="font-medium text-sm text-themeInputTitleColor">
                  {currency_id
                    ? currency_current && currency_current[0]?.name
                    : "N/A"}
                </p>
              </div>

              <div className="flex flex-row items-center">
                <h4 className="font-semibold text-sm text-themeInputTitleColor w-1/3">
                  Grade
                </h4>
                <p className="font-medium text-sm text-themeInputTitleColor">
                  {grade ? grade : "N/A"}
                </p>
              </div>
              <div className="flex flex-row items-center">
                <h4 className="font-semibold text-sm text-themeInputTitleColor w-1/3">
                  Country
                </h4>
                <p className="font-medium text-sm text-themeInputTitleColor">
                  {country_id ? country_name && country_name[0]?.name : "N/A"}
                </p>
              </div>
            </div>

            {/* Section 2 */}
            <div className="flex flex-col space-y-4 w-1/2">
              <div className="flex flex-row items-center">
                <h4 className="font-semibold text-sm text-themeInputTitleColor w-1/3">
                  City
                </h4>
                <p className="font-medium text-sm text-themeInputTitleColor">
                  {city ? city : "N/A"}
                </p>
              </div>
              <div className="flex flex-row items-center">
                <h4 className="font-semibold text-sm text-themeInputTitleColor w-1/3">
                  Job Title
                </h4>
                <p className="font-medium text-sm text-themeInputTitleColor">
                  {job_title ? job_title : "N/A"}
                </p>
              </div>
              <div className="flex flex-row items-center">
                <h4 className="font-semibold text-sm text-themeInputTitleColor w-1/3">
                  Position Owner
                </h4>
                <p className="font-medium text-sm text-themeInputTitleColor">
                  {position_owner ? position_owner : "N/A"}
                </p>
              </div>

              <div className="flex flex-row items-center">
                <h4 className="font-semibold text-sm text-themeInputTitleColor w-1/3">
                  Job Grade Type
                </h4>
                <p className="font-medium text-sm text-themeInputTitleColor">
                  {grade_type ? grade_type : "N/A"}
                </p>
              </div>

              <div className="flex flex-row items-center">
                <h4 className="font-semibold text-sm text-themeInputTitleColor w-1/3">
                  Reporting to Grade
                </h4>
                <p className="font-medium text-sm text-themeInputTitleColor">
                  {reporting_grade ? reporting_grade : "N/A"}
                </p>
              </div>

              <div className="flex flex-row items-center">
                <h4 className="font-semibold text-sm text-themeInputTitleColor w-1/3">
                  Reporting Title
                </h4>
                <p className="font-medium text-sm text-themeInputTitleColor">
                  {reporting_title ? reporting_title : "N/A"}
                </p>
              </div>

              <div className="flex flex-row items-center">
                <h4 className="font-semibold text-sm text-themeInputTitleColor w-1/3">
                  Cost Centre
                </h4>
                <p className="font-medium text-sm text-themeInputTitleColor">
                  {cost_centre ? cost_centre : "N/A"}
                </p>
              </div>

              <div className="flex flex-row items-center">
                <h4 className="font-semibold text-sm text-themeInputTitleColor w-1/3">
                  Critical Role?
                </h4>
                <p className="font-medium text-sm text-themeInputTitleColor">
                  {is_critical ? "Yes" : "No"}
                </p>
              </div>

              <div className="flex flex-row items-center">
                <h4 className="font-semibold text-sm text-themeInputTitleColor w-1/3">
                  Position Comment
                </h4>
                <p className="font-medium text-sm text-themeInputTitleColor">
                  {position_comment ? position_comment : "M/A"}
                </p>
              </div>

              {/* Add more rows for Section 2 as needed */}
            </div>
          </div>
        </CollapsibleCard>
      ) : (
        <div>
          <div>
            <Row justify="space-between" className="mb-2">
              <Col span={11}>
                <label className=" leading-10 mt-4 ">
                  <span className="font-medium text-themeBlue text-lg">
                    Type
                  </span>
                </label>
                <Select
                  className="custom-select"
                  size="large"
                  showArrow
                  placeholder="Select offer type from here..."
                  showSearch={false}
                  onChange={value =>
                    !readOnly &&
                    handleChange({ target: { name: "type", value } })
                  }
                  value={type || undefined}
                >
                  {types?.map(({ id, label, value }) => (
                    <Option key={id} value={value}>
                      {label}
                    </Option>
                  ))}
                </Select>
              </Col>
              <Col span={11}>
                <label className="font-medium text-themeBlue text-lg leading-10 mt-4 ">
                  <span className="font-medium text-themeBlue text-lg ">
                    {" "}
                    Job Function
                  </span>
                </label>
                <Select
                  className="custom-select"
                  loading={isLoadingJobFunctions}
                  size="large"
                  showArrow
                  placeholder="Select job function from here..."
                  showSearch={false}
                  onChange={(serialized_jobFunction: string) => {
                    if (readOnly) return;
                    const jobFunction = JSON.parse(serialized_jobFunction);
                    handleChange({
                      target: {
                        name: "job_function",
                        value: { id: jobFunction?.id, name: jobFunction?.name },
                      },
                    });
                    dispatch(
                      handlePositionDetails({
                        sub_job_function_id: null,
                      })
                    );
                    setSubJobFunctions(jobFunction?.job_sub_functions);
                  }}
                  value={job_function?.name || undefined}
                >
                  {jobFunctions?.map(
                    (jobFunction: Partial<IJobFunctionItem>) => (
                      <Option
                        key={jobFunction?.id}
                        value={JSON.stringify(jobFunction)}
                      >
                        {jobFunction?.name}
                      </Option>
                    )
                  )}
                </Select>
              </Col>
            </Row>
            <Row justify="space-between" className="mb-2">
              <Col span={11}>
                <label className="font-medium text-themeBlue text-lg leading-10 mt-4 ">
                  <span className="font-medium text-themeBlue text-lg ">
                    Sub-Job Function
                  </span>
                </label>
                <Select
                  className="custom-select"
                  disabled={!job_function?.id}
                  size="large"
                  showArrow
                  placeholder="Select sub-job function from here..."
                  showSearch={false}
                  onChange={value =>
                    !readOnly &&
                    handleChange({
                      target: { name: "sub_job_function_id", value },
                    })
                  }
                  value={sub_job_function_id || undefined}
                >
                  {subJobFunctions
                    ?.slice()
                    ?.sort(function (a: any, b: any) {
                      if (a.name < b.name) {
                        return -1;
                      }
                      if (a.name > b.name) {
                        return 1;
                      }
                      return 0;
                    })
                    ?.map(({ id, name }: ISubJobFunctionItem) => (
                      <Option key={id} value={id}>
                        {name}
                      </Option>
                    ))}
                </Select>
              </Col>
              <Col span={11}>
                <label className="font-medium text-themeBlue text-lg leading-10 mt-4 ">
                  <span className="font-medium text-themeBlue text-lg ">
                    Business Unit
                  </span>
                </label>
                <Select
                  className="custom-select"
                  loading={isLoadingBusinessUnits}
                  size="large"
                  showArrow
                  placeholder="Select business unit from here..."
                  showSearch={false}
                  value={business_unit?.name || undefined}
                  onChange={(serialized_businessUnit: string) => {
                    if (readOnly) return;
                    const businessUnit = JSON.parse(serialized_businessUnit);
                    handleChange({
                      target: {
                        name: "business_unit",
                        value: {
                          id: businessUnit?.id,
                          name: businessUnit?.name,
                        },
                      },
                    });
                    dispatch(
                      handlePositionDetails({
                        region: null,
                      })
                    );
                    setRegions(businessUnit?.regions);
                  }}
                >
                  {businessUnits?.map(
                    (businessUnit: Partial<IBusinessUnitItem>) => (
                      <Option
                        key={businessUnit?.id}
                        value={JSON.stringify(businessUnit)}
                      >
                        {businessUnit?.name}
                      </Option>
                    )
                  )}
                </Select>
              </Col>
            </Row>
            <Row justify="space-between" className="mb-2">
              <Col span={11}>
                <label className="font-medium text-themeBlue text-lg leading-10 mt-4 ">
                  <span className="font-medium text-themeBlue text-lg ">
                    Region
                  </span>
                </label>
                <Select
                  className="custom-select"
                  disabled={!business_unit?.id}
                  size="large"
                  showArrow
                  placeholder="Select region from here..."
                  showSearch={false}
                  value={region?.name || undefined}
                  onChange={(region: string) => {
                    if (readOnly) return;
                    const { name, id, countries } = JSON.parse(region);
                    handleChange({
                      target: {
                        name: "region",
                        value: { name, id },
                      },
                    });
                    handleChange({
                      target: {
                        name: "country_id",
                        value: null,
                      },
                    });
                    setCountries(countries);
                  }}
                >
                  {regions?.map((region: Partial<IRegion>) => (
                    <Option key={region?.id} value={JSON.stringify(region)}>
                      {region?.name}
                    </Option>
                  ))}
                </Select>
              </Col>
              <Col span={11}>
                <label className="font-medium text-themeBlue text-lg leading-10 mt-4 ">
                  <span className="font-medium text-themeBlue text-lg ">
                    Current Currency
                  </span>
                </label>
                <Select
                  className="custom-select"
                  value={currency_id || company?.currency?.id}
                  loading={isFetchingCurrencies}
                  onChange={value =>
                    !readOnly &&
                    handleChange({
                      target: { name: "currency_id", value },
                    })
                  }
                  showArrow={true}
                  showSearch={true}
                  size="large"
                  filterOption={(input: any, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  placeholder="Select currency from here..."
                >
                  {currencies?.map(({ id, name, code }: ICurrency) => (
                    <Option key={id} value={id}>
                      {`${name} (${code})`}
                    </Option>
                  ))}
                </Select>
              </Col>
            </Row>

            <Row justify="space-between" className="mb-2">
              <Col span={11}>
                <label className="font-medium text-themeBlue text-lg leading-10 mt-4 ">
                  <span className="font-medium text-themeBlue text-lg ">
                    {" "}
                    Grade
                  </span>
                </label>
                <Select
                  className="custom-select"
                  loading={isLoadingGrades}
                  size="large"
                  showArrow
                  placeholder="Select grade from here..."
                  showSearch={false}
                  value={grade || undefined}
                  onChange={(grade: string) => {
                    !readOnly &&
                      handleChange({
                        target: {
                          name: "grade",
                          value: grade,
                        },
                      });

                    handleChange({
                      target: { name: "reporting_grade", value: null },
                    });
                  }}
                >
                  {grades?.map((grade: Partial<IJobGrade>) => (
                    <Option key={grade?.id!} value={grade?.grade!}>
                      {grade?.grade}
                    </Option>
                  ))}
                </Select>
              </Col>
              <Col span={11}>
                <label className="font-medium text-themeBlue text-lg leading-10 mt-4 ">
                  <span className="font-medium text-themeBlue text-lg ">
                    {" "}
                    Country
                  </span>
                </label>
                <Select
                  className="custom-select"
                  disabled={!region}
                  size="large"
                  showArrow
                  placeholder="Select country from here..."
                  showSearch={false}
                  value={country_id || undefined}
                  onChange={value =>
                    !readOnly &&
                    handleChange({ target: { name: "country_id", value } })
                  }
                >
                  {countries?.map((country: ICountry) => (
                    <Option key={country?.id} value={country.id}>
                      {country?.name}
                    </Option>
                  ))}
                </Select>
              </Col>
            </Row>

            <Row justify="space-between" className="mb-2">
              <Col span={11}>
                <label className="font-medium text-themeBlue text-lg leading-10 mt-4 ">
                  <span className="font-medium text-themeBlue text-lg ">
                    {" "}
                    City
                  </span>
                </label>
                <Input
                  readOnly={readOnly}
                  className={`w-full shadow-md my-2 font-semibold text-sm outline-none border border-themeInputBorder bg-white px-3 py-3 rounded-md`}
                  size="large"
                  name="city"
                  placeholder="Enter city here..."
                  onChange={handleChange}
                  value={city || undefined}
                />
              </Col>
            </Row>
          </div>
          <div className="offers__form__fields">
            <div className="font-bold text-xl  text-center text-themeBlue my-12">
              <span className="font-semibold text-2xl text-themeBlue ">
                Other Details
              </span>
            </div>
            <Row justify="space-between" className="mb-2">
              <Col span={11}>
                <label className="font-medium text-themeBlue text-lg leading-10 mt-4 ">
                  <span className="font-medium text-themeBlue text-lg ">
                    Job Title
                  </span>
                </label>

                <Input
                  readOnly={readOnly}
                  className={`w-full shadow-md my-2 font-semibold text-sm outline-none border border-themeInputBorder bg-white px-3 py-3 rounded-md`}
                  name="job_title"
                  onChange={handleChange}
                  value={job_title || ""}
                  size="large"
                  placeholder="Enter job title here..."
                />
              </Col>
              <Col span={11}>
                <label className="font-medium text-themeBlue text-lg leading-10 mt-4 ">
                  <span className="font-medium text-themeBlue text-lg ">
                    {" "}
                    Position Owner
                  </span>
                </label>
                <Input
                  readOnly={readOnly}
                  className={`w-full shadow-md my-2 font-semibold text-sm outline-none border border-themeInputBorder bg-white px-3 py-3 rounded-md`}
                  name="position_owner"
                  onChange={handleChange}
                  value={position_owner || ""}
                  size="large"
                  placeholder="Enter position owner here..."
                />
              </Col>
            </Row>

            <Row justify="space-between" className="mb-2">
              <Col span={11}>
                <label className="font-medium text-themeBlue text-lg leading-10 mt-4 ">
                  <span className="font-medium text-themeBlue text-lg ">
                    Job Grade Type
                  </span>
                </label>
                <Input
                  readOnly={readOnly}
                  size="large"
                  className={`w-full shadow-md my-2 font-semibold text-sm outline-none border border-themeInputBorder bg-white px-3 py-3 rounded-md`}
                  name="grade_type"
                  onChange={handleChange}
                  placeholder="Enter job grade type here..."
                  value={grade_type || ""}
                />
              </Col>
              <Col span={11}>
                <label className="font-medium text-themeBlue text-lg leading-10 mt-4 ">
                  <span className="font-medium text-themeBlue text-lg ">
                    Reporting to Grade
                  </span>
                </label>
                <Select
                  className="custom-select"
                  size="large"
                  showArrow
                  placeholder="Select reporting grade from here..."
                  showSearch={false}
                  value={reporting_grade || undefined}
                  onChange={value => {
                    !readOnly &&
                      handleChange({
                        target: { name: "reporting_grade", value },
                      });
                  }}

                  // onChange={value => {

                  //   // Find the index of the current grade
                  //   const matchIndex = grades.findIndex(
                  //     (item: any) => item?.grade === grade
                  //   );

                  //   // Ensure the current grade exists in the grades array
                  //   if (matchIndex === -1) {
                  //     handleChange({
                  //       target: { name: "reporting_grade", value: null },
                  //     });
                  //     message.error("Please select grade first!");
                  //     return;
                  //   }

                  //   // Validate that the selected value is not less than the current grade
                  //   const selectedGradeIndex = grades.findIndex(
                  //     (item: any) => item?.grade === value
                  //   );

                  //   if (selectedGradeIndex === -1) {
                  //     handleChange({
                  //       target: { name: "reporting_grade", value: null },
                  //     });
                  //     message.error(
                  //       "Selected grade not found in the grades list!"
                  //     );
                  //     return;
                  //   }

                  //   if (selectedGradeIndex < matchIndex) {
                  //     handleChange({
                  //       target: { name: "reporting_grade", value: null },
                  //     });
                  //     message.error(
                  //       "Reporting grade cannot be smaller than the grade!"
                  //     );
                  //     return;
                  //   }

                  //   // Proceed with updating the value if all conditions are met
                  //   if (!readOnly) {
                  //     handleChange({
                  //       target: { name: "reporting_grade", value },
                  //     });
                  //   }
                  // }}
                >
                  {grades?.map(({ id, grade }: Partial<IJobGrade>) => (
                    <Option key={id} value={grade!}>
                      {grade}
                    </Option>
                  ))}
                </Select>
              </Col>
            </Row>
            <Row justify="space-between" className="mb-2">
              <Col span={11}>
                <label className="font-medium text-themeBlue text-lg leading-10 mt-4 ">
                  <span className="font-medium text-themeBlue text-lg ">
                    Reporting Title
                  </span>
                </label>
                <Input
                  readOnly={readOnly}
                  className={`w-full shadow-md my-2 font-semibold text-sm outline-none border border-themeInputBorder bg-white px-3 py-3 rounded-md`}
                  name="reporting_title"
                  onChange={handleChange}
                  value={reporting_title || ""}
                  size="large"
                  placeholder="Enter reporting title here..."
                />
              </Col>
              <Col span={11}>
                <label className="font-medium text-themeBlue text-lg leading-10 mt-4 ">
                  <span className="font-medium text-themeBlue text-lg ">
                    Cost Centre
                  </span>
                </label>
                <Input
                  readOnly={readOnly}
                  name="cost_centre"
                  className={`w-full shadow-md my-2 font-semibold text-sm outline-none border border-themeInputBorder bg-white px-3 py-3 rounded-md`}
                  onChange={handleChange}
                  value={cost_centre || ""}
                  size="large"
                  placeholder="Enter cost center here..."
                />
                <br />
                <Checkbox
                  checked={is_critical}
                  onChange={event =>
                    handleChange({
                      target: {
                        name: "is_critical",
                        value: event.target.checked,
                      },
                    })
                  }
                  className="offers__form__fields__checkbox mt-4"
                >
                  <span className={"font-medium text-lg text-themeBlue"}>
                    Critical Role?
                  </span>
                </Checkbox>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <label className="font-medium text-themeBlue text-lg leading-10 mt-4 ">
                  <span className="font-medium text-themeBlue text-lg ">
                    Position Comment
                  </span>
                </label>
                <TextArea
                  readOnly={readOnly}
                  className={`w-full shadow-md my-2 font-semibold text-sm outline-none border border-themeInputBorder bg-white px-3 py-3 rounded-md`}
                  name="position_comment"
                  onChange={handleChange}
                  value={position_comment || ""}
                  rows={5}
                  placeholder="Enter your comment here..."
                />
              </Col>
            </Row>
            {offerModeller ? null : (
              <div className="flex items-center   justify-around p-6 border-t border-solid border-blueGray-200 rounded-b">
                <CustomButton
                  customClasses={`!px-10  py-3 w-3/12  font-semibold ${
                    disabled
                      ? "bg-themeButtonGray text-themeBlack  cursor-not-allowed"
                      : "text-white bg-themeBlue"
                  }`}
                  btnName={"Next"}
                  disabledProp={disabled}
                  onClick={() => handleSubmit(false)}
                >
                  {(isCreatingOfferDetails || isUpdatingOfferDetails) &&
                  !draft?.current ? (
                    <LoadingOutlined className="spinner" />
                  ) : null}
                </CustomButton>

                <CustomButton
                  customClasses={`!px-10  py-3 w-3/12 font-semibold ${
                    disabled
                      ? "bg-themeButtonGray text-themeBlack  cursor-not-allowed"
                      : "text-white bg-themeBlue"
                  }`}
                  btnName={"Save as draft"}
                  disabledProp={disabled}
                  onClick={() => handleSubmit(true)}
                >
                  {(isCreatingOfferDetails || isUpdatingOfferDetails) &&
                  draft?.current ? (
                    <LoadingOutlined className="spinner" />
                  ) : (
                    ""
                  )}
                </CustomButton>

                <CustomButton
                  btnName="Go Back"
                  onClick={() => history.goBack()}
                  customClasses={`!px-10 py-3  w-3/12 ${
                    disabled
                      ? "bg-themeBlue  text-white"
                      : "bg-themeButtonGray  text-themeBlack"
                  } font-semibold`}
                  textStyles="font-semibold"
                ></CustomButton>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default OfferDetails;
