import { FC } from "react";
import { Col, Empty, Pagination, Skeleton, Table as AntTable } from "antd";
import "./table.less";

interface ITableColumn {
  title: string;
  dataIndex: string;
  key: string;
  width?: number;
  // Add more column properties as needed
}

interface ITable {
  data: Array<Record<string, any>>;
  columns: ITableColumn[];
  onRowClick?: (record: Record<string, any>) => void;
  locale?: any;
  isLoading?: boolean;
  page?: number;
  pagination?: boolean;
  onChangePage?: (page: number) => void;
  count?: number;
  scroll?: number;
  scrollY?: number;
  uniqueClass?: string;
}

const Table: FC<ITable> = ({
  columns,
  data,
  onRowClick,
  pagination = false,
  isLoading,
  page = 1,
  count = 10,
  onChangePage,
  locale,
  scroll,
  scrollY,
  uniqueClass = "table2",
}) => {
  const lastColumn = columns[columns.length - 1];
  const mainColumns = columns.slice(0, -1);

  return (
    <Col span={24}>
      <div className="tables-container">
        {/* Render the main table */}
        <div className="main-table">
          <AntTable
            onRow={(record: Record<string, any>) => ({
              onClick: () => onRowClick && onRowClick(record),
            })}
            className={`table ${onRowClick ? "onRowHover" : ""} ${
              window.location.pathname.includes("client") ? "table--client" : ""
            }`}
            scroll={{ x: scroll || "max-content", y: scrollY || "100%" }}
            columns={mainColumns.map(col => ({
              ...col,
              width: col.width || 100, // Default width if not specified
            }))}
            dataSource={isLoading ? [] : data}
            pagination={false}
            rowKey="id"
            locale={
              !data?.length && !isLoading
                ? locale
                : {
                    emptyText: isLoading ? (
                      <>
                        {[1, 2, 3, 4].map(x => (
                          <Skeleton.Input key={x} size="small" active />
                        ))}
                      </>
                    ) : (
                      <Empty />
                    ),
                  }
            }
          />
        </div>

        {lastColumn && (
          <div className="last-column-table">
            <AntTable
              className="last-column-table-inner"
              columns={[lastColumn]}
              dataSource={isLoading ? [] : data}
              pagination={false}
              rowKey="id"
              scroll={{ x: scroll || "max-content", y: scrollY || "100%" }}
              locale={
                !data?.length && !isLoading
                  ? locale
                  : {
                      emptyText: isLoading ? (
                        <>
                          {[1, 2, 3, 4].map(x => (
                            <Skeleton.Input key={x} size="small" active />
                          ))}
                        </>
                      ) : (
                        <Empty />
                      ),
                    }
              }
            />
          </div>
        )}
      </div>

      {/* Pagination if applicable */}
      {data?.length && pagination ? (
        <div className="table__pagination">
          <Pagination
            onChange={onChangePage}
            current={page}
            defaultCurrent={1}
            total={count}
          />
        </div>
      ) : null}
    </Col>
  );
};

export default Table;
