import { Row, Col, Input } from "antd";
import _ from "lodash";
import { useDispatch } from "react-redux";

import { ReactComponent as PlusIcon } from "@assets/images/plus-with-bg.svg";
import { useTypedSelector } from "@hooks";
import { handleOfferModeller, removeCurrency } from "@store/offers";
import { getCurrencyTotal, getTotal, round } from "@utils";
import {
  offersApi,
  useFetchAutoPopulateDataQuery,
  useFetchComparatorDataQuery,
  useFetchOfferCurrencyRateQuery,
} from "@services";
import { useEffect } from "react";

const FixedCash = () => {
  const dispatch = useDispatch();
  const {
    offerModeller: {
      offer_sti: { is_sale_incentive },
    },
  } = useTypedSelector(state => state?.offers);
  const {
    offerModeller: { offer_fixed_cash, offer_sti },
    currencyRate,
    currencyName,
    offer,
    offerCurrency,
  } = useTypedSelector(state => state.offers);
  const currencyCode = currencyName?.split("(")[1]?.replace(")", "") || "";
  const { company } = useTypedSelector(state => state.auth?.user!);
  const { id: company_id } = company!;
  const { data } = useFetchComparatorDataQuery(
    {
      company_id,
      offer_id: offer?.id,
    },
    { skip: !offer?.id }
  );
  const { data: comparatorData } = data || {};
  const readOnly = offer && offer?.status !== "DRAFTED" ? true : false;
  const labelColSpan = currencyCode ? 10 : 12;
  const currencyColSpan = currencyCode ? 7 : 12;



  const { current_currency_total, proposed_currency_total } = getCurrencyTotal(
    offer_fixed_cash,
    currencyRate,
    [
      "current_compa_ratio",
      "current_market_ratio",
      "proposed_compa_ratio",
      "proposed_market_ratio",
    ]
  );

  const handleChange = (event: any) => {
    dispatch(
      handleOfferModeller({
        key: "offer_fixed_cash",
        data: {
          [event.target.name]: +event.target.value || null,
        },
      })
    );
  };

  const addMoreLocally = (is_current: boolean) => {
    const newCurrent = {
      is_current,
      value: null,
      name: null,
      id: +_.uniqueId(),
      readonly: true,
    };
    dispatch(
      handleOfferModeller({
        key: "offer_fixed_cash",
        data: {
          allowances: [...offer_fixed_cash.allowances, newCurrent],
        },
      })
    );
  };

  const handleChangeNewField = (
    id: number,
    value: number | string,
    type: "name" | "value"
  ) => {
    dispatch(
      handleOfferModeller({
        key: "offer_fixed_cash",
        data: {
          allowances: offer_fixed_cash?.allowances?.map(allowance =>
            allowance?.id === id ? { ...allowance, [type]: value } : allowance
          ) as any,
        },
      })
    );
  };

  const removeField = (id: number) => {
    dispatch(
      handleOfferModeller({
        key: "offer_fixed_cash",
        data: {
          allowances: offer_fixed_cash?.allowances?.map(allowance =>
            allowance?.id === id ? { ...allowance, readonly: false } : allowance
          ) as any,
        },
      })
    );
  };

  const removeAllowance = (id: number) => {
    dispatch(
      handleOfferModeller({
        key: "offer_fixed_cash",
        data: {
          allowances: offer_fixed_cash?.allowances?.filter(
            allowance => allowance?.id !== id
          ) as any,
        },
      })
    );
  };

  let id = comparatorData?.currency?.market_currency?.id;
  const currencyId = typeof id === "string" ? Number(id) : id;

  useFetchOfferCurrencyRateQuery(
    {
      code: currencyId,
      company_id,
      offer_id: offer?.id,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !comparatorData?.currency?.market_currency?.id,
    }
  );

  useFetchAutoPopulateDataQuery(
    {
      company_id,
      offer_id: offer?.id,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !offerCurrency,
    }
  );

  useEffect(() => {
    // if (id && id != offer?.offer_position_detail?.currency?.id) {
    //   dispatch(
    //     offersApi.endpoints.fetchOfferCurrencyRate.initiate(
    //       {
    //         code: id,
    //         company_id,
    //         offer_id: offer?.id,
    //       },
    //       {
    //         subscribe: false,
    //         forceRefetch: true,
    //       }
    //     )
    //   );

    //   // updateNew();
    // }

    dispatch(removeCurrency());
  }, [id]);

  // let updateNew = () => {

  //   if(offer_fixed_cash?.allowances.length >0){
  //     let val = offer_fixed_cash?.proposed_annual_base;

  //     dispatch(
  //       handleOfferModeller({
  //         key: "offer_fixed_cash",
  //         data: {
  //           proposed_annual_base: val || null,
  //           proposed_compa_ratio:
  //             (comparatorData?.internal_comparator_data?.internal_salary_range
  //               ?.mid &&
  //               val &&
  //               (offerCurrency != null ? val * offerCurrency : val) /
  //                 comparatorData?.internal_comparator_data?.internal_salary_range
  //                   ?.mid) ||
  //             null,
  //           proposed_market_ratio:
  //             (comparatorData?.external_comparator_data?.market_data_grade?.p50 &&
  //               val &&
  //               val /
  //                 comparatorData?.external_comparator_data?.market_data_grade
  //                   ?.p50) ||
  //             null,
  //         },
  //       })
  //     );

  //   }

  // };

  // useEffect(() => {
  //   updateNew();
  // }, [offerCurrency]);

  return (
    <>
      <Row className="salary-offer">
        <Col className="salary-offer__parent-col" span={12}>
          <Row className="salary-offer__header-row cursor-pointer border-b border-themeBorder bg-themeCore">
            <Col span={12}>
              <div className="sub-heading font-semibold text-lg">Current</div>
            </Col>
          </Row>

          <Row className="salary-offer__content-row">
            <Col className="salary-offer__content-row__col" span={labelColSpan}>
              <div className="salary-offer__content-row__field-upper"></div>

              <div className="salary-offer__content-row__field">
                <label>Annual Base</label>
              </div>
              <div className="salary-offer__content-row__field">
                <label>Compa Ratio</label>
              </div>
              <div className="salary-offer__content-row__field">
                <label>Market Ratio</label>
              </div>

              {offer_fixed_cash?.allowances
                ?.filter(({ is_current }) => is_current)
                ?.map(allowance => {
                  const { name, id } = allowance;
                  return (
                    <div key={id} className="salary-offer__content-row__field">
                      {name?.length && !allowance?.readonly! ? (
                        <label>{name}</label>
                      ) : (
                        <Input
                          className={`w-full shadow-md my-2 font-semibold text-sm outline-none border border-themeInputBorder bg-white px-3 py-3 rounded-md`}
                          readOnly={readOnly}
                          autoFocus
                          onChange={e =>
                            handleChangeNewField(id!, e?.target?.value, "name")
                          }
                          onKeyDown={e => {
                            if (e.key === "Enter") {
                              removeField(id!);
                            }
                          }}
                          value={name || ""}
                          placeholder="Value here..."
                          size="large"
                        />
                      )}
                      {!readOnly && (
                        <div
                          className="salary-offer__content-row__field__remove"
                          onClick={() => removeAllowance(id!)}
                        >
                          <PlusIcon fill="#000" />
                        </div>
                      )}
                    </div>
                  );
                })}

              {!readOnly ? (
                <div
                  onClick={() => addMoreLocally(true)}
                  className="salary-offer__content-row__field--add-more"
                >
                  <PlusIcon fill="#4FAA89" />
                  <span>Add more</span>
                </div>
              ) : (
                <div className="salary-offer__content-row__field--add-more" />
              )}
              {/* <div className="salary-offer__content-row__field salary-offer__content-row__field--total">
              <label>Total Guaranteed Cash</label>
            </div>
            <div className="salary-offer__content-row__field salary-offer__content-row__field--total" /> */}
            </Col>

            <Col
              className="salary-offer__content-row__col"
              span={currencyColSpan}
            >
              <div className="salary-offer__content-row__field-upper">
                {offer?.offer_position_detail?.currency?.code}
              </div>
              <div className="salary-offer__content-row__field">
                <Input
                  className={`w-full shadow-md my-2 font-semibold text-sm outline-none border border-themeInputBorder bg-white px-3 py-3 rounded-md`}
                  readOnly={readOnly}
                  type="number"
                  name="current_annual_base"
                  onChange={handleChange}
                  value={offer_fixed_cash?.current_annual_base || ""}
                  placeholder="Value here..."
                  size="large"
                />
              </div>
              <div className="salary-offer__content-row__field">
                <Input
                  className={`w-full shadow-md my-2 font-semibold text-sm outline-none border border-themeInputBorder bg-white px-3 py-3 rounded-md`}
                  readOnly={readOnly}
                  type="number"
                  name="current_compa_ratio"
                  value={offer_fixed_cash?.current_compa_ratio || ""}
                  onChange={handleChange}
                  placeholder="Value here..."
                  size="large"
                />
              </div>
              <div className="salary-offer__content-row__field">
                <Input
                  className={`w-full shadow-md my-2 font-semibold text-sm outline-none border border-themeInputBorder bg-white px-3 py-3 rounded-md`}
                  readOnly={readOnly}
                  type="number"
                  name="current_market_ratio"
                  value={offer_fixed_cash?.current_market_ratio || ""}
                  onChange={handleChange}
                  placeholder="Value here..."
                  size="large"
                />
              </div>

              {offer_fixed_cash?.allowances
                ?.filter(({ is_current }) => is_current)
                ?.map(({ name, value, id }) => (
                  <div key={id} className="salary-offer__content-row__field">
                    <Input
                      className={`w-full shadow-md my-2 font-semibold text-sm outline-none border border-themeInputBorder bg-white px-3 py-3 rounded-md`}
                      readOnly={readOnly}
                      name={name!}
                      type="number"
                      value={value || ""}
                      onChange={e =>
                        handleChangeNewField(id!, +e.target.value, "value")
                      }
                      placeholder="Value here..."
                      size="large"
                    />
                  </div>
                ))}

              <div className="salary-offer__content-row__field--add-more" />
              {/* <div className="salary-offer__content-row__field salary-offer__content-row__field--total">
              <Input
                className={`w-full shadow-md my-2 font-semibold text-sm outline-none border border-themeInputBorder bg-white px-3 py-3 rounded-md`}
                readOnly
                name="current_total"
                value={offer_fixed_cash?.current_total || ""}
                placeholder="Value here..."
                size="large"
              />
            </div>
            <div className="salary-offer__content-row__field salary-offer__content-row__field--total" /> */}
            </Col>
            {currencyCode ? (
              <Col className="salary-offer__content-row__col" span={7}>
                <div className="salary-offer__content-row__field-upper">
                  {currencyCode}
                </div>
                <div className="salary-offer__content-row__field">
                  <Input
                    className={`w-full shadow-md my-2 font-semibold text-sm outline-none border border-themeInputBorder bg-white px-3 py-3 rounded-md`}
                    value={
                      round(
                        offer_fixed_cash?.current_annual_base! * currencyRate!
                      ) || ""
                    }
                    readOnly
                    placeholder="Value here..."
                    size="large"
                  />
                </div>
                <div className="salary-offer__content-row__field" />
                <div className="salary-offer__content-row__field" />
                {offer_fixed_cash?.allowances
                  ?.filter(({ is_current }) => is_current)
                  ?.map(({ id, value }) => (
                    <div key={id} className="salary-offer__content-row__field">
                      <Input
                        className={`w-full shadow-md my-2 font-semibold text-sm outline-none border border-themeInputBorder bg-white px-3 py-3 rounded-md`}
                        value={round(value! * currencyRate!) || ""}
                        readOnly
                        placeholder="Value here..."
                        size="large"
                      />
                    </div>
                  ))}

                {/* <div className="salary-offer__content-row__field--add-more" />
              <div className="salary-offer__content-row__field salary-offer__content-row__field--total">
                <Input
                  className={`w-full shadow-md my-2 font-semibold text-sm outline-none border border-themeInputBorder bg-white px-3 py-3 rounded-md`}
                  value={current_currency_total || ""}
                  readOnly
                  placeholder="Value here..."
                  size="large"
                />
              </div>
              <div className="salary-offer__content-row__field salary-offer__content-row__field--total" /> */}
              </Col>
            ) : null}
          </Row>
        </Col>

        <Col className="salary-offer__parent-col" span={12}>
          <Row className="salary-offer__header-row cursor-pointer border-b border-themeBorder bg-themeCore">
            <Col span={12}>
              <div className="sub-heading font-semibold text-lg">Proposed</div>
            </Col>
          </Row>
          <Row className="salary-offer__content-row">
            <Col className="salary-offer__content-row__col" span={labelColSpan}>
              <div className="salary-offer__content-row__field-upper"></div>

              <div className="salary-offer__content-row__field">
                <label>Annual Base</label>
              </div>
              <div className="salary-offer__content-row__field">
                <label>Compa Ratio</label>
              </div>
              <div className="salary-offer__content-row__field">
                <label>Market Ratio</label>
              </div>

              {offer_fixed_cash?.allowances
                ?.filter(({ is_current }) => !is_current)
                ?.map(({ name, id, readonly }) => (
                  <div key={id} className="salary-offer__content-row__field">
                    {name?.length && !readonly! ? (
                      <label>{name}</label>
                    ) : (
                      <Input
                        className={`w-full shadow-md my-2 font-semibold text-sm outline-none border border-themeInputBorder bg-white px-3 py-3 rounded-md`}
                        readOnly={readOnly}
                        autoFocus
                        onChange={e =>
                          handleChangeNewField(id!, e?.target?.value, "name")
                        }
                        onKeyDown={e => {
                          if (e.key === "Enter") {
                            removeField(id!);
                          }
                        }}
                        value={name || ""}
                        placeholder="Value here..."
                        size="large"
                      />
                    )}
                    {!readOnly && (
                      <div
                        className="salary-offer__content-row__field__remove"
                        onClick={() => removeAllowance(id!)}
                      >
                        <PlusIcon fill="#000" />
                      </div>
                    )}
                  </div>
                ))}
              {!readOnly ? (
                <div
                  onClick={() => addMoreLocally(false)}
                  className="salary-offer__content-row__field--add-more"
                >
                  <PlusIcon fill="#4FAA89" />
                  <span>Add more</span>
                </div>
              ) : (
                <div className="salary-offer__content-row__field--add-more" />
              )}
              {/* <div className="salary-offer__content-row__field salary-offer__content-row__field--total">
                <label>Total Guaranteed Cash</label>
              </div>
              <div className="salary-offer__content-row__field salary-offer__content-row__field--total">
                <label>Difference</label>
              </div> */}
            </Col>
            <Col
              className="salary-offer__content-row__col"
              span={currencyColSpan}
            >
              <div className="salary-offer__content-row__field-upper">
                {offer?.offer_position_detail?.currency?.code}
              </div>

              <div className="salary-offer__content-row__field">
                <Input
                  className={`w-full shadow-md my-2 font-semibold text-sm outline-none border border-themeInputBorder bg-white px-3 py-3 rounded-md`}
                  disabled={is_sale_incentive}
                  readOnly={readOnly}
                  type="number"
                  name="proposed_annual_base"
                  onChange={event => {
                    const val = +event.target.value;
                    dispatch(
                      handleOfferModeller({
                        key: "offer_fixed_cash",
                        data: {
                          proposed_annual_base: val || null,
                          proposed_compa_ratio:
                            (comparatorData?.internal_comparator_data
                              ?.internal_salary_range?.mid &&
                              val &&
                              (offerCurrency != null
                                ? val * offerCurrency
                                : val) /
                                comparatorData?.internal_comparator_data
                                  ?.internal_salary_range?.mid) ||
                            null,

                          // proposed_market_ratio:
                          //   (comparatorData?.external_comparator_data
                          //     ?.market_data_grade?.p50 &&
                          //     val &&
                          //     val /
                          //       comparatorData?.external_comparator_data
                          //         ?.market_data_grade?.p50) ||
                          //   null,

                          proposed_market_ratio:
                            comparatorData?.external_comparator_data
                              ?.market_data_grade?.p50 && val
                              ? (offerCurrency != null
                                  ? val * offerCurrency
                                  : val) /
                                (comparatorData?.external_comparator_data
                                  ?.market_data_grade_function?.p50 ||
                                  comparatorData?.external_comparator_data
                                    ?.market_data_grade?.p50)
                              : null,
                        },
                      })
                    );

                    dispatch(
                      handleOfferModeller({
                        key: "offer_fixed_cash",
                        data: {
                          allowances: offer_fixed_cash?.allowances?.map(
                            allowance => {
                              return allowance?.is_percentage
                                ? {
                                    ...allowance,
                                    value: allowance?.percentage
                                      ? (val * allowance?.percentage) / 100
                                      : val,
                                  }
                                : allowance;
                            }
                          ),
                        },
                      })
                    );

                    dispatch(
                      handleOfferModeller({
                        key: "offer_sti",
                        data: {
                          bonuses: offer_sti?.bonuses?.map(bonus => {
                            return bonus?.is_percentage && bonus?.is_basic_pay
                              ? {
                                  ...bonus,
                                  value: bonus?.percentage
                                    ? (val * bonus?.percentage) / 100
                                    : val,
                                }
                              : bonus?.is_percentage && !bonus?.is_basic_pay
                              ? {
                                  ...bonus,
                                  value: bonus?.percentage
                                    ? (offer_fixed_cash?.proposed_total *
                                        bonus?.percentage) /
                                      100
                                    : val,
                                }
                              : bonus;
                          }),
                        },
                      })
                    );
                  }}
                  value={
                    is_sale_incentive
                      ?  offer_fixed_cash?.proposed_annual_base || ""
                      : offer_fixed_cash?.proposed_annual_base || ""
                  }
                  placeholder="Value here..."
                  size="large"
                />
              </div>
              <div className="salary-offer__content-row__field">
                <Input
                  className={`w-full shadow-md my-2 font-semibold text-sm outline-none border border-themeInputBorder bg-white px-3 py-3 rounded-md`}
                  readOnly
                  type="number"
                  name="proposed_compa_ratio"
                  value={round(offer_fixed_cash?.proposed_compa_ratio) || ""}
                  placeholder="Value here..."
                  size="large"
                />
              </div>
              <div className="salary-offer__content-row__field">
                <Input
                  className={`w-full shadow-md my-2 font-semibold text-sm outline-none border border-themeInputBorder bg-white px-3 py-3 rounded-md`}
                  readOnly
                  type="number"
                  name="proposed_market_ratio"
                  value={round(offer_fixed_cash?.proposed_market_ratio) || ""}
                  placeholder="Value here..."
                  size="large"
                />
              </div>

              {offer_fixed_cash?.allowances
                ?.filter(({ is_current }) => !is_current)
                ?.map(({ name, value, id,is_percentage }) => (
                  <div key={id} className="salary-offer__content-row__field">
                  
                    <Input
                      className={`w-full shadow-md my-2 font-semibold text-sm outline-none border border-themeInputBorder bg-white px-3 py-3 rounded-md`}
                      readOnly={readOnly}
                      name={name!}
                      contentEditable={false}
                      type="number"
                      value={is_percentage && offer_fixed_cash.proposed_annual_base  ? value : !is_percentage  ? value || "": ""}
                      onChange={e => {
                        if(!is_percentage){
                          handleChangeNewField(id!, +e.target.value, "value");

                        }
                      }}
                      size="large"
                    />
                  </div>
                ))}

              <div className="salary-offer__content-row__field--add-more" />
              {/* <div className="salary-offer__content-row__field salary-offer__content-row__field--total">
                <Input
                  className={`w-full shadow-md my-2 font-semibold text-sm outline-none border border-themeInputBorder bg-white px-3 py-3 rounded-md`}
                  readOnly
                  name="proposed_total"
                  value={offer_fixed_cash?.proposed_total || ""}
                  placeholder="Value here..."
                  size="large"
                  type="number"
                />
              </div>
              <div className="salary-offer__content-row__field salary-offer__content-row__field--total">
                <Input
                  className={`w-full shadow-md my-2 font-semibold text-sm outline-none border border-themeInputBorder bg-white px-3 py-3 rounded-md`}
                  readOnly
                  value={
                    offer_fixed_cash?.proposed_total &&
                    offer_fixed_cash?.current_total
                      ? `${round(
                          (offer_fixed_cash?.proposed_total /
                            offer_fixed_cash?.current_total -
                            1) *
                            100
                        )}%` || ""
                      : ""
                  }
                  placeholder="Value here..."
                  size="large"
                />
              </div> */}
            </Col>
            {currencyCode ? (
              <Col className="salary-offer__content-row__col" span={7}>
                <div className="salary-offer__content-row__field-upper">
                  {currencyCode}
                </div>
                <div className="salary-offer__content-row__field">
                  <Input
                    className={`w-full shadow-md my-2 font-semibold text-sm outline-none border border-themeInputBorder bg-white px-3 py-3 rounded-md`}
                    value={
                      round(
                        offer_fixed_cash?.proposed_annual_base! * currencyRate!
                      ) || ""
                    }
                    readOnly
                    placeholder="Value here..."
                    size="large"
                  />
                </div>
                <div className="salary-offer__content-row__field" />
                <div className="salary-offer__content-row__field" />
                {offer_fixed_cash?.allowances
                  ?.filter(({ is_current }) => !is_current)
                  ?.map(({ id, value }) => (
                    <div key={id} className="salary-offer__content-row__field">
                      <Input
                        className={`w-full shadow-md my-2 font-semibold text-sm outline-none border border-themeInputBorder bg-white px-3 py-3 rounded-md`}
                        value={round(value! * currencyRate!) || ""}
                        readOnly
                        placeholder="Value here..."
                        size="large"
                      />
                    </div>
                  ))}

                <div className="salary-offer__content-row__field--add-more" />
                {/* <div className="salary-offer__content-row__field salary-offer__content-row__field--total">
                  <Input
                    className={`w-full shadow-md my-2 font-semibold text-sm outline-none border border-themeInputBorder bg-white px-3 py-3 rounded-md`}
                    value={proposed_currency_total || ""}
                    readOnly
                    placeholder="Value here..."
                    size="large"
                  />
                </div>
                <div className="salary-offer__content-row__field salary-offer__content-row__field--total">
                  <Input
                    className={`w-full shadow-md my-2 font-semibold text-sm outline-none border border-themeInputBorder bg-white px-3 py-3 rounded-md`}
                    value={
                      offer_fixed_cash?.proposed_annual_base &&
                      offer_fixed_cash?.current_annual_base
                        ? `${round(
                            (offer_fixed_cash?.proposed_annual_base /
                              offer_fixed_cash?.current_annual_base -
                              1) *
                              100
                          )}%` || ""
                        : ""
                    }
                    readOnly
                    placeholder="Value here..."
                    size="large"
                  />
                </div> */}
              </Col>
            ) : null}
          </Row>
        </Col>
      </Row>

      <Row className="salary-offer">
        <Col className="salary-offer__parent-col" span={12}>
          <Row className="salary-offer__content-row__col">
            <Col className="salary-offer__content-row__col" span={labelColSpan}>
              <div className="salary-offer__content-row__field salary-offer__content-row__field--total">
                <label>Total Guaranteed Cash</label>
              </div>
              {/* <div className="salary-offer__content-row__field salary-offer__content-row__field--total" /> */}
            </Col>

            <Col
              className="salary-offer__content-row__col"
              span={currencyColSpan}
            >
              <div className="salary-offer__content-row__field salary-offer__content-row__field--total">
                <Input
                  className={`w-full shadow-md my-2 font-semibold text-sm outline-none border border-themeInputBorder bg-white px-3 py-3 rounded-md`}
                  readOnly
                  name="current_total"
                  value={offer_fixed_cash?.current_total || ""}
                  placeholder="Value here..."
                  size="large"
                />
              </div>
              {/* <div className="salary-offer__content-row__field salary-offer__content-row__field--total" /> */}
            </Col>

            {currencyCode ? (
              <Col className="salary-offer__content-row__col" span={7}>
                <div className="salary-offer__content-row__field salary-offer__content-row__field--total">
                  <Input
                    className={`w-full shadow-md my-2 font-semibold text-sm outline-none border border-themeInputBorder bg-white px-3 py-3 rounded-md`}
                    value={current_currency_total || ""}
                    readOnly
                    placeholder="Value here..."
                    size="large"
                  />
                </div>
                {/* <div className="salary-offer__content-row__field salary-offer__content-row__field--total" /> */}
              </Col>
            ) : null}
          </Row>
        </Col>

        <Col className="salary-offer__parent-col" span={12}>
          <Row className="salary-offer__content-row__col">
            <Col className="salary-offer__content-row__col" span={labelColSpan}>
              <div className="salary-offer__content-row__field salary-offer__content-row__field--total">
                <label>Total Guaranteed Cash</label>
              </div>
            </Col>

            <Col
              className="salary-offer__content-row__col"
              span={currencyColSpan}
            >
              <div className="salary-offer__content-row__field salary-offer__content-row__field--total">
                <Input
                  className={`w-full shadow-md my-2 font-semibold text-sm outline-none border border-themeInputBorder bg-white px-3 py-3 rounded-md`}
                  readOnly
                  name="proposed_total"
                  value={offer_fixed_cash?.proposed_total || ""}
                  placeholder="Value here..."
                  size="large"
                  type="number"
                />
              </div>
              {/* <div className="salary-offer__content-row__field salary-offer__content-row__field--total" /> */}

              {/* <div className="salary-offer__content-row__field salary-offer__content-row__field--total">
                <Input
                  className={`w-full shadow-md my-2 font-semibold text-sm outline-none border border-themeInputBorder bg-white px-3 py-3 rounded-md`}
                  readOnly
                  value={
                    offer_fixed_cash?.proposed_total &&
                    offer_fixed_cash?.current_total
                      ? `${round(
                          (offer_fixed_cash?.proposed_total /
                            offer_fixed_cash?.current_total -
                            1) *
                            100
                        )}%` || ""
                      : ""
                  }
                  placeholder="Value here..."
                  size="large"
                />
                <div
                  className={`w-6/12 ml-4  text-white shadow-md my-2 font-semibold text-sm outline-none border border-themeInputBorder bg-themeBlue px-3 py-2 rounded-md`}
                >
                  {offer_fixed_cash?.proposed_total &&
                  offer_fixed_cash?.current_total
                    ? `${round(
                        (offer_fixed_cash?.proposed_total /
                          offer_fixed_cash?.current_total -
                          1) *
                          100
                      )}%` || ""
                    : "0%"}
                </div>
              </div> */}
            </Col>

            {currencyCode ? (
              <Col className="salary-offer__content-row__col" span={7}>
                <div className="salary-offer__content-row__field salary-offer__content-row__field--total">
                  <Input
                    className={`w-full shadow-md my-2 font-semibold text-sm outline-none border border-themeInputBorder bg-white px-3 py-3 rounded-md`}
                    value={proposed_currency_total || ""}
                    readOnly
                    placeholder="Value here..."
                    size="large"
                  />
                </div>
              </Col>
            ) : null}
          </Row>
        </Col>
      </Row>

      <Row className="salary-offer">
        <Col className="salary-offer__parent-col" span={12}>
          <Row className="salary-offer__content-row__col">
            <Col className="salary-offer__content-row__col" span={labelColSpan}>
              <div className="salary-offer__content-row__field salary-offer__content-row__field--total"></div>
            </Col>

            <Col
              className="salary-offer__content-row__col"
              span={currencyCode ? 14 : currencyColSpan}
            >
              <div className="salary-offer__content-row__field salary-offer__content-row__field--total"></div>
            </Col>
          </Row>
        </Col>

        <Col className="salary-offer__parent-col" span={12}>
          <Row className="salary-offer__content-row__col">
            <Col className="salary-offer__content-row__col" span={labelColSpan}>
              <div className="salary-offer__content-row__field salary-offer__content-row__field--total">
                <label>Difference</label>
              </div>
            </Col>

            <Col
              className="salary-offer__content-row__col"
              span={currencyCode ? 14 : currencyColSpan}
            >
              <div className="salary-offer__content-row__field salary-offer__content-row__field--total">
                <div
                  className={`w-full px-6   text-white shadow-md my-2 font-semibold text-center text-sm outline-none border border-themeInputBorder bg-themeBlue  py-2 rounded-md`}
                >
                  {offer_fixed_cash?.proposed_total &&
                  offer_fixed_cash?.current_total
                    ? `${round(
                        (offer_fixed_cash?.proposed_total /
                          offer_fixed_cash?.current_total -
                          1) *
                          100
                      )}%` || ""
                    : "0%"}
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default FixedCash;
