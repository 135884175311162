import React from "react";
import { Stepper, Step, StepLabel, StepConnector } from "@mui/material";
import { styled } from "@mui/material/styles";
import { StepIconProps } from "@mui/material/StepIcon";
import classNames from "classnames";

// Custom StepIcon component
const CustomStepIcon = styled("div")(({ theme, active, completed }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: 50,
  height: 50,
  borderRadius: "50%",
  zIndex: 100,
  backgroundColor: active || completed ? "#00519D" : "white",
  color: active || completed ? "white" : "#748194",
  border: `1px solid ${active || completed ? "#00519D" : "#D8E2EF"}`,
}));

// Custom connector to style the lines between steps
const CustomStepConnector = styled(StepConnector)(({ theme }) => ({
  marginTop: 14,
  "& .MuiStepConnector-line": {
    borderColor: "#D8E2EF",
    borderWidth: 2,
  },
}));

// Stepper component
const StepperComponent = ({ steps, currentStep, onStepClick }) => (
  <Stepper
    activeStep={currentStep}
    alternativeLabel
    connector={<CustomStepConnector />}
  >
    {steps.map((step, index) => (
      <Step key={step.title} onClick={() => onStepClick(index)}>
        <StepLabel
          StepIconComponent={(props: StepIconProps) => (
            <CustomStepIcon
              {...props}
              active={currentStep >= index}
              completed={currentStep >= index}
            >
              {step.icon}
            </CustomStepIcon>
          )}
        >
          <div
            className={classNames("text-center", {
              "text-[#00519D]": currentStep >= index,
              "text-[#748194]": currentStep < index,
            })}
          >
            {step.title}
          </div>
        </StepLabel>
      </Step>
    ))}
  </Stepper>
);

export default StepperComponent;
