import { FC, useEffect, useState } from "react";
import { Button, Col, DatePicker, Input, Row, Select } from "antd";
import Checkbox from "antd/lib/checkbox/Checkbox";
import moment from "moment";
import { useParams } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";

import Modal from "@components/Modal";
import {
  ICountry,
  ICurrency,
  IJobGrade,
  ILongTermIncentive,
  ILongTermPlan,
  IModal,
} from "@types";
import {
  ErrorServices,
  useCreateLongTermIncentiveMutation,
  useFetchCompanyCountriesQuery,
  useFetchAllCompanyJobGradesQuery,
  useFetchCurrenciesQuery,
  useFetchAllLongTermPlansQuery,
  useUpdateLongTermIncentiveMutation,
} from "@services";
import { useTypedSelector } from "@hooks";
import { showSuccessPopup } from "@utils";
import CustomModal from "@/components/customModel";

const { Option } = Select;

interface IFormValues {
  grade: null | string;
  country_id: null | number;
  currency_id: null | number;
  plan: any;
  is_all_grade: boolean;
  is_global: boolean;
  is_amount: boolean;
  range_type: string;
  year: number | null;
  equity_min: number | null;
  equity_mid: number | null;
  equity_max: number | null;
}

interface IAddLongTermIncentive extends IModal {
  selectedLongTermIncentive: ILongTermIncentive | null;
  setSelectedLongTermIncentive: React.Dispatch<
    React.SetStateAction<ILongTermIncentive | null>
  >;
}

const AddLongTermIncentive: FC<IAddLongTermIncentive> = ({
  isVisible,
  setIsVisible,
  selectedLongTermIncentive,
  setSelectedLongTermIncentive,
}) => {
  const { plan_id } = useParams<{ plan_id?: string }>();
  const { company } = useTypedSelector(state => state.auth?.user!);
  const { id: company_id } = company!;
  const [longTermIncentive, setLongTermIncentive] = useState<IFormValues>({
    grade: null,
    country_id: null,
    currency_id: null,
    plan: null,
    is_all_grade: false,
    is_global: false,
    is_amount: false,
    range_type: "",
    year: null,
    equity_min: null,
    equity_mid: null,
    equity_max: null,
  });
  const { data: plansData, isLoading: isFetchingPlans } =
    useFetchAllLongTermPlansQuery({
      company_id,
    });

  const [stock_data, setStockData] = useState(null);
  const [planGrades, setPlanGrades] = useState<string[]>([]);
  const [planCountries, setPlanCountries] = useState<ICountry[]>([]);
  const { data: plans } = plansData || {};
  const { data: jobGradesData, isLoading: isFetchingGrades } =
    useFetchAllCompanyJobGradesQuery({ company_id });
  const { data: jobGrades } = jobGradesData || {};
  const { data: countriesData, isLoading: isFetchingCountries } =
    useFetchCompanyCountriesQuery({
      company_id,
    });
  const { data: countries } = countriesData || {};
  const gradeList = longTermIncentive?.is_all_grade
    ? jobGrades?.map(({ grade }: IJobGrade) => grade)
    : planGrades;
  const countryList =
    longTermIncentive?.plan?.is_global || longTermIncentive?.is_global
      ? countries
      : planCountries;
  const { data: currenciesData, isLoading: isFetchingCurrencies } =
    useFetchCurrenciesQuery(null);
  const { data: currencies } = currenciesData || {};
  const [createLongTermIncentive, { isLoading: isCreating }] =
    useCreateLongTermIncentiveMutation();
  const [updateLongTermIncentive, { isLoading: isUpdating }] =
    useUpdateLongTermIncentiveMutation();

  useEffect(() => {
    if (plan_id && !selectedLongTermIncentive) {
      const plan = plans?.find((x: any) => x.id === +plan_id);
      setLongTermIncentive(prev => ({
        ...prev,
        plan,
        is_all_grade: plan?.is_all_grade,
        is_global: plan?.is_global,
      }));
      setPlanGrades(
        plan?.is_all_grade
          ? jobGrades?.map(({ grade }: IJobGrade) => grade)
          : plan?.grades
      );
      setPlanCountries(plan?.is_global ? countries : plan?.countries);
    }
  }, [plan_id, plans, selectedLongTermIncentive, countries, jobGrades]);

  useEffect(() => {
    if (selectedLongTermIncentive) {
      const { country, plan } = selectedLongTermIncentive;
      const longTermIncentive: any = {
        ...selectedLongTermIncentive,
        currency_id: 1,
        plan: { id: plan?.id, name: plan?.name },
        country_id: country?.id,
        range_type: plan?.is_amount ? "Value" : "Unit",
      };
      delete longTermIncentive?.country;
      const { countries, grades } =
        plans?.find((x: any) => x.id === +plan?.id) || {};
      setPlanCountries(countries);
      setPlanGrades(grades);
      setLongTermIncentive(longTermIncentive);
      setStockData(plan?.stock_data[0]);
    }
    return () => {
      setSelectedLongTermIncentive(null);
    };
  }, [selectedLongTermIncentive, setSelectedLongTermIncentive, plans]);

  const handleSubmit = async () => {
    try {
      const body = { ...longTermIncentive, plan: longTermIncentive?.plan?.id };
      if (selectedLongTermIncentive) {
        await editLongTermIncentive(body);
      } else {
        await addLongTermIncentive(body);
      }
      setIsVisible(false);
      showSuccessPopup({
        title: `Long Team Incentive ${
          selectedLongTermIncentive ? "Updated!" : "Created!"
        }`,
        desc: `You have successfully ${
          selectedLongTermIncentive ? "updated the" : "created new"
        } long term incentive.`,
        role: "client",
      });
    } catch (error) {
      ErrorServices(error);
      console.log(error);
    }
  };

  const addLongTermIncentive = async (body: any) =>
    await createLongTermIncentive({ company_id, body }).unwrap();

  const editLongTermIncentive = async (body: any) =>
    await updateLongTermIncentive({
      company_id,
      body,
      id: selectedLongTermIncentive?.id,
    }).unwrap();

  return (
    <CustomModal
      width="w-7/12"
      height="600px"
      footer={
        <>
          <button
            className="bg-themeGrey text-themeBlack font-medium text-lg py-2 px-14 rounded-md"
            onClick={() => setIsVisible(false)}
          >
            Cancel
          </button>
          <button
            className={`bg-themeBlue text-white font-medium text-lg py-2 px-14 rounded-md ${
              isCreating || isUpdating ? "opacity-50 cursor-not-allowed" : ""
            }`}
            disabled={
              !longTermIncentive?.equity_max ||
              !longTermIncentive?.equity_mid ||
              !longTermIncentive?.equity_mid ||
              !longTermIncentive?.year ||
              !longTermIncentive?.range_type ||
              !longTermIncentive?.currency_id
            }
            onClick={handleSubmit}
          >
            {isCreating || isUpdating ? (
              <LoadingOutlined className="spinner" />
            ) : selectedLongTermIncentive ? (
              "Update"
            ) : (
              "Create"
            )}
          </button>
        </>
      }
      title={`${
        selectedLongTermIncentive ? "Update" : "Create"
      } a Long Term Incentive`}
      isVisible={isVisible}
    >
      <>
        {stock_data && (
          <Row justify="space-between">
            <Col span={24}>
              <h4 className="font-medium text-themeBlack text-lg leading-10  ">
                Stock Type:{" "}
                <span className="font-semibold">{stock_data?.stock_type}</span>
              </h4>

              <h4 className="font-medium text-themeBlack text-lg leading-10  ">
                Stock Rate:{" "}
                <span className="font-semibold">
                  {stock_data?.stock_value || 100}
                </span>
              </h4>


              <h4 className="font-medium text-themeBlack text-lg leading-10  ">
                Stock Id:
                <span className="font-semibold">
                  {" "}
                  {stock_data?.tracking_id}
                </span>
              </h4>
            </Col>
          </Row>
        )}
        <Row justify="space-between" className="modal__row">
          <Col span={11}>
            <label className="font-medium text-themeInputTitleColor text-lg leading-10 mt-4 ">
              Plan
            </label>
            <Select
              className="custom-select"
              disabled={!!selectedLongTermIncentive || !!plan_id}
              loading={isFetchingPlans}
              size="large"
              showSearch={false}
              showArrow={true}
              value={longTermIncentive?.plan?.name || undefined}
              placeholder="Select plan from here..."
              onChange={(serializedPlan: any) => {
                const { id, grades, name, countries, is_amount } = JSON.parse(
                  serializedPlan
                ) as ILongTermPlan;

                let { stock_data } = JSON.parse(serializedPlan);
               

                setStockData(stock_data[0]);
                setLongTermIncentive(prev => ({
                  ...prev,
                  plan: { id, name },
                  is_global: !countries.length,
                  is_all_grade: !grades.length,
                  grade: null,
                  country_id: null,
                  range_type: is_amount ? "Value" : "Unit",
                }));
                setPlanGrades(grades);
                setPlanCountries(countries);
              }}
            >
              {plans?.map((plan: ILongTermPlan) => (
                <Option key={plan?.id} value={JSON.stringify(plan)}>
                  {`${plan?.name} (${plan?.type?.name})`}
                </Option>
              ))}
            </Select>
          </Col>
          <Col span={11}>
            <label className="font-medium text-themeInputTitleColor text-lg leading-10 mt-4 ">
              Grade
            </label>
            <Select
              className="custom-select"
              disabled={longTermIncentive?.is_all_grade}
              value={
                longTermIncentive?.is_all_grade
                  ? "All"
                  : longTermIncentive?.grade || undefined
              }
              loading={
                selectedLongTermIncentive && isFetchingGrades ? true : false
              }
              showArrow={true}
              size="large"
              showSearch={false}
              placeholder="Select grade from here..."
              onChange={grade => {
                if (grade == "All") {
                  setLongTermIncentive(prev => ({
                    ...prev,
                    is_all_grade: true,
                  }));
                } else {
                  setLongTermIncentive(prev => ({
                    ...prev,
                    is_all_grade: false,
                    grade,
                  }));
                }
              }}
            >
              {gradeList?.length > 0 ? (
                <Option key="ALL" value="All">
                  All
                </Option>
              ) : null}
              {gradeList?.map((grade: string) => (
                <Option key={grade} value={grade}>
                  {grade}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>
        <Row justify="space-between">
          <Col span={11}>
            <label className="font-medium text-themeInputTitleColor text-lg leading-10 mt-4 ">
              Country
            </label>
            <Select
              className="custom-select"
              disabled={longTermIncentive?.is_global}
              value={
                longTermIncentive?.is_global
                  ? -1
                  : longTermIncentive?.country_id || undefined
              }
              showArrow={true}
              loading={
                longTermIncentive?.is_global && isFetchingCountries
                  ? true
                  : false
              }
              size="large"
              showSearch={false}
              placeholder="Select country from here..."
              onChange={
                country_id => {
                  if (country_id == -1) {
                    setLongTermIncentive(prev => ({
                      ...prev,
                      is_global: true,
                    }));
                  } else {
                    setLongTermIncentive(prev => ({
                      ...prev,
                      is_global: false,
                      country_id,
                    }));
                  }
                }
                // setLongTermIncentive(prev => ({
                //   ...prev,
                //   country_id,
                // }))
              }
            >
              {countryList?.length > 0 ? (
                <Option key={-1} value={-1}>
                  All
                </Option>
              ) : null}
              {countryList?.map(({ id, name }: ICountry) => (
                <Option key={id} value={id}>
                  {name}
                </Option>
              ))}
            </Select>
          </Col>

          <Col span={11}>
            <label className="font-medium text-themeInputTitleColor text-lg leading-10 mt-4 ">
              Currency
            </label>
            <Select
              className="custom-select"
              showArrow={true}
              showSearch={true}
              loading={isFetchingCurrencies}
              filterOption={(input: any, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              value={longTermIncentive?.currency_id || undefined}
              onChange={currency_id =>
                setLongTermIncentive(prev => ({
                  ...prev,
                  currency_id,
                }))
              }
              size="large"
              placeholder="Select currency from here..."
            >
              {currencies?.map(({ id, name, code }: ICurrency) => (
                <Option key={id} value={id}>
                  {`${name} (${code})`}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>
        <Row justify="space-between">
          <Col span={11}>
            <label className="font-medium text-themeInputTitleColor text-lg leading-10 mt-4 ">
              {/* Range Type */}
              Grant Basis
            </label>
            <Input
              className={`w-full shadow-sm my-2 font-semibold text-sm outline-none border border-themeInputBorder bg-white px-3 py-3 rounded-md`}
              size="large"
              disabled={true}
              value={longTermIncentive?.range_type}
              onChange={e =>
                setLongTermIncentive(prev => ({
                  ...prev,
                  range_type: e.target.value,
                }))
              }
              placeholder="Enter range type here..."
            />
          </Col>

          <Col span={11}>
            <label className="font-medium text-themeInputTitleColor text-lg leading-10 mt-4 ">
              Equity Min
            </label>
            <Input
              className={`w-full shadow-sm my-2 font-semibold text-sm outline-none border border-themeInputBorder bg-white px-3 py-3 rounded-md`}
              size="large"
              placeholder="Enter equity min"
              type="number"
              value={longTermIncentive?.equity_min || ""}
              onChange={e =>
                setLongTermIncentive(prev => ({
                  ...prev,
                  equity_min: +e.target.value,
                }))
              }
            />
          </Col>
        </Row>
        <Row justify="space-between">
          <Col span={11}>
            <label className="font-medium text-themeInputTitleColor text-lg leading-10 mt-4 ">
              Equity Mid
            </label>
            <Input
              size="large"
              className={`w-full shadow-sm my-2 font-semibold text-sm outline-none border border-themeInputBorder bg-white px-3 py-3 rounded-md`}
              placeholder="Enter equity mid"
              type="number"
              value={longTermIncentive?.equity_mid || ""}
              onChange={e =>
                setLongTermIncentive(prev => ({
                  ...prev,
                  equity_mid: +e.target.value,
                }))
              }
            />
          </Col>
          <Col span={11}>
            <label className="font-medium text-themeInputTitleColor text-lg leading-10 mt-4 ">
              Equity Max
            </label>
            <Input
              size="large"
              className={`w-full shadow-sm my-2 font-semibold text-sm outline-none border border-themeInputBorder bg-white px-3 py-3 rounded-md`}
              placeholder="Enter equity max"
              type="number"
              value={longTermIncentive?.equity_max || ""}
              onChange={e =>
                setLongTermIncentive(prev => ({
                  ...prev,
                  equity_max: +e.target.value,
                }))
              }
            />
          </Col>
        </Row>
        <Row justify="space-between">
          <Col span={24}>
            <label className="font-medium text-themeInputTitleColor text-lg leading-10 mt-4 ">
              Year
            </label>
            <br />
            <DatePicker
              size="large"
              className={`w-full shadow-sm my-2 font-semibold text-sm outline-none border border-themeInputBorder bg-white px-3 py-3 rounded-md`}
              value={
                longTermIncentive?.year
                  ? moment(`${longTermIncentive?.year}-1`)
                  : undefined
              }
              onChange={(_, year) =>
                setLongTermIncentive(prev => ({ ...prev, year: +year }))
              }
              picker="year"
            />
          </Col>
        </Row>
        {/* <Row justify="space-between">
          <Col className="align-center-vertically" span={7}>
            <Checkbox
              checked={longTermIncentive?.is_amount}
              onChange={event =>
                setLongTermIncentive(prev => ({
                  ...prev,
                  is_amount: event.target.checked,
                }))
              }
            >
              <span className={"font-medium text-lg text-themeInputTitleColor"}>
                Amount
              </span>
            </Checkbox>
          </Col>
          <Col span={7}>
            <Checkbox
              checked={longTermIncentive?.is_all_grade}
              onChange={event =>
                setLongTermIncentive(prev => ({
                  ...prev,
                  is_all_grade: event.target.checked,
                }))
              }
            >
              <span className={"font-medium text-lg text-themeInputTitleColor"}>
                All Grades
              </span>
            </Checkbox>
          </Col>
          <Col span={7}>
            <Checkbox
              checked={longTermIncentive?.is_global}
              onChange={event =>
                setLongTermIncentive(prev => ({
                  ...prev,
                  is_global: event.target.checked,
                }))
              }
            >
              <span className={"font-medium text-lg text-themeInputTitleColor"}>
                Global
              </span>
            </Checkbox>
          </Col>
        </Row> */}
      </>
    </CustomModal>
  );
};

export default AddLongTermIncentive;
