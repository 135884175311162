import { useDispatch } from "react-redux";
import { Col, message, Row } from "antd";
import { useState, useRef } from "react";

import Button from "@components/Button";
import Table from "@components/Table";
import {
  ErrorServices,
  shortTermIPApi,
  useDeleteShortTermIncentiveMutation,
  useFetchShortTermIncentivesByPlanQuery,
  useFetchShortTermIncentivesQuery,
  useUploadShortTermIncentivesMutation,
} from "@services";
import { getColumns } from "./config";
import AddShortTermIncentive from "./AddIncentive";
import { IShortTermIncentive } from "@types";
import ShortTermIncentiveVersions from "./Versions";
import Upload from "@components/Upload";
import { useTypedSelector } from "@hooks";
import { useParams } from "react-router-dom";
import { checkPermission } from "@/utils";
import { permissions } from "@/router";
import SearchBox from "@/components/searchInput";
import { MdOutlineFileDownload, MdOutlineFileUpload } from "react-icons/md";
import CustomButton from "@/components/customButton";

const ShortTermIncentives = () => {
  const { company } = useTypedSelector(state => state.auth?.user!);
  const { plan_id } = useParams<{ plan_id?: string }>();
  const { id: company_id } = company!;
  const dispatch = useDispatch();
  const shortTermIncentive_id = useRef<any>(null);
  const [selectedShortTermIncentive, setSelectedShortTermIncentive] =
    useState<IShortTermIncentive | null>(null);
  const [isVisible, setIsVisible] = useState(false);
  const [searchInput, setSearchInput] = useState("");

  const [isVersionsModal, setIsVersionsModal] = useState(false);
  const [page, setPage] = useState(1);
  const [isUploadModal, setIsUploadModal] = useState(false);
  const [uploadFile, setUploadFile] = useState({
    file: null,
    active: false,
  });
  const [uploadShortTermIncentives, { isLoading: isUploading }] =
    useUploadShortTermIncentivesMutation();
  const [deleteShortTermIncentive, { isLoading: isDeleting }] =
    useDeleteShortTermIncentiveMutation();
  const { data: incentivesData, isLoading } = useFetchShortTermIncentivesQuery(
    {
      company_id,
      page,
    },
    { skip: !!plan_id === true }
  );
  const { data: incentives, pagination: incentivesPagination } =
    incentivesData || {};
  const { data: incentivesByPlanData, isLoading: isFetchingPlanIncentives } =
    useFetchShortTermIncentivesByPlanQuery(
      {
        company_id,
        plan_id,
        page,
      },
      { skip: !!plan_id === false }
    );
  const { data: incentivesByPlan, pagination: incentivesByPlanPagination } =
    incentivesByPlanData || {};

  const data = plan_id ? incentivesByPlan : incentives;
  const pagination = plan_id
    ? incentivesByPlanPagination
    : incentivesPagination;



  const editShortTermIncentive = (incentive: IShortTermIncentive) => {
    setSelectedShortTermIncentive(incentive);
    setIsVisible(true);
  };

  const removeShortTermIncentive = async (id: number) => {
    try {
      shortTermIncentive_id.current = id;
      await deleteShortTermIncentive({ company_id, id });
      message.success("Short term incentive deleted successfully!");
    } catch (error) {
      ErrorServices(error);
      console.log(error);
    }
  };

  const handleUpload = async () => {
    const { file, active }: { file: any; active: boolean } = uploadFile;
    setIsUploadModal(false);
    try {
      const formData = new FormData();
      formData.append("attachment", file!, file?.name!);
      const res = await uploadShortTermIncentives({
        company_id,
        active,
        body: formData,
      }).unwrap();
      message.success(res?.message);
    } catch (error) {
      message.error(error?.message);
      console.log(error);
    }
  };

  const download = (id?: number) => {
    dispatch(
      shortTermIPApi.endpoints.downloadShortTermIncentives.initiate(
        {
          company_id,
          version_id: id || null,
        },
        {
          subscribe: false,
          forceRefetch: true,
        }
      )
    );
  };

  return (
    <>
      {isUploadModal && (
        <Upload
          title="Upload Short Term Incentives"
          onSubmit={handleUpload}
          isVisible={isUploadModal}
          setIsVisible={setIsUploadModal}
          file={uploadFile}
          setFile={setUploadFile}
        />
      )}
      {isVisible && (
        <AddShortTermIncentive
          isVisible={isVisible}
          setIsVisible={setIsVisible}
          selectedShortTermIncentive={selectedShortTermIncentive}
          setSelectedShortTermIncentive={setSelectedShortTermIncentive}
        />
      )}
      {isVersionsModal && (
        <ShortTermIncentiveVersions
          isVisible={isVersionsModal}
          setIsVisible={setIsVersionsModal}
          download={download}
        />
      )}

      <div className="bg-themeCore ">
        <div className="jobCreationBar bg-white px-4 py-6 rounded-lg flex justify-between items-center ">
          <div className="title text-themeBlue  font-medium text-3xl">
            Short Term Incentives
          </div>

          <div className="flex flex-row justify-between">
            {!plan_id ? (
              <>
                {checkPermission(permissions.CREATE_COMPANY_STI) ||
                checkPermission(permissions.UPDATE_COMPANY_STI) ? (
                  <CustomButton
                    onClick={() => setIsUploadModal(true)}
                    isLoading={isUploading}
                    btnName="Upload"
                    customClasses="shadow-md font-medium border py-1 px-6 !border-themeBorder"
                    iconPosition="left"
                  >
                    <MdOutlineFileUpload
                      size={20}
                      className="text-white font-bold"
                    />
                  </CustomButton>
                ) : null}

                <CustomButton
                  onClick={() => download()}
                  btnName="Download"
                  customClasses="shadow-md font-medium border py-1 px-6 !border-themeBorder ml-4"
                  iconPosition="right"
                >
                  <MdOutlineFileDownload
                    size={20}
                    className="text-white font-bold"
                  />
                </CustomButton>
              </>
            ) : null}

            <CustomButton
              onClick={() => setIsVersionsModal(true)}
              btnName="Versions"
              customClasses="shadow-md font-medium border py-1 px-6 !border-themeBorder ml-4"
              iconPosition="left"
            ></CustomButton>

            {checkPermission(permissions.CREATE_COMPANY_STI) && (
              <CustomButton
                onClick={() => setIsVisible(true)}
                btnName="Create Short Term Incentive"
                customClasses="shadow-md font-medium border py-1 px-6 !border-themeBorder ml-4"
                iconPosition="left"
              ></CustomButton>
            )}
          </div>
        </div>
      </div>

      <div className="jobCreationListing mt-10 bg-white px-14 py-6 rounded-lg">
        <SearchBox
          placeholder="Type to search..."
          value={searchInput}
          onChange={value => setSearchInput(value)}
        />
        <Row>
          <Table
            scroll={1500}
            data={data}
            columns={getColumns({
              isDeleting,
              shortTermIncentive_id,
              removeShortTermIncentive,
              editShortTermIncentive,
            })}
            isLoading={isLoading || isFetchingPlanIncentives}
            pagination={true}
            count={pagination?.count}
            onChangePage={setPage}
            page={page}
          />
        </Row>
      </div>
    </>
  );
};

export default ShortTermIncentives;
