import Cookies from "universal-cookie";
import { cookiesDomain } from "@/constants";

const cookies = new Cookies();

export const setUserDataInSharedStorage = (data: any) => {
  cookies.set("user_data", data, { path: "/", domain: cookiesDomain });
};

export const getTokenFromSharedStorage = () => {
  const data = cookies.get("user_data");
  return data || undefined;
};

export const removeUserDateFromSharedStorage = () => {
  cookies.remove("user_data");
};

export const getSelectedCompanyFromStorage = (): number => {
  const selected_company = cookies.get("selected_company");
  return selected_company?.id || 0;
};

export const removeSelectedCompanyInStorage = () => {
  cookies.remove("selected_company");
};

export const getSelectedApplication = () => {
  return cookies.get("selected_application");
};
