import { AppstoreOutlined, EditOutlined, HistoryOutlined } from "@ant-design/icons";
import React from "react";

// Define tabs with associated labels and icons
const tabs = [
  { key: "1", icon: <AppstoreOutlined />, label: "Active Offers" },
  { key: "2", icon: <HistoryOutlined />, label: "Past Offers" },
  { key: "3", icon: <EditOutlined />, label: "Draft Offers" }
];

const CustomTabs = ({ children, currentTab, onTabChange }) => {
  return (
    <div>
      <div className="flex border-b border-gray-300 ">
        {tabs.map((tab, index) => (
          <div
            key={tab.key}
            onClick={() => onTabChange(tab.key)}
            className={`outline-none  cursor-pointer py-3 px-4 text-center text-sm flex items-center justify-center ${
              tab.key === currentTab
                ? "bg-white drop-shadow-lg shadow-md font-semibold text-themeBlue"
                : "bg-gray-100 "
            } ${index === tabs.length - 1 ? "" : ""}`}
          >
            <span className="flex items-center space-x-2">
              {tab.icon}
              <span>{tab.label}</span>
            </span>
          </div>
        ))}
      </div>
      <div className="mt-4 ">
        {React.Children.map(children, child =>
          child.props.tabKey === currentTab ? child : null
        )}
      </div>
    </div>
  );
};

export default CustomTabs;
