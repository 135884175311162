import { LoadingOutlined } from "@ant-design/icons";
import { Col, Input, Row, Select } from "antd";
import { useEffect, FC, useState } from "react";

import { generateArrayOfYears, showSuccessPopup } from "@utils";
import { ICountry, IJobGrade, ISalaryRange, IModal } from "@types";
import {
  ErrorServices,
  useCreateSalaryRangeMutation,
  useFetchAllCompanyJobGradesQuery,
  useFetchCompanyCountriesQuery,
  useUpdateSalaryRangeMutation,
} from "@/services";
import { useTypedSelector } from "@hooks";
import CustomModal from "@/components/customModel";
import "./index.css";
const { Option } = Select;

interface IAddSalaryRange extends IModal {
  selectedSalaryRange: ISalaryRange | null;
  setSelectedSalaryRange: React.Dispatch<
    React.SetStateAction<ISalaryRange | null>
  >;
}

const INITIAL_SALARY_RANGE = {
  grade: "",
  year: null,
  tier: null,
  country_id: null,
  city: "",
  range_type: "",
  salary_min: null,
  salary_mid: null,
  salary_max: null,
};

const AddSalaryRange: FC<IAddSalaryRange> = ({
  isVisible,
  setIsVisible,
  selectedSalaryRange,
  setSelectedSalaryRange,
}) => {
  const { company } = useTypedSelector(state => state.auth?.user!);
  const { id: company_id } = company!;
  const [salaryRange, setSalaryRange] =
    useState<ISalaryRange>(INITIAL_SALARY_RANGE);
  const [createSalaryRange, { isLoading: isCreating }] =
    useCreateSalaryRangeMutation();
  const [updateSalaryRange, { isLoading: isUpdating }] =
    useUpdateSalaryRangeMutation();
  const [gradeCountries, setGradeCountries] = useState<ICountry[]>([]);
  const { data: jobGradesData, isLoading: isFetchingGrades } =
    useFetchAllCompanyJobGradesQuery({ company_id });
  const { data: jobGrades } = jobGradesData || {};
  const { data: countriesData } = useFetchCompanyCountriesQuery({
    company_id,
  });
  const { data: countries } = countriesData || {};

  useEffect(() => {
    if (selectedSalaryRange) {
      const clonedSalaryRange = { ...selectedSalaryRange };
      delete clonedSalaryRange.country;
      delete clonedSalaryRange.id;
      setSalaryRange({
        ...clonedSalaryRange,
        country_id: selectedSalaryRange.country?.id,
      });
      setGradeCountries([selectedSalaryRange?.country!]);
    }
    return () => {
      setSelectedSalaryRange(null);
      setSalaryRange(INITIAL_SALARY_RANGE);
    };
  }, [selectedSalaryRange, setSelectedSalaryRange]);

  useEffect(() => {
    if (selectedSalaryRange) {
      const grade = jobGrades?.find(
        (grade: IJobGrade) => selectedSalaryRange?.grade === grade?.grade
      );
      setGradeCountries(
        !grade?.countries?.length ? countries : grade?.countries
      );
    }
  }, [jobGrades, selectedSalaryRange, countries]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSalaryRange(prev => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSelectChange = (label: string, value: number) => {
    setSalaryRange(prev => ({
      ...prev,
      [label]: value,
    }));
  };

  const handleSubmit = async () => {
    try {
      if (selectedSalaryRange) {
        await editSalaryRange();
      } else {
        await addSalaryRange();
      }
      setIsVisible(false);
      showSuccessPopup({
        title: selectedSalaryRange
          ? "Salary Range Updated"
          : "New Salary Range Created",
        desc: `You have successfully ${
          selectedSalaryRange ? "updated the" : "created new"
        } salary range.`,
        role: "client",
      });
    } catch (error) {
      ErrorServices(error);
      console.log(error);
    }
  };

  const addSalaryRange = async () =>
    await createSalaryRange({
      company_id,
      body: { ...salaryRange },
    }).unwrap();

  const editSalaryRange = async () =>
    await updateSalaryRange({
      company_id,
      id: selectedSalaryRange?.id,
      body: { ...salaryRange },
    }).unwrap();

  return (
    <CustomModal
      width="w-8/12"
      isVisible={isVisible}
      title={`${selectedSalaryRange ? "Update" : "Create"} Salary Range`}
      onClose={() => setIsVisible(false)}
      footer={
        <>
          <button
            className="bg-themeGrey text-themeBlack font-medium text-lg py-2 px-14 rounded-md"
            onClick={() => setIsVisible(false)}
          >
            Cancel
          </button>
          <button
            className={`bg-themeBlue text-white font-medium text-lg py-2 px-14 rounded-md `}
            disabled={
              !salaryRange.grade ||
              !salaryRange.country_id ||
              !salaryRange.city ||
              !salaryRange.range_type ||
              !salaryRange.salary_min ||
              !salaryRange.salary_mid ||
              !salaryRange.salary_max
            }
            onClick={handleSubmit}
          >
            {isCreating || isUpdating ? (
              <LoadingOutlined className="spinner" />
            ) : selectedSalaryRange ? (
              "Update"
            ) : (
              "Create"
            )}
          </button>
        </>
      }
    >
      <>
        <Row justify="space-between">
          <Col span={11}>
            <label className="font-medium text-themeInputTitleColor text-lg leading-10 mt-4 ">
              Grade
            </label>
            <Select
              className="custom-select"
              loading={isFetchingGrades}
              size="large"
              showArrow
              placeholder="Select grade from here..."
              showSearch={false}
              value={salaryRange?.grade || undefined}
              onChange={(serializedSalaryRange: any) => {
                const salaryRange = JSON.parse(
                  serializedSalaryRange
                ) as IJobGrade;
                const { grade } = salaryRange;
                setSalaryRange(prev => ({ ...prev, grade, country_id: null }));

                setGradeCountries(
                  salaryRange?.countries?.length
                    ? salaryRange?.countries
                    : countries
                );
              }}
            >
              {jobGrades?.map((jobGrade: IJobGrade) => (
                <Option key={jobGrade.id} value={JSON.stringify(jobGrade)}>
                  {jobGrade?.grade}
                </Option>
              ))}
            </Select>
          </Col>
          <Col span={11}>
            <label className="font-medium text-themeInputTitleColor text-lg leading-10 mt-4 ">
              Country
            </label>
            <Select
              className="custom-select"
              disabled={!salaryRange?.grade}
              size="large"
              showArrow
              placeholder="Select country from here..."
              showSearch={false}
              value={salaryRange?.country_id || undefined}
              onChange={country_id =>
                setSalaryRange((prev: any) => ({
                  ...prev,
                  country_id,
                }))
              }
            >
              {gradeCountries?.map((country: ICountry) => (
                <Option key={country?.id} value={country?.id}>
                  {country?.name}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>
        <Row justify="space-between">
          <Col span={11}>
            <label className="font-medium text-themeInputTitleColor text-lg leading-10 mt-4 ">
              City
            </label>
            <Input
              className={`w-full shadow-sm my-2 font-semibold text-sm outline-none border border-themeInputBorder bg-white px-3 py-3 rounded-md`}
              onChange={handleChange}
              size="large"
              name="city"
              value={salaryRange?.city}
              placeholder="Enter city here..."
            />
          </Col>
          <Col span={11}>
            <label className="font-medium text-themeInputTitleColor text-lg leading-10 mt-4 ">
              Tier
            </label>
            <Input
              className={`w-full shadow-sm my-2 font-semibold text-sm outline-none border border-themeInputBorder bg-white px-3 py-3 rounded-md`}
              size="large"
              type="number"
              name="tier"
              onChange={handleChange}
              value={salaryRange?.tier || ""}
              placeholder="Enter tier here..."
            />
          </Col>
        </Row>
        <Row justify="space-between">
          <Col span={11}>
            <label className="font-medium text-themeInputTitleColor text-lg leading-10 mt-4 ">
              Range Type
            </label>
            <Input
              className={`w-full shadow-sm my-2 font-semibold text-sm outline-none border border-themeInputBorder bg-white px-3 py-3 rounded-md`}
              size="large"
              name="range_type"
              onChange={handleChange}
              value={salaryRange?.range_type}
              placeholder="Enter range type here..."
            />
          </Col>
          <Col span={11}>
            <label className="font-medium text-themeInputTitleColor text-lg leading-10 mt-4 ">
              Year
            </label>
            <Select
              className="custom-select"
              size="large"
              showArrow
              placeholder="Select year from here..."
              showSearch={false}
              value={salaryRange?.year || undefined}
              onChange={val => handleSelectChange("year", val)}
            >
              {generateArrayOfYears(50)?.map(year => (
                <Option key={year} value={year}>
                  {year}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>
        <Row justify="space-between">
          <Col span={8}>
            <label className="font-medium text-themeInputTitleColor text-lg leading-10 mt-4 ">
              Min
            </label>
            <Input
              className={`w-full shadow-sm my-2 font-semibold text-sm outline-none border border-themeInputBorder bg-white px-3 py-3 rounded-md`}
              size="large"
              type="number"
              name="salary_min"
              onChange={handleChange}
              placeholder="Enter min here..."
              value={salaryRange?.salary_min || ""}
            />
          </Col>
          <Col span={7}>
            <label className="font-medium text-themeInputTitleColor text-lg leading-10 mt-4 ">
              Mid
            </label>
            <Input
              className={`w-full shadow-sm my-2 font-semibold text-sm outline-none border border-themeInputBorder bg-white px-3 py-3 rounded-md`}
              size="large"
              type="number"
              name="salary_mid"
              onChange={handleChange}
              placeholder="Enter mid here..."
              value={salaryRange?.salary_mid || ""}
            />
          </Col>
          <Col span={8}>
            <label className="font-medium text-themeInputTitleColor text-lg leading-10 mt-4 ">
              Max
            </label>
            <Input
              className={`w-full shadow-sm my-2 font-semibold text-sm outline-none border border-themeInputBorder bg-white px-3 py-3 rounded-md`}
              size="large"
              type="number"
              name="salary_max"
              onChange={handleChange}
              placeholder="Enter max here..."
              value={salaryRange?.salary_max || ""}
            />
          </Col>
        </Row>
      </>
    </CustomModal>
  );
};

export default AddSalaryRange;
