import { OfferModellerAddedField } from "@/types";

export const calcluateStiTotal = (
  data: any,
  black_list?: string[]
): { current_total: number; proposed_total: number } => {
  let current_total = 0;
  let proposed_total = 0;

  for (let key in data) {
    if (data[key] && !black_list?.includes(key)) {
      if (Array.isArray(data[key])) {
        // eslint-disable-next-line no-loop-func
        data[key].forEach((item: OfferModellerAddedField) => {
          if (item?.is_current && item?.value) {
            current_total += item.value;
          } else if (!item?.is_current && item?.value) {
            proposed_total += item.value;
          }
        });
      } else {
        if (key?.includes("current") && !key?.includes("_total")) {
          current_total += data[key];
        } else if (key?.includes("proposed") && !key?.includes("_total")) {
            proposed_total += data[key];
        }
      }
    }
  }

  return { current_total, proposed_total };
};
