import { LoadingOutlined } from "@ant-design/icons";
import { Button, Col, Input, Row, Select } from "antd";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { FC, useState, useEffect } from "react";

import { ICashAllowance, ICountry, IJobGrade, IModal } from "@types";
import Modal from "@components/Modal";
import {
  ErrorServices,
  useCreateCashAllowanceMutation,
  useFetchAllCompanyJobGradesQuery,
  useFetchCompanyCountriesQuery,
  useUpdateCashAllowanceMutation,
} from "@services";
import { showSuccessPopup } from "@utils";
import { useTypedSelector } from "@hooks";
import CustomModal from "@/components/customModel";

const { Option } = Select;

interface IAddCashAllowance extends IModal {
  selectedCashAllowance: ICashAllowance | null;
  setSelectedCashAllowance: React.Dispatch<
    React.SetStateAction<ICashAllowance | null>
  >;
}

const INITIAL_CASH_ALLOWANCE: ICashAllowance = {
  allowance_name: null,
  grade: "",
  country_id: null,
  city: null,
  is_all_grade: false,
  is_percentage: false,
  is_basic_pay: false,
  value: null,
};

const AddCashAllowance: FC<IAddCashAllowance> = ({
  isVisible,
  setIsVisible,
  selectedCashAllowance,
  setSelectedCashAllowance,
}) => {
  const { company } = useTypedSelector(state => state.auth?.user!);
  const { id: company_id } = company!;
  const [cashAllowance, setCashAllowance] = useState(INITIAL_CASH_ALLOWANCE);
  const [createCashAllowance, { isLoading: isCreating }] =
    useCreateCashAllowanceMutation();
  const [updateCashAllowance, { isLoading: isUpdating }] =
    useUpdateCashAllowanceMutation();
  const [gradeCountries, setGradeCountries] = useState<ICountry[]>([]);
  const { data: countriesData, isLoading: isFetchingCountries } =
    useFetchCompanyCountriesQuery({
      company_id,
    });
  const { data: countries } = countriesData || {};
  const { data: jobGradesData, isLoading: isFetchingGrades } =
    useFetchAllCompanyJobGradesQuery({ company_id });
  const { data: jobGrades } = jobGradesData || {};

  useEffect(() => {
    if (selectedCashAllowance) {
      const { country } = selectedCashAllowance;
      const cashAllowance = {
        ...selectedCashAllowance,
        country_id: country?.id || null,
      };
      delete cashAllowance.id;
      delete cashAllowance.country;
      if (country) {
        setGradeCountries([country]);
      }
      setCashAllowance(cashAllowance);
    }
    return () => {
      setSelectedCashAllowance(null);
      setCashAllowance(INITIAL_CASH_ALLOWANCE);
    };
  }, [selectedCashAllowance, setSelectedCashAllowance]);

  useEffect(() => {
    if (selectedCashAllowance) {
      const grade = jobGrades?.find(
        (grade: IJobGrade) => selectedCashAllowance?.grade === grade?.grade
      );
      setGradeCountries(
        !grade?.countries?.length ? countries : grade?.countries
      );
    }
  }, [jobGrades, selectedCashAllowance, countries]);

  const handleChange = (event: any) => {
    const { name, value, checked } = event.target;
    setCashAllowance((prev: ICashAllowance) => ({
      ...prev,
      [name]: name?.includes("is_") ? checked : value,
    }));
    if (name?.includes("is_all_grade") && checked) {
      setGradeCountries(countries);
    }
  };

  const handleSubmit = async () => {
    const { city, value } = cashAllowance;
    const body = {
      ...cashAllowance,
      city: city || null,
      value: +value! || null,
      grade: cashAllowance?.is_all_grade ? null : cashAllowance?.grade,
    };
    body?.id && delete body?.id;
    body?.country && delete body?.country;
    try {
      if (selectedCashAllowance) {
        await editCashAllowance(body);
      } else {
        await addCashAllowance(body);
      }
      setIsVisible(false);
      showSuccessPopup({
        title: selectedCashAllowance
          ? "Cash Allowance Updated"
          : "New Cash Allowance Created",
        desc: `You have successfully ${
          selectedCashAllowance ? "updated the" : "created new"
        } cash allowance.`,
        role: "client",
      });
    } catch (error) {
      ErrorServices(error);
      console.log(error);
    }
  };

  const addCashAllowance = async (body: ICashAllowance) =>
    await createCashAllowance({
      company_id,
      body,
    }).unwrap();

  const editCashAllowance = async (body: ICashAllowance) =>
    updateCashAllowance({
      company_id,
      id: selectedCashAllowance?.id,
      body,
    }).unwrap();

  return (
    <CustomModal
      width="w-7/12"
      footer={
        <>
          <button
            className="bg-themeGrey text-themeBlack font-medium text-lg py-2 px-14 rounded-md"
            onClick={() => setIsVisible(false)}
          >
            Cancel
          </button>
          <button
            className={`bg-themeBlue text-white font-medium text-lg py-2 px-14 rounded-md `}
            disabled={!cashAllowance.country_id || !cashAllowance.value}
            onClick={handleSubmit}
          >
            {isCreating || isUpdating ? (
              <LoadingOutlined className="spinner" />
            ) : selectedCashAllowance ? (
              "Update"
            ) : (
              "Create"
            )}
          </button>
        </>
      }
      title={`${selectedCashAllowance ? "Update" : "Create"} Cash Allowance`}
      isVisible={isVisible}
    >
      <>
        <Row justify="space-between" className="modal__row">
          <Col span={24}>
            <label className="font-medium text-themeInputTitleColor text-lg leading-10 mt-4 ">
              Name
            </label>
            <Input
              name="allowance_name"
              value={cashAllowance?.allowance_name || ""}
              onChange={handleChange}
              size="large"
              placeholder="Enter allowance name here..."
            />
          </Col>
        </Row>
        <Row justify="space-between" className="modal__row">
          <Col span={11}>
            <label className="font-medium text-themeInputTitleColor text-lg leading-10 mt-4 ">
              Grade
            </label>
            <Select
              className="custom-select"
              disabled={cashAllowance?.is_all_grade}
              loading={isFetchingGrades}
              size="large"
              showArrow
              placeholder="Select grade from here..."
              showSearch={false}
              value={cashAllowance?.grade || undefined}
              onChange={grade => {
                const jobGrade = JSON.parse(grade) as IJobGrade;
                setCashAllowance((prev: ICashAllowance) => ({
                  ...prev,
                  grade: jobGrade?.grade,
                  country_id: null,
                }));

                setGradeCountries(
                  jobGrade?.countries?.length ? jobGrade?.countries : countries
                );
              }}
            >
              {jobGrades?.map((jobGrade: IJobGrade) => (
                <Option key={jobGrade.id} value={JSON.stringify(jobGrade)}>
                  {jobGrade?.grade}
                </Option>
              ))}
            </Select>
          </Col>
          <Col span={11}>
            <label className="font-medium text-themeInputTitleColor text-lg leading-10 mt-4 ">
              Country
            </label>
            <Select
              size="large"
              className="custom-select"
              showArrow
              placeholder="Select country from here..."
              showSearch={false}
              value={
                isFetchingCountries && selectedCashAllowance
                  ? undefined
                  : cashAllowance?.country_id || undefined
              }
              onChange={country_id =>
                setCashAllowance((prev: ICashAllowance) => ({
                  ...prev,
                  country_id,
                }))
              }
            >
              {gradeCountries?.map((country: ICountry) => (
                <Option key={country?.id} value={country?.id}>
                  {country?.name}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>
        <Row justify="space-between" className="modal__row">
          <Col span={11}>
            <label className="font-medium text-themeInputTitleColor text-lg leading-10 mt-4 ">
              City
            </label>
            <Input
              name="city"
              value={cashAllowance?.city || ""}
              onChange={handleChange}
              size="large"
              placeholder="Enter city here..."
            />
          </Col>
          <Col span={11}>
            <label className="font-medium text-themeInputTitleColor text-lg leading-10 mt-4 ">
              Value
            </label>
            <Input
              name="value"
              value={cashAllowance?.value || ""}
              onChange={handleChange}
              type="number"
              size="large"
              placeholder="Enter value here..."
            />
          </Col>
        </Row>
        <Row className="modal__row">
          <Checkbox
            checked={cashAllowance?.is_all_grade}
            name="is_all_grade"
            onChange={handleChange}
          >
            <span className={"font-medium text-lg text-themeInputTitleColor"}>
              All Grade
            </span>
          </Checkbox>
          <Checkbox
            checked={cashAllowance?.is_percentage}
            name="is_percentage"
            onChange={handleChange}
          >
            <span className={"font-medium text-lg text-themeInputTitleColor"}>
              Percentage
            </span>
          </Checkbox>
          <Checkbox
            checked={cashAllowance?.is_basic_pay}
            name="is_basic_pay"
            onChange={handleChange}
          >
            <span className={"font-medium text-lg text-themeInputTitleColor"}>
              Basic Pay
            </span>
          </Checkbox>
        </Row>
      </>
    </CustomModal>
  );
};

export default AddCashAllowance;
