import { useRef, useState } from "react";
import { Col, message, Row } from "antd";
import { useDispatch } from "react-redux";

import Table from "@components/Table/gradeTable";
import {
  ErrorServices,
  gradeSetupApi,
  useDeleteJobGradeMutation,
  useFetchCompanyJobGradesQuery,
  useUploadJobGradesMutation,
} from "@services";
import { useTypedSelector } from "@hooks";
import { getColumns } from "./config";
import AddGrade from "./AddGrade";
import GradeSetupVersions from "./Versions";
import { IJobGrade } from "@types";
import Upload from "@components/Upload";
import { checkPermission } from "@/utils";
import { permissions } from "@/router";
import CustomButton from "@/components/customButton";
import { MdOutlineFileDownload, MdOutlineFileUpload } from "react-icons/md";
import SearchBox from "@/components/searchInput";

const GradeSetup = () => {
  const { company } = useTypedSelector(state => state.auth?.user!);
  const { id: company_id } = company!;
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  let jobGrade_id = useRef<any>(null);
  const { data: jobGradesData, isLoading } = useFetchCompanyJobGradesQuery({
    company_id,
    page,
  });
  const { data, pagination } = jobGradesData || {};
  const [deleteJobGrade, { isLoading: isDeleting }] =
    useDeleteJobGradeMutation();
  const [isVisible, setIsVisible] = useState(false);
  const [isVersionsModal, setIsVersionsModal] = useState(false);
  const [searchInput, setSearchInput] = useState("");

  const [isUploadModal, setIsUploadModal] = useState(false);
  const [selectedJobGrade, setSelectedJobGrade] = useState<null | IJobGrade>(
    null
  );
  const [uploadFile, setUploadFile] = useState({
    file: null,
    active: false,
  });

  const [uploadJobGrades, { isLoading: isUploading }] =
    useUploadJobGradesMutation();

  const removeJobGrade = async (id: number) => {
    try {
      jobGrade_id.current = id;
      await deleteJobGrade({ id, company_id });
      message.success("Job Grade deleted successfully!");
    } catch (error) {
      ErrorServices(error);
      console.log(error);
    }
  };

  const editJobGrade = (jobGrade: IJobGrade) => {
    setSelectedJobGrade(jobGrade);
    setIsVisible(true);
  };

  const handleUpload = async () => {
    const { file, active }: { file: any; active: boolean } = uploadFile;
    setIsUploadModal(false);
    try {
      const formData = new FormData();
      formData.append("attachment", file!, file?.name!);
      const res = await uploadJobGrades({
        company_id,
        active,
        body: formData,
      }).unwrap();
      message.success(res?.message);
    } catch (error) {
      message.error(error?.message);
      console.log(error);
    }
  };

  const download = (id?: number) => {
    dispatch(
      gradeSetupApi.endpoints.downloadJobGrades.initiate(
        {
          company_id,
          version_id: id || null,
        },
        {
          subscribe: false,
          forceRefetch: true,
        }
      )
    );
  };

  const columns = getColumns({
    deleteJobGrade: removeJobGrade,
    jobGrade_id,
    isDeleting,
    editJobGrade,
  });

  return (
    <>
      {isVisible && (
        <AddGrade
          selectedJobGrade={selectedJobGrade}
          setSelectedJobGrade={setSelectedJobGrade}
          isVisible={isVisible}
          setIsVisible={setIsVisible}
        />
      )}

      {isVersionsModal && (
        <GradeSetupVersions
          isVisible={isVersionsModal}
          setIsVisible={setIsVersionsModal}
          download={download}
        />
      )}
      {isUploadModal && (
        <Upload
          title="Upload Job Grades"
          onSubmit={handleUpload}
          isVisible={isUploadModal}
          setIsVisible={setIsUploadModal}
          file={uploadFile}
          setFile={setUploadFile}
        />
      )}

      <div className="bg-themeCore">
        <div className="jobCreationBar bg-white px-4 py-6 rounded-lg flex justify-between items-center ">
          <div className="title text-themeBlue  font-medium text-3xl">
            Job Creation
          </div>

          <div className="flex flex-row justify-between">
            {checkPermission(permissions.UPDATE_JOB_GRADE) ||
            checkPermission(permissions.CREATE_JOB_GRADE) ? (
              <CustomButton
                isLoading={isUploading}
                onClick={() => setIsUploadModal(true)}
                btnName="Upload"
                customClasses="shadow-md font-medium border py-1 px-6 !border-themeBorder"
                iconPosition="left"
              >
                <MdOutlineFileUpload
                  size={20}
                  className="text-white font-bold"
                />
              </CustomButton>
            ) : null}

            <CustomButton
              onClick={() => download()}
              btnName="Download"
              customClasses="shadow-md font-medium border py-1 px-6 !border-themeBorder ml-4"
              iconPosition="right"
            >
              <MdOutlineFileDownload
                size={20}
                className="text-white font-bold"
              />
            </CustomButton>

            <CustomButton
              onClick={() => setIsVersionsModal(true)}
              btnName="Versions"
              customClasses="shadow-md font-medium border py-1 px-6 !border-themeBorder ml-4"
              iconPosition="left"
            ></CustomButton>

            {checkPermission(permissions.CREATE_JOB_GRADE) && (
              <CustomButton
                onClick={() => setIsVisible(true)}
                btnName="Create Job Grade"
                customClasses="shadow-md font-medium border py-1 px-6 !border-themeBorder ml-4"
                iconPosition="left"
              ></CustomButton>
            )}
          </div>
        </div>
      </div>

      <div className="jobCreationListing mt-6 bg-white px-14 py-4 rounded-lg">
        <SearchBox
          placeholder="Type to search..."
          value={searchInput}
          onChange={value => setSearchInput(value)}
        />

        <Table
          data={data}
          isLoading={isLoading}
          columns={columns}
          pagination={true}
          count={pagination?.count}
          onChangePage={setPage}
          page={page}
        />
      </div>
    </>
  );
};

export default GradeSetup;
