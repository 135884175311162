import { offersApi } from "@services";
import { IOffer, IRegion, OfferModellerAddedField, Stage } from "@types";
import { createSlice, current, isAnyOf, isPending } from "@reduxjs/toolkit";
import { getTotal, calcluateFixedTotal,calcluateStiTotal,round } from "@/utils";

type NullOrString = null | string;
type NullOrNumber = null | number;

type NameAndID = null | {
  name: string;
  id: number;
};

interface IPositionDetails {
  grade: NullOrString;
  job_title: NullOrString;
  type: NullOrString;
  business_unit: NameAndID;
  region: Partial<IRegion> | null;
  country_id: NullOrNumber;
  job_function: NameAndID;
  sub_job_function_id: NullOrNumber;
  reporting_grade: NullOrString;
  position_owner: NullOrString;
  city: NullOrString;
  grade_type: NullOrString;
  reporting_title: NullOrString;
  cost_centre: NullOrString;
  is_critical: boolean;
  currency_id: NullOrNumber;
  position_comment: NullOrString;
}

interface ICandidateDetails {
  candidate_name: NullOrString;
  company_name: NullOrString;
  current_title: NullOrString;
  current_grade: NullOrString;
  business_unit: NullOrString;
  region: NullOrString;
  location: NullOrString;
  relevant_experience: NullOrNumber;
  total_experience: NullOrNumber;
  is_critical_skills: boolean;
  critical_skills: NullOrString;
  is_referral: boolean;
  referral_employees: NullOrString;
  country_id: NullOrNumber;
  currency_id: NullOrNumber;
  current_job_function: NameAndID | null;
  current_sub_job_function_id: NullOrNumber;
  sector: NameAndID | null;
  industry: NameAndID | null;
  sub_industry_id: NullOrNumber;
  // gender: NullOrString;
}

interface IOfferFixedCash {
  current_annual_base: NullOrNumber;
  proposed_annual_base: NullOrNumber;
  current_compa_ratio: NullOrNumber;
  proposed_compa_ratio: NullOrNumber;
  current_market_ratio: NullOrNumber;
  proposed_market_ratio: NullOrNumber;
  current_total: NullOrNumber;
  proposed_total: NullOrNumber;
  allowances: OfferModellerAddedField[];
}

interface IOfferSti {
  current_bonus_target: NullOrNumber;
  proposed_bonus_target: NullOrNumber;
  current_sale_incentive: NullOrNumber;
  proposed_base_pay_percentage: NullOrNumber;
  is_sale_incentive: boolean;
  current_total_bonus: NullOrNumber;
  proposed_total_bonus: NullOrNumber;
  bonuses: OfferModellerAddedField[];
}

interface IOfferLti {
  current_unvested_equity: NullOrNumber;
  proposed_new_hire_equity: NullOrNumber;
  current_annual_grant: NullOrNumber;
  proposed_annual_grant: NullOrNumber;
  current_total_lti: NullOrNumber;
  proposed_total_lti: NullOrNumber;
  other_ltis: OfferModellerAddedField[];
}

interface IOfferSignOnBonus {
  current_total_bonus: NullOrNumber;
  proposed_total_bonus: NullOrNumber;
  bonuses: OfferModellerAddedField[];
}

interface IOfferBenefits {
  current_total_bonus: NullOrNumber;
  proposed_total_bonus: NullOrNumber;
  bonuses: OfferModellerAddedField[];
}

interface IOfferModeller {
  offer_fixed_cash: IOfferFixedCash;
  offer_sti: IOfferSti;
  offer_lti: IOfferLti;
  offer_sign_on_bonus: IOfferSignOnBonus;
  offer_benefits: IOfferBenefits;
}

type OfferModellerKeys = keyof IOfferModeller;

interface IInitialState {
  currencyRate: NullOrNumber;
  currencyCode: NullOrNumber;
  offerCurrency: NullOrNumber;
  stiBonusCurrencyRate: NullOrNumber;
  currencyName: string;
  positionDetails: IPositionDetails;
  candidateDetails: ICandidateDetails;
  offerModeller: IOfferModeller;
  hidden_sti_bonuses: OfferModellerAddedField[];
  offer: Partial<IOffer> | null;
  stock_data: null;
}

function object_equals(x: any, y: any) {
  if (x === y) return true;
  // if both x and y are null or undefined and exactly the same

  if (!(x instanceof Object) || !(y instanceof Object)) return false;
  // if they are not strictly equal, they both need to be Objects

  if (x.constructor !== y.constructor) return false;
  // they must have the exact same prototype chain, the closest we can do is
  // test there constructor.

  for (var p in x) {
    if (!x.hasOwnProperty(p)) continue;
    // other properties were tested using x.constructor === y.constructor

    if (!y.hasOwnProperty(p)) return false;
    // allows to compare x[ p ] and y[ p ] when set to undefined

    if (x[p] === y[p]) continue;
    // if they have the same strict value or identity then they are equal

    if (typeof x[p] !== "object") return false;
    // Numbers, Strings, Functions, Booleans must be strictly equal

    if (!object_equals(x[p], y[p])) return false;
    // Objects and Arrays must be tested recursively
  }

  for (p in y) if (y.hasOwnProperty(p) && !x.hasOwnProperty(p)) return false;
  // allows x[ p ] to be set to undefined

  return true;
}

export const offerModellerInitialState = {
  offer_fixed_cash: {
    current_annual_base: null,
    proposed_annual_base: null,
    current_compa_ratio: null,
    proposed_compa_ratio: null,
    current_market_ratio: null,
    proposed_market_ratio: null,
    current_total: null,
    proposed_total: null,
    allowances: [],
  },
  offer_sti: {
    current_bonus_target: null,
    proposed_bonus_target: null,
    current_sale_incentive: null,
    proposed_base_pay_percentage: null,
    is_sale_incentive: false,
    current_total_bonus: null,
    proposed_total_bonus: null,
    bonuses: [],
  },
  offer_lti: {
    current_unvested_equity: null,
    proposed_new_hire_equity: null,
    current_annual_grant: null,
    proposed_annual_grant: null,
    current_total_lti: null,
    proposed_total_lti: null,
    other_ltis: [],
  },
  offer_sign_on_bonus: {
    current_total_bonus: null,
    proposed_total_bonus: null,
    bonuses: [],
  },
  offer_benefits: {
    current_total_bonus: null,
    proposed_total_bonus: null,
    bonuses: [],
  },
};

const offerModellerState = offerModellerInitialState;

const initialState: IInitialState = {
  currencyRate: null,
  currencyName: "",
  stiBonusCurrencyRate: null,
  offerCurrency: null,
  hidden_sti_bonuses: [],
  currencyCode: null,
  positionDetails: {
    grade: null,
    job_title: null,
    currency_id: null,
    type: null,
    region: null,
    country_id: null,
    job_function: null,
    sub_job_function_id: null,
    reporting_grade: null,
    position_owner: null,
    city: null,
    grade_type: null,
    reporting_title: null,
    cost_centre: null,
    is_critical: true,
    position_comment: null,
    business_unit: null,
  },
  candidateDetails: {
    candidate_name: null,
    company_name: null,
    current_title: null,
    current_grade: null,
    business_unit: null,
    region: null,
    location: null,
    relevant_experience: null,
    total_experience: null,
    is_critical_skills: false,
    critical_skills: null,
    is_referral: false,
    referral_employees: null,
    country_id: null,
    currency_id: null,
    current_job_function: null,
    current_sub_job_function_id: null,
    sector: null,
    industry: null,
    sub_industry_id: null,
    // gender: null,
  },
  offerModeller: offerModellerState,
  offer: null,
  stock_data: null,
};

const offersSlice = createSlice({
  name: "offers",
  initialState,
  reducers: {
    handlePositionDetails: (state, action) => {
      state.positionDetails = { ...state.positionDetails, ...action.payload };
    },
    handleCandidateDetails: (state, action) => {
      state.candidateDetails = { ...state.candidateDetails, ...action.payload };
    },
    handleStage: (state, action: { payload: Stage }) => {
      state.offer = { ...state?.offer, stage: action.payload };
    },
    handleCurrency: (state, action) => {
      state.currencyCode = action.payload.id;
      state.currencyName = action.payload.code;
    },
    removeCurrency: state => {
      state.currencyCode = null;
      state.currencyRate = null;
      state.currencyName = "";
      state.stiBonusCurrencyRate = null;
    },
    handleHiddenStiBonuses: (state, action) => {
      state.hidden_sti_bonuses = action.payload;
    },
    handleOfferModeller: (
      state,
      action: {
        payload: {
          key: OfferModellerKeys;
          data: Partial<
            | IOfferFixedCash
            | IOfferSti
            | IOfferLti
            | IOfferBenefits
            | IOfferSignOnBonus
          >;
        };
      }
    ) => {
      state.offerModeller = {
        ...state.offerModeller,
        [action.payload.key]: {
          ...state.offerModeller[action?.payload?.key],
          ...action.payload.data,
        },
      };
      if (action.payload.key === "offer_fixed_cash") {
        const { current_total, proposed_total } = calcluateFixedTotal(
          state.offerModeller.offer_fixed_cash,
          [
            "current_compa_ratio",
            "current_market_ratio",
            "proposed_compa_ratio",
            "proposed_market_ratio",
          ]
        );
        state.offerModeller.offer_fixed_cash.current_total = current_total;
        state.offerModeller.offer_fixed_cash.proposed_total = proposed_total;
      } else if (action.payload.key === "offer_sti") {
        const { current_total, proposed_total } = calcluateStiTotal(
          {
            ...state.offerModeller.offer_sti,
            current_bonus_target:
              state.offerModeller.offer_fixed_cash?.current_annual_base &&
              state.offerModeller.offer_sti?.current_bonus_target
                ? round(
                    (state.offerModeller.offer_fixed_cash?.current_annual_base *
                      state.offerModeller.offer_sti?.current_bonus_target) /
                      100
                  )
                : null,

            proposed_bonus_target:
              state.offerModeller.offer_fixed_cash?.proposed_annual_base &&
              state.offerModeller.offer_sti?.proposed_bonus_target
                ? round(
                    (state.offerModeller.offer_fixed_cash
                      ?.proposed_annual_base *
                      state.offerModeller.offer_sti?.proposed_bonus_target) /
                      100
                  )
                : null,
          },
          []
        );

        state.offerModeller.offer_sti.current_total_bonus = current_total;
        state.offerModeller.offer_sti.proposed_total_bonus = proposed_total;
      } else if (action.payload.key === "offer_lti") {
        const { current_total, proposed_total } = getTotal(
          state.offerModeller.offer_lti
        );
        state.offerModeller.offer_lti.current_total_lti = current_total;
        state.offerModeller.offer_lti.proposed_total_lti = proposed_total;
      } else if (action.payload.key === "offer_benefits") {
        const { current_total, proposed_total } = getTotal(
          state.offerModeller.offer_benefits
        );
        state.offerModeller.offer_benefits.current_total_bonus = current_total;
        state.offerModeller.offer_benefits.proposed_total_bonus =
          proposed_total;
      } else if (action.payload.key === "offer_sign_on_bonus") {
        const { current_total, proposed_total } = getTotal(
          state.offerModeller.offer_sign_on_bonus
        );
        state.offerModeller.offer_sign_on_bonus.current_total_bonus =
          current_total;
        state.offerModeller.offer_sign_on_bonus.proposed_total_bonus =
          proposed_total;
      }
    },
    resetOffers: () => initialState,

    resetOfferModeller: (state, action) => {

      console.log(initialState,'sagar')
      state.offerModeller = {
        ...offerModellerInitialState
      };
    },
  },
  extraReducers: builder => {
    builder
      .addMatcher(
        isAnyOf(
          offersApi.endpoints.createOfferDetails.matchFulfilled,
          offersApi.endpoints.createCandidateDetails.matchFulfilled,
          offersApi.endpoints.updateOfferDetails.matchFulfilled
        ),
        (state, { payload }) => {
          state.offer = payload?.data;
        }
      )
      .addMatcher(
        offersApi.endpoints.fetchOfferById.matchFulfilled,
        (state, { payload }) => {
          const {
            offer_position_detail,
            offer_candidate_detail,
            offer_lti,
            offer_fixed_cash,
            offer_sti,
            offer_benefits,
            offer_sign_on_bonus,
          } = payload?.data;

          state.offerModeller = {
            ...state.offerModeller,
            offer_lti: offer_lti || offerModellerState["offer_lti"],
            offer_fixed_cash:
              offer_fixed_cash || offerModellerState["offer_fixed_cash"],
            offer_sti: offer_sti || offerModellerState["offer_sti"],
            offer_sign_on_bonus:
              offer_sign_on_bonus || offerModellerState["offer_sign_on_bonus"],
            offer_benefits:
              offer_benefits || offerModellerState["offer_benefits"],
          };
          const positionDetails = { ...offer_position_detail };
          const candidateDetails = { ...offer_candidate_detail };

          delete positionDetails["sub_job_function"];
          delete positionDetails["country"];
          delete positionDetails["currency"];
          delete positionDetails["region"];
          delete candidateDetails["country"];
          delete candidateDetails["currency"];
          delete candidateDetails["current_sub_job_function"];
          delete candidateDetails["sub_industry"];

          state.offer = { ...payload?.data };
          state.positionDetails = {
            ...positionDetails,
            sub_job_function_id: offer_position_detail?.sub_job_function?.id,
            country_id: offer_position_detail?.country?.id,
            region: offer_position_detail?.region,
            currency_id: offer_position_detail?.currency?.id,
          };
          state.candidateDetails = {
            ...candidateDetails,
            country_id: offer_candidate_detail?.country?.id,
            currency_id: offer_candidate_detail?.currency?.id,
            current_sub_job_function_id:
              offer_candidate_detail?.current_sub_job_function?.id,
            sub_industry_id: offer_candidate_detail?.sub_industry?.id,
          };
        }
      )
      .addMatcher(
        offersApi.endpoints.fetchCurrencyRate.matchFulfilled,
        (state, action) => {
          state.currencyRate = action.payload?.data?.result
            ? parseFloat(
                Object.values(action.payload?.data?.result)[0]
              ).toFixed(5)
            : null;
        }
      )

      .addMatcher(
        offersApi.endpoints.fetchStiCurrencyRate.matchFulfilled,
        (state, action) => {
          state.stiBonusCurrencyRate = action.payload?.data?.result
            ? parseFloat(
                Object.values(action.payload?.data?.result)[0]
              ).toFixed(2)
            : null;
        }
      )

      .addMatcher(
        offersApi.endpoints.fetchOfferCurrencyRate.matchFulfilled,
        (state, action) => {
          state.offerCurrency = !action.payload?.data?.result
            ? null
            : (Object.values(action.payload?.data?.result)[0] as number | null);
        }
      )
      .addMatcher(
        offersApi.endpoints.fetchAutoPopulateData.matchFulfilled,
        (state, action) => {
          if (object_equals(offerModellerInitialState, state.offerModeller)) {
            const { fixed_cash, lti, sti, stock_data } =
              action.payload.data || {};
            const { offer_fixed_cash, offer_lti, offer_sti } =
              state.offerModeller;

            let currency = state.offerCurrency;

            const cash_allowances = fixed_cash?.map(
              ({ allowance_name, id, value, is_percentage, is_basic_pay }) => {
                let updatedValue = value;

                if (!is_percentage && currency) {
                  updatedValue = value / (currency || 1);
                  updatedValue = round(updatedValue);
                }

                return {
                  is_current: false,
                  value: updatedValue,
                  // value:updatedValue,
                  is_basic_pay,
                  is_percentage,
                  percentage: value,
                  name: allowance_name || "N/A",
                  id,
                };
              }
            );

            const fixedCashTotals = getTotal(
              {
                ...offer_fixed_cash,
                allowances: [
                  ...offer_fixed_cash.allowances,
                  ...cash_allowances,
                ],
              },
              [
                "current_compa_ratio",
                "current_market_ratio",
                "proposed_compa_ratio",
                "proposed_market_ratio",
              ]
            );

            const bonuses = sti?.map(
              ({ plan, id, value, is_percentage, is_basic_pay }) => {
                let updatedValue = value;

                if (!is_percentage && currency) {
                  updatedValue = value / (currency || 1);
                  updatedValue = round(updatedValue);
                }

                return {
                  is_current: false,
                  auto_populate: true,

                  value: updatedValue,

                  // value: is_percentage
                  //   ? is_basic_pay
                  // ? offer_fixed_cash?.proposed_annual_base
                  //   ? (offer_fixed_cash.proposed_annual_base * updatedValue) / 100
                  //   : updatedValue
                  //     : fixedCashTotals?.proposed_total
                  //     ? (fixedCashTotals.proposed_total * updatedValue) / 100
                  //     : updatedValue
                  //   : updatedValue,
                  // value: is_percentage
                  //   ? offer_sti?.proposed_bonus_target
                  //     ? (offer_sti?.proposed_bonus_target * value) / 100
                  //     : value
                  //   : value,
                  name: `${plan?.name} (${plan?.type?.name})` || "N/A",
                  percentage: value,
                  is_percentage: is_percentage,
                  is_basic_pay: is_basic_pay,
                  id,
                };
              }
            );

            const ltis = lti?.map(({ plan, id, equity_mid, is_amount }) => {
              return {
                is_current: false,
                // value: plan?.is_adhoc
                //   ? round(equity_mid / (plan?.stock_data?.stock_value || 1))
                //   : round(equity_mid * (plan?.stock_data?.stock_value || 1)),
                value: equity_mid,
                name: `${plan?.name} (${plan?.type?.name})` || "N/A",
                unit: is_amount,
                id,
              };
            });

            const stiTotals = getTotal(
              {
                ...offer_sti,
                bonuses: [...offer_sti.bonuses, ...bonuses],
                current_bonus_target:
                  fixedCashTotals?.current_total &&
                  offer_sti?.current_bonus_target
                    ? round(
                        (fixedCashTotals?.current_total *
                          offer_sti?.current_bonus_target) /
                          100
                      )
                    : null,

                proposed_bonus_target:
                  fixedCashTotals?.proposed_total &&
                  offer_sti?.proposed_bonus_target
                    ? round(
                        (fixedCashTotals?.proposed_total *
                          offer_sti?.proposed_bonus_target) /
                          100
                      )
                    : null,
              },
              []
            );

            // Step 3: Calculate totals for offer_lti
            const ltiTotals = getTotal({
              ...offer_lti,
              other_ltis: [...offer_lti.other_ltis, ...ltis],
            });

            state.stock_data = stock_data;
            // Step 4: Update state with calculated totals
            state.offerModeller = {
              ...state.offerModeller,
              offer_fixed_cash: {
                ...offer_fixed_cash,
                current_total: fixedCashTotals.current_total,
                proposed_total: fixedCashTotals.proposed_total,
                allowances: [
                  ...offer_fixed_cash.allowances,
                  ...cash_allowances,
                ],
              },
              offer_sti: {
                ...offer_sti,
                current_total_bonus: stiTotals.current_total,
                proposed_total_bonus: stiTotals.proposed_total,
                bonuses: [...offer_sti.bonuses, ...bonuses],
              },
              offer_lti: {
                ...offer_lti,
                current_total_lti: ltiTotals.current_total,
                proposed_total_lti: ltiTotals.proposed_total,
                other_ltis: [...offer_lti.other_ltis, ...ltis],
              },
            };
          }
        }
      );
    // .addMatcher(
    //   offersApi.endpoints.fetchAutoPopulateData.matchFulfilled,
    //   (state, action) => {
    //     if (object_equals(offerModellerInitialState, state.offerModeller)) {
    //       const { fixed_cash, lti, sti } = action.payload.data || {};
    //       const { offer_fixed_cash, offer_lti, offer_sti } =
    //         state.offerModeller;
    //       const cash_allowances = fixed_cash?.map(
    //         ({ allowance_name, id, value, is_percentage }: any) => ({
    //           is_current: false,
    // value: is_percentage
    //   ? offer_fixed_cash?.proposed_annual_base
    //     ? (offer_fixed_cash?.proposed_annual_base * value) / 100
    //     : value
    //   : value,
    //           is_percentage,
    //           percentage: value,
    //           name: allowance_name || "N/A",
    //           id,
    //         })
    //       );
    //       const bonuses = sti?.map(
    //         ({ plan, id, value, is_percentage }: any) => ({
    //           is_current: false,
    //           auto_populate: true,
    //           value: is_percentage
    //             ? offer_sti?.proposed_bonus_target
    //               ? (offer_sti?.proposed_bonus_target * value) / 100
    //               : null
    //             : value,
    //           name: `${plan?.name} (${plan?.type?.name})` || "N/A",
    //           id,
    //         })
    //       );

    //       const ltis = lti?.map(
    //         ({ plan, id, equity_mid, is_amount, is_percentage }: any) => ({
    //           is_current: false,
    //           value: equity_mid,
    //           name: `${plan?.name} (${plan?.type?.name})` || "N/A",
    //           unit: is_amount,
    //           id,
    //         })
    //       );
    //       state.offerModeller = {
    //         ...state.offerModeller,
    //         offer_fixed_cash: {
    //           ...offer_fixed_cash,
    //           allowances: [
    //             ...offer_fixed_cash.allowances,
    //             ...cash_allowances,
    //           ],
    //         },
    //         offer_sti: {
    //           ...offer_sti,
    //           bonuses: [...offer_sti.bonuses, ...bonuses],
    //         },
    //         offer_lti: {
    //           ...offer_lti,
    //           other_ltis: [...offer_lti.other_ltis, ...ltis],
    //         },
    //       };
    //     }
    //   }
    // );
  },
});

export const {
  handlePositionDetails,
  resetOffers,
  handleCandidateDetails,
  handleStage,
  handleOfferModeller,
  removeCurrency,
  handleCurrency,
  handleHiddenStiBonuses,
  resetOfferModeller,
} = offersSlice.actions;

export default offersSlice.reducer;
