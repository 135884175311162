import {
  DeleteOutlined,
  EditOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { TableColumnsType } from "antd";

import { IShortTermPlan } from "@types";
import { checkPermission } from "@/utils";
import { permissions } from "@/router";

export const getColumns = ({
  removeShortTermPlan,
  shortTermPlan_id,
  isDeleting,
  editShortTermPlan,
}: {
  removeShortTermPlan: (id: number) => Promise<void>;
  shortTermPlan_id: React.MutableRefObject<any>;
  isDeleting: boolean;
  editShortTermPlan: (selectedShortTermPlan: IShortTermPlan) => void;
}): TableColumnsType<any> => [
  {
    title: "type of plan",
    dataIndex: "type",
    key: "type",
    width: 250,
    render: (type: { id: number; name: string }) => type.name,
  },
  {
    title: "name",
    dataIndex: "name",
    key: "name",
    width: 250,
  },
  {
    title: "grades",
    key: "grades",
    width: !checkPermission([
      permissions.DELETE_COMPANY_STI,
      permissions.UPDATE_COMPANY_STI,
    ])
      ? 200
      : 400,
    render: (plan: IShortTermPlan) => (
      <div className="text-wrap">
        {plan?.is_all_grade ? "All" : plan?.grades.join(", ")}
      </div>
    ),
  },
  {
    title: "coverage",
    key: "coverage",
    width: !checkPermission([
      permissions.DELETE_COMPANY_STI,
      permissions.UPDATE_COMPANY_STI,
    ])
      ? 200
      : 400,
    render: (plan: IShortTermPlan) => (
      <div className="text-wrap">
        {plan?.is_global
          ? "Global"
          : plan?.countries?.map(country => country?.name).join(", ")}
      </div>
    ),
  },
  ...(!checkPermission([
    permissions.DELETE_COMPANY_STI,
    permissions.UPDATE_COMPANY_STI,
  ])
    ? []
    : ([
        {
          title: "Actions",
          key: "actions",
          width: 160,
          fixed: "right",
          render: (shortTermPlan: IShortTermPlan) => (
            <>
              {checkPermission(permissions.UPDATE_COMPANY_STI) && (
                <div
                  onClick={event => {
                    event.stopPropagation();
                    editShortTermPlan(shortTermPlan);
                  }}
                  className="table__action__btn table__action__btn--client"
                >
                  <EditOutlined style={{ color: "#1A8245" }} /> Edit
                </div>
              )}
              {checkPermission(permissions.DELETE_COMPANY_STI) && (
                <div
                  onClick={event => {
                    event.stopPropagation();
                    removeShortTermPlan(shortTermPlan?.id);
                  }}
                  className="table__action__btn table__action__btn--delete"
                >
                  {isDeleting &&
                  shortTermPlan?.id === shortTermPlan_id?.current ? (
                    <LoadingOutlined color="#B62424" className="spinner" />
                  ) : (
                    <>
                      <DeleteOutlined style={{ color: "#B62424" }} /> Delete
                    </>
                  )}
                </div>
              )}
            </>
          ),
        },
      ] as any)),
];
