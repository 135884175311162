import { FC, useState, useEffect } from "react";
import CustomModal from "@components/customModel"; // Adjust the import path as needed
import CustomFormInput from "@components/customInput";
// import CustomSelect from "@/components/customSelect";
import { Button, Col, Input, Row, Select } from "antd";

import {
  ErrorServices,
  useCreateJobGradeMutation,
  useFetchCompanyCountriesQuery,
  useUpdateJobGradeMutation,
} from "@services";
import { ICountry, IJobGrade, IModal } from "@types";
import { showSuccessPopup } from "@utils";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { useTypedSelector } from "@hooks";
import "./index.less";

const { Option } = Select;

interface IAddGrade extends IModal {
  selectedJobGrade: IJobGrade | null;
  setSelectedJobGrade: React.Dispatch<React.SetStateAction<IJobGrade | null>>;
}

const AddGrade: FC<IAddGrade> = ({
  isVisible,
  setIsVisible,
  selectedJobGrade,
  setSelectedJobGrade,
}) => {
  const { company } = useTypedSelector(state => state.auth?.user!);
  const { id: company_id } = company!;

  const [jobGrade, setJobGrade] = useState<IJobGrade>({
    grade: "",
    type: null,
    country_ids: [],
  });
  const [isGlobal, setIsGlobal] = useState(false);
  const { data: countries, isLoading: isFetchingCountries } =
    useFetchCompanyCountriesQuery({
      company_id,
    });
  const { data } = countries || {};
  const [createJobGrade, { isLoading: isCreating }] =
    useCreateJobGradeMutation();
  const [updateJobGrade, { isLoading: isUpdating }] =
    useUpdateJobGradeMutation();

  useEffect(() => {
    if (selectedJobGrade) {
      const { grade, type, countries } = selectedJobGrade;
      setJobGrade({
        grade,
        type,
        country_ids: countries?.map(country => country.id),
      });
      if (!countries?.length) {
        setIsGlobal(true);
      }
    }
    return () => {
      setSelectedJobGrade(null);
    };
  }, [selectedJobGrade, setSelectedJobGrade]);

  const handleSubmit = async () => {
    try {
      const job_grade = {
        ...(jobGrade as IJobGrade),
        country_ids: isGlobal ? [] : jobGrade?.country_ids,
      };
      if (selectedJobGrade) {
        await editJobGrade(job_grade);
      } else {
        await addJobGrade(job_grade);
      }
      setIsVisible(false);
      showSuccessPopup({
        title: selectedJobGrade ? "Job Grade Updated" : "New Job Grade Created",
        desc: `You have successfully ${
          selectedJobGrade ? "updated the" : "created new"
        } job grade.`,
        role: "client",
      });
    } catch (error) {
      ErrorServices(error);
      console.log(error);
    }
  };

  const addJobGrade = async (jobGrade: IJobGrade) =>
    await createJobGrade({
      company_id,
      body: { ...jobGrade },
    }).unwrap();

  const editJobGrade = async (jobGrade: IJobGrade) =>
    await updateJobGrade({
      company_id,
      id: selectedJobGrade?.id,
      body: { ...jobGrade },
    }).unwrap();

  return (
    <CustomModal
      width="w-6/12"
      isVisible={isVisible}
      title={`${selectedJobGrade ? "Update" : "Create"} Job Grade`}
      onClose={() => setIsVisible(false)}
      footer={
        <>
          <button
            className="bg-themeGrey text-themeBlack font-medium text-lg py-2 px-14 rounded-md"
            onClick={() => setIsVisible(false)}
          >
            Cancel
          </button>
          <button
            className={`bg-themeBlue text-white font-medium text-lg py-2 px-14 rounded-md ${
              isCreating || isUpdating ? "opacity-50 cursor-not-allowed" : ""
            }`}
            onClick={handleSubmit}
            disabled={isCreating || isUpdating || !jobGrade.grade}
          >
            {isCreating || isUpdating
              ? "Loading..."
              : selectedJobGrade
              ? "Update"
              : "Create"}
          </button>
        </>
      }
    >
      <>
        <Row justify="space-between">
          <Col span={11}>
            <label className="font-medium text-themeInputTitleColor text-lg leading-10 mt-4 ">
              Grade
            </label>
            <Input
              size="large"
              className={`w-full shadow-sm my-2 font-semibold text-sm outline-none border border-themeInputBorder bg-white px-3 py-3 rounded-md`}
              name="grade"
              value={jobGrade.grade}
              onChange={e =>
                setJobGrade(prev => ({ ...prev, grade: e.target.value }))
              }
              placeholder="Enter grade here..."
            />
          </Col>
          <Col span={11}>
            <label className="font-medium text-themeInputTitleColor text-lg leading-10 mt-4 ">
              Type
            </label>
            <Input
              size="large"
              className={`w-full shadow-sm my-2 font-semibold text-sm outline-none border border-themeInputBorder bg-white px-3 py-3 rounded-md`}
              name="type"
              value={jobGrade.type || ""}
              onChange={e =>
                setJobGrade(prev => ({ ...prev, type: e.target.value }))
              }
              placeholder="Enter type here..."
            />
          </Col>
        </Row>
        <Row className="modal__row">
          <Col span={24}>
            <label className="font-medium text-themeInputTitleColor text-lg leading-10 mt-4 ">
              Countries
            </label>
            <Select
              className="custom-select"

              disabled={isGlobal}
              size="large"
              showArrow
              placeholder="Select industry from here..."
              showSearch={false}
              loading={isFetchingCountries}
              value={jobGrade?.country_ids}
              mode="multiple"
              onChange={val =>
                setJobGrade(prev => ({ ...prev, country_ids: val }))
              }
            >
              {data?.map((country: ICountry) => (
                <Option key={country?.id} value={country?.id}>
                  {country?.name}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>
        <Row className="modal__row">
          <Col span={24}>
            <Checkbox
              checked={isGlobal}
              onChange={event => setIsGlobal(event.target.checked)}
            >
              <span
                className={"font-medium text-lg text-themeInputTitleColor"}
              >
                Global
              </span>
            </Checkbox>
          </Col>
        </Row>
      </>
    </CustomModal>
  );
};

export default AddGrade;
