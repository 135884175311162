import React, { useState } from "react";
import { FaChevronDown, FaChevronUp, FaEdit } from "react-icons/fa";
import { CheckOutlined } from "@ant-design/icons";
import CustomButton from "@components/customButton"; // Adjust the path as needed

const CollapsibleCard = ({
  children,
  onUpdateClick,
  onSaveClick,
  updateButtonLabel = "Update Details",
  saveButtonLabel = "Save Changes",
  header,
  hideUpdateBtn,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="bg-themeCore shadow-lg z-10 rounded-lg flex flex-col">
      <div className="flex justify-between border-b-2 rounded-tl-lg rounded-tr-lg px-10 border-themeCardBorder bg-white items-center py-4">
        <div className="flex flex-row items-center">
          {header}
          <div
            onClick={handleToggle}
            className="rounded-full bg-themeIconBg p-2 cursor-pointer flex justify-center items-center ml-4"
          >
            {isOpen ? (
              <FaChevronUp size={14} className="text-themeBlue" />
            ) : (
              <FaChevronDown size={14} className="text-themeBlue" />
            )}
          </div>
        </div>

        {!hideUpdateBtn && (
          <CustomButton
            styles={{ background: "white" }}
            btnName={updateButtonLabel}
            customClasses="shadow-md border border-gray-300 text-themeBlack font-medium"
            onClick={onUpdateClick}
          >
            <FaEdit />
          </CustomButton>
        )}
      </div>
      {isOpen && (
        <>
          <div className="px-10 py-6 bg-gray-200 overflow-y-auto flex-grow">
            <div className="my-4">{children}</div>
          </div>
          <div className="px-10 py-4 bg-white flex items-center justify-end space-x-4 border-t border-themeCardBorder rounded-bl-lg rounded-br-lg">
            <CustomButton
              styles={{ background: "white" }}
              btnName={saveButtonLabel}
              customClasses="shadow-md border border-gray-300 text-themeBlack font-medium"
              onClick={onSaveClick}
            >
              <p>
                <CheckOutlined className="mb-1" />
              </p>
            </CustomButton>
          </div>
        </>
      )}
    </div>
  );
};

export default CollapsibleCard;
