import {
  DeleteOutlined,
  EditOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { Switch, TableColumnsType } from "antd";

import { ISalaryRange, ICountry, IVersion } from "@types";
import { checkPermission } from "@/utils";
import { permissions } from "@/router";
import { dateFormat } from "@/constants";

export const getColumns = ({
  editSalaryRange,
  isDeleting,
  salary_range_id,
  removeSalaryRange,
}: {
  editSalaryRange: (salaryRange: ISalaryRange) => void;
  isDeleting: boolean;
  salary_range_id: React.MutableRefObject<any>;
  removeSalaryRange: (id: number) => Promise<void>;
}): TableColumnsType<any> => [
  {
    title: "country",
    dataIndex: "country",
    key: "country",
    width: 200,
    render: (country: ICountry) => country.name,
  },
  {
    title: "city",
    dataIndex: "city",
    key: "city",
    width: 200,
  },
  {
    title: "tier",
    dataIndex: "tier",
    key: "tier",
    width: 200,
  },
  {
    title: "range type",
    dataIndex: "range_type",
    key: "range_type",
    width: 200,
  },
  {
    title: "grade",
    dataIndex: "grade",
    key: "grade",
    width: 200,
  },
  {
    title: "year",
    dataIndex: "year",
    key: "year",
    width: 200,
  },
  {
    title: "min",
    dataIndex: "salary_min",
    key: "salary_min",
    width: 200,
  },
  {
    title: "mid",
    dataIndex: "salary_mid",
    key: "salary_mid",
    width: 200,
  },
  {
    title: "max",
    dataIndex: "salary_max",
    key: "salary_max",
    width: 200,
  },
  ...(checkPermission([
    permissions.DELETE_SALARY_RANGE,
    permissions.UPDATE_SALARY_RANGE,
  ])
    ? ([
        {
          title: "Actions",
          key: "actions",
          width: 160,
          fixed: "right",
          render: (salaryRange: ISalaryRange) => (
            <>
              {checkPermission(permissions.UPDATE_SALARY_RANGE) && (
                <div
                  onClick={() => editSalaryRange(salaryRange)}
                  className="table__action__btn table__action__btn--client"
                >
                  <EditOutlined style={{ color: "#1A8245" }} /> Edit
                </div>
              )}
              {checkPermission(permissions.DELETE_SALARY_RANGE) && (
                <div
                  onClick={() => removeSalaryRange(salaryRange?.id!)}
                  className="table__action__btn table__action__btn--delete"
                >
                  {isDeleting &&
                  salaryRange?.id === salary_range_id?.current ? (
                    <LoadingOutlined color="#B62424" className="spinner" />
                  ) : (
                    <>
                      <DeleteOutlined style={{ color: "#B62424" }} /> Delete
                    </>
                  )}
                </div>
              )}
            </>
          ),
        },
      ] as any)
    : []),
];

export const getVersionsColumns = ({
  handleActive,
  versionId,
  download,
}: {
  handleActive: (id: number) => Promise<void>;
  versionId: number | null;
  download: (id?: number | undefined) => void;
}): TableColumnsType<any> => [
  {
    title: "created at",
    dataIndex: "created_at",
    key: "created_at",
    width: "35%",
    render: (date: string) => <div>{moment(date).format(dateFormat)}</div>,
  },
  ...(!checkPermission(permissions.CREATE_SALARY_RANGE)
    ? []
    : ([
        {
          title: "Active",
          key: "active",
          width: "15%",
          align: "center",
          render: (version: any) => (
            <Switch
              onChange={checked => {
                if (!checked) return;
                handleActive(version?.id);
              }}
              checked={
                versionId === version?.id ||
                (version?.is_active === "TRUE" && versionId === version?.id)
              }
              defaultChecked={version?.is_active === "TRUE"}
            />
          ),
        },
      ] as any)),
  {
    title: "action",
    key: "action",
    width: "15%",
    render: (version: IVersion) => (
      <div
        onClick={() => download(version?.id)}
        className="table__action__btn table__action__btn--client"
      >
        Download
      </div>
    ),
  },
];
