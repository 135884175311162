import Checkbox from "antd/lib/checkbox/Checkbox";
import { useDispatch } from "react-redux";
import { Row, Col, Input } from "antd";
import _ from "lodash";

import { ReactComponent as PlusIcon } from "@assets/images/plus-with-bg.svg";
import { useTypedSelector } from "@hooks";
import {
  handleHiddenStiBonuses,
  handleOfferModeller,
  removeCurrency,
} from "@store/offers";
import { getCurrencyTotal, round } from "@utils";
import { offersApi, useFetchComparatorDataQuery } from "@services";
import { useEffect } from "react";

const ShortTermIncentives = () => {
  const dispatch = useDispatch();

  const {
    offerModeller: { offer_sti, offer_fixed_cash },
    currencyRate,
    currencyName,
    offer,
    hidden_sti_bonuses,
    offerCurrency,
  } = useTypedSelector(state => state.offers);
  const { company } = useTypedSelector(state => state.auth?.user!);
  const { id: company_id } = company!;
  const { data } = useFetchComparatorDataQuery(
    {
      company_id,
      offer_id: offer?.id,
    },
    { skip: !offer?.id }
  );

  const { data: comparatorData } = data || {};
  const currencyCode = currencyName?.split("(")[1]?.replace(")", "") || "";

  const current_bonus_target_amount =
    offer_fixed_cash?.current_annual_base && offer_sti?.current_bonus_target
      ? round(
          (offer_fixed_cash?.current_annual_base *
            offer_sti?.current_bonus_target) /
            100
        )
      : "";
  const proposed_bonus_target_amount =
    offer_fixed_cash?.proposed_annual_base && offer_sti?.proposed_bonus_target
      ? round(
          (offer_fixed_cash?.proposed_annual_base *
            offer_sti?.proposed_bonus_target) /
            100
        )
      : "";

  const readOnly = offer && offer?.status !== "DRAFTED" ? true : false;
  const labelColSpan = currencyCode ? 10 : 12;
  const currencyColSpan = currencyCode ? 7 : 12;

  const { current_currency_total, proposed_currency_total } = getCurrencyTotal(
    {
      ...offer_sti,
      current_bonus_target: current_bonus_target_amount,
      proposed_bonus_target: proposed_bonus_target_amount,
    },
    currencyRate,
    ["proposed_base_pay_percentage"]
  );

  const handleChange = (event: any) => {
    dispatch(
      handleOfferModeller({
        key: "offer_sti",
        data: {
          [event.target.name]: +event.target.value || null,
        },
      })
    );
  };

  const addMoreLocally = (is_current: boolean) => {
    const newCurrent = {
      is_current,
      value: null,
      name: null,
      id: +_.uniqueId(),
      readonly: true,
    };
    dispatch(
      handleOfferModeller({
        key: "offer_sti",
        data: {
          bonuses: [...offer_sti.bonuses, newCurrent],
        },
      })
    );
  };

  const handleChangeNewField = (
    id: number,
    value: number | string,
    type: "name" | "value"
  ) => {
    dispatch(
      handleOfferModeller({
        key: "offer_sti",
        data: {
          bonuses: offer_sti?.bonuses?.map(bonus =>
            bonus?.id === id ? { ...bonus, [type]: value } : bonus
          ) as any,
        },
      })
    );
  };

  const removeField = (id: number) => {
    dispatch(
      handleOfferModeller({
        key: "offer_sti",
        data: {
          bonuses: offer_sti?.bonuses?.map(bonus =>
            bonus?.id === id ? { ...bonus, readonly: false } : bonus
          ) as any,
        },
      })
    );
  };

  const removeBonus = (id: number) => {
    dispatch(
      handleOfferModeller({
        key: "offer_sti",
        data: {
          bonuses: offer_sti?.bonuses?.filter(bonus => bonus?.id !== id) as any,
        },
      })
    );
  };

  const proposedTotal =
    (offer_fixed_cash?.proposed_total || 0) +
    (offer_sti?.proposed_total_bonus || 0);
  const currentTotal =
    (offer_fixed_cash?.current_total || 0) +
    (offer_sti?.current_bonus_target || 0);

  let percentageDifference = 0;

  if (proposedTotal > 0 && currentTotal > 0) {
    percentageDifference = (proposedTotal / currentTotal - 1) * 100;
  }

  const finalPercentage =
    percentageDifference > 0 ? percentageDifference.toFixed(0) : 0;

  let id = comparatorData?.currency?.market_currency?.id;

  useEffect(() => {
    if (id != offer?.offer_position_detail?.currency?.id) {
      dispatch(
        offersApi.endpoints.fetchStiCurrencyRate.initiate(
          {
            code: id,
            company_id,
            offer_id: offer?.id,
          },
          {
            subscribe: false,
            forceRefetch: true,
          }
        )
      );

      // updateNew();
    }
  }, [id]);

  return (
    <>
      <Row className="salary-offer">
        <Col className="salary-offer__parent-col" span={12}>
          <Row className="salary-offer__header-row">
            <Col span={12}>
              <div className="sub-heading">Current</div>
            </Col>
          </Row>
          <Row className="salary-offer__content-row">
            {/* Current-Cols */}
            <Col className="salary-offer__content-row__col" span={labelColSpan}>
              <div className="salary-offer__content-row__field-upper" />

              <div className="salary-offer__content-row__field">
                <label>Bonus Target %</label>
              </div>

              <div className="salary-offer__content-row__field">
                <label>Bonus Target Amount</label>
              </div>
              <div className="salary-offer__content-row__field">
                <label>Sales Incentive:</label>
              </div>

              {offer_sti?.bonuses
                ?.filter(({ is_current }) => is_current)
                ?.map(({ name, id, readonly }) => (
                  <div key={id} className="salary-offer__content-row__field">
                    {name?.length && !readonly! ? (
                      <label>{name}</label>
                    ) : (
                      <Input
                        readOnly={readOnly}
                        autoFocus
                        onChange={e =>
                          handleChangeNewField(id!, e?.target?.value, "name")
                        }
                        onKeyDown={e => {
                          if (e.key === "Enter") {
                            removeField(id!);
                          }
                        }}
                        value={name || ""}
                        placeholder="Value here..."
                        size="large"
                      />
                    )}
                    {!readOnly && (
                      <div
                        className="salary-offer__content-row__field__remove"
                        onClick={() => removeBonus(id!)}
                      >
                        <PlusIcon fill="#000" />
                      </div>
                    )}
                  </div>
                ))}

              {!readOnly ? (
                <div
                  onClick={() => addMoreLocally(true)}
                  className="salary-offer__content-row__field--add-more"
                >
                  <PlusIcon fill="#4FAA89" />
                  <span>Add more</span>
                </div>
              ) : (
                <div className="salary-offer__content-row__field--add-more" />
              )}

              {/* <div className="salary-offer__content-row__field salary-offer__content-row__field--totalBB">
                <label>Total Bonus</label>
              </div> */}
            </Col>
            <Col
              className="salary-offer__content-row__col"
              span={currencyColSpan}
            >
              <div className="salary-offer__content-row__field-upper">
                {offer?.offer_position_detail?.currency?.code}
              </div>
              <div className="salary-offer__content-row__field">
                <Input
                  readOnly={readOnly}
                  type="number"
                  name="current_bonus_target"
                  onChange={handleChange}
                  value={offer_sti?.current_bonus_target || ""}
                  placeholder="Value here..."
                  size="large"
                />
              </div>
              <div className="salary-offer__content-row__field">
                <Input
                  readOnly
                  type="number"
                  value={current_bonus_target_amount || ""}
                  placeholder="Value here..."
                  size="large"
                />
              </div>
              <div className="salary-offer__content-row__field">
                <Input
                  readOnly={readOnly}
                  type="number"
                  name="current_sale_incentive"
                  value={offer_sti?.current_sale_incentive || ""}
                  onChange={handleChange}
                  placeholder="Value here..."
                  size="large"
                />
              </div>

              {offer_sti?.bonuses
                ?.filter(({ is_current }) => is_current)
                ?.map(({ name, value, id }) => (
                  <div key={id} className="salary-offer__content-row__field">
                    <Input
                      readOnly={readOnly}
                      name={name!}
                      type="number"
                      value={value || ""}
                      onChange={e =>
                        handleChangeNewField(id!, +e.target.value, "value")
                      }
                      placeholder="Value here..."
                      size="large"
                    />
                  </div>
                ))}

              <div className="salary-offer__content-row__field--add-more" />
              {/* <div className="salary-offer__content-row__field salary-offer__content-row__field--totalBB">
                <Input
                  readOnly
                  value={offer_sti?.current_total_bonus || ""}
                  name="current_total_bonus"
                  placeholder="Value here..."
                  size="large"
                />
              </div> */}
            </Col>
            {currencyCode ? (
              <Col className="salary-offer__content-row__col" span={7}>
                <div className="salary-offer__content-row__field-upper">
                  {currencyCode}
                </div>
                <div className="salary-offer__content-row__field">
                  <Input
                    readOnly={readOnly}
                    type="number"
                    value={
                      offer_sti?.current_bonus_target
                        ? round(
                            offer_sti?.current_bonus_target * currencyRate!
                          ) || ""
                        : ""
                    }
                    onChange={handleChange}
                    placeholder="Value here..."
                    size="large"
                  />
                </div>
                <div className="salary-offer__content-row__field">
                  <Input
                    value={
                      current_bonus_target_amount
                        ? round(current_bonus_target_amount * currencyRate!) ||
                          ""
                        : ""
                    }
                    readOnly
                    placeholder="Value here..."
                    size="large"
                  />
                </div>

                <div className="salary-offer__content-row__field">
                  <Input
                    value={
                      round(
                        offer_sti?.current_sale_incentive! * currencyRate!
                      ) || ""
                    }
                    readOnly
                    placeholder="Value here..."
                    size="large"
                  />
                </div>
                {offer_sti?.bonuses
                  ?.filter(({ is_current }) => is_current)
                  ?.map(({ id, value }) => (
                    <div key={id} className="salary-offer__content-row__field">
                      <Input
                        value={round(value! * currencyRate!) || ""}
                        readOnly
                        placeholder="Value here..."
                        size="large"
                      />
                    </div>
                  ))}

                <div className="salary-offer__content-row__field--add-more" />
                {/* <div className="salary-offer__content-row__field salary-offer__content-row__field--totalBB ">
                  <Input
                    value={current_currency_total || ""}
                    readOnly
                    placeholder="Value here..."
                    size="large"
                  />
                </div> */}
              </Col>
            ) : null}
          </Row>
        </Col>
        <Col className="salary-offer__parent-col" span={12}>
          <Row className="salary-offer__header-row">
            <Col span={12}>
              <div className="sub-heading">Proposed</div>
            </Col>
          </Row>
          <Row className="salary-offer__content-row">
            {/* Proposed-Cols */}
            <Col className="salary-offer__content-row__col" span={labelColSpan}>
              <div className="salary-offer__content-row__field-upper"></div>
              <div className="salary-offer__content-row__field">
                <label>Bonus Target %</label>
              </div>

              <div className="salary-offer__content-row__field">
                <label>Bonus Target Amount</label>
              </div>

              <div className="salary-offer__content-row__field">
                <label>
                  <Checkbox
                    checked={offer_sti?.is_sale_incentive}
                    disabled={readOnly}
                    onChange={e => {
                      dispatch(
                        handleOfferModeller({
                          key: "offer_sti",
                          data: {
                            is_sale_incentive: e.target.checked,
                            bonuses: !e.target.checked
                              ? [...offer_sti?.bonuses, ...hidden_sti_bonuses]
                              : offer_sti?.bonuses?.filter(
                                  bonus => !bonus?.auto_populate
                                ),
                          },
                        })
                      );
                      dispatch(
                        handleHiddenStiBonuses(
                          e.target.checked
                            ? offer_sti?.bonuses?.filter(
                                bonus => bonus?.auto_populate
                              )
                            : []
                        )
                      );
                    }}
                  >
                    Sales Incentive
                  </Checkbox>
                </label>
              </div>
              <div
                className={`salary-offer__content-row__field salary-offer__content-row__field--nested ${
                  offer_sti?.is_sale_incentive ? "visible" : ""
                }`}
              >
                <label>Annual Base Pay:</label>
              </div>
              <div
                className={`salary-offer__content-row__field salary-offer__content-row__field--nested ${
                  offer_sti?.is_sale_incentive ? "visible" : ""
                }`}
              >
                <label>Base Pay %:</label>
              </div>
              <div
                className={`salary-offer__content-row__field salary-offer__content-row__field--nested ${
                  offer_sti?.is_sale_incentive ? "visible" : ""
                }`}
              >
                <label>Incentive %:</label>
              </div>
              <div
                className={`salary-offer__content-row__field salary-offer__content-row__field--nested ${
                  offer_sti?.is_sale_incentive ? "visible" : ""
                }`}
              >
                <label>Sales Incentive Payout:</label>
              </div>
              {offer_sti?.bonuses
                ?.filter(({ is_current }) => !is_current)
                ?.map(({ name, id, readonly }) => (
                  <div key={id} className="salary-offer__content-row__field">
                    {name?.length && !readonly! ? (
                      <label>{name}</label>
                    ) : (
                      <Input
                        readOnly={readOnly}
                        autoFocus
                        onChange={e =>
                          handleChangeNewField(id!, e?.target?.value, "name")
                        }
                        onKeyDown={e => {
                          if (e.key === "Enter") {
                            removeField(id!);
                          }
                        }}
                        value={name || ""}
                        placeholder="Value here..."
                        size="large"
                      />
                    )}
                    {!readOnly && (
                      <div
                        className="salary-offer__content-row__field__remove"
                        onClick={() => removeBonus(id!)}
                      >
                        <PlusIcon fill="#000" />
                      </div>
                    )}
                  </div>
                ))}

              {!readOnly ? (
                <div
                  onClick={() => addMoreLocally(false)}
                  className="salary-offer__content-row__field--add-more"
                >
                  <PlusIcon fill="#4FAA89" />
                  <span>Add more</span>
                </div>
              ) : (
                <div className="salary-offer__content-row__field--add-more" />
              )}

              {/* <div className="salary-offer__content-row__field salary-offer__content-row__field--totalBB">
                <label>Total Bonus/Incentive</label>
              </div> */}
              {/* <div className="salary-offer__content-row__field salary-offer__content-row__field">
                <label>Target Total Cash</label>
              </div> */}
            </Col>
            <Col
              className="salary-offer__content-row__col"
              span={currencyColSpan}
            >
              <div className="salary-offer__content-row__field-upper">
                {offer?.offer_position_detail?.currency?.code}
              </div>
              <div className="salary-offer__content-row__field">
                <Input
                  disabled={offer_sti?.is_sale_incentive}
                  type="number"
                  readOnly={readOnly}
                  name="proposed_bonus_target"
                  onChange={handleChange}
                  value={offer_sti?.proposed_bonus_target || ""}
                  placeholder="Value here..."
                  size="large"
                />
              </div>
              <div className="salary-offer__content-row__field">
                <Input
                  readOnly
                  disabled={offer_sti?.is_sale_incentive}
                  type="number"
                  value={proposed_bonus_target_amount || ""}
                  placeholder="Value here..."
                  size="large"
                />
              </div>
              <div className="salary-offer__content-row__field" />
              <div
                className={`${
                  offer_sti?.is_sale_incentive ? "visible" : ""
                } salary-offer__content-row__field salary-offer__content-row__field--nested`}
              >
                <Input
                  type="number"
                  disabled={!offer_sti?.is_sale_incentive}
                  contentEditable={offer_sti?.is_sale_incentive}
                  readOnly={readOnly}
                  // onChange={event => {
                  //   const val = +event.target.value
                  //   dispatch(
                  //     handleOfferModeller({
                  //       key: "offer_fixed_cash",
                  //       data: {
                  //         proposed_annual_base: val || null,
                  //         proposed_compa_ratio:
                  //           (comparatorData?.internal_comparator_data
                  //             ?.internal_salary_range?.mid &&
                  //             val &&
                  //             val /
                  //               comparatorData?.internal_comparator_data
                  //                 ?.internal_salary_range?.mid) ||
                  //           null,
                  //         proposed_market_ratio:
                  //           (comparatorData?.external_comparator_data
                  //             ?.market_data_grade?.p50 &&
                  //             val &&
                  //             val /
                  //               comparatorData?.external_comparator_data
                  //                 ?.market_data_grade?.p50) ||
                  //           null,
                  //       },
                  //     })
                  //   );
                  //   dispatch(
                  //     handleOfferModeller({
                  //       key: "offer_fixed_cash",
                  //       data: {
                  //         allowances: offer_fixed_cash?.allowances?.map(
                  //           allowance => {
                  //             return allowance?.is_percentage
                  //               ? {
                  //                   ...allowance,
                  //                   value: allowance?.percentage
                  //                     ? (val * allowance?.percentage) / 100
                  //                     : val,
                  //                 }
                  //               : allowance;
                  //           }
                  //         ),
                  //       },
                  //     })
                  //   );


                    
                  // }}


                  onChange={event => {
                    const val = +event.target.value;
                    dispatch(
                      handleOfferModeller({
                        key: "offer_fixed_cash",
                        data: {
                          proposed_annual_base: val || null,
                          proposed_compa_ratio:
                            (comparatorData?.internal_comparator_data
                              ?.internal_salary_range?.mid &&
                              val &&
                              (offerCurrency != null
                                ? val * offerCurrency
                                : val) /
                                comparatorData?.internal_comparator_data
                                  ?.internal_salary_range?.mid) ||
                            null,

                          // proposed_market_ratio:
                          //   (comparatorData?.external_comparator_data
                          //     ?.market_data_grade?.p50 &&
                          //     val &&
                          //     val /
                          //       comparatorData?.external_comparator_data
                          //         ?.market_data_grade?.p50) ||
                          //   null,

                          proposed_market_ratio:
                            comparatorData?.external_comparator_data
                              ?.market_data_grade?.p50 && val
                              ? (offerCurrency != null
                                  ? val * offerCurrency
                                  : val) /
                                (comparatorData?.external_comparator_data
                                  ?.market_data_grade_function?.p50 ||
                                  comparatorData?.external_comparator_data
                                    ?.market_data_grade?.p50)
                              : null,
                        },
                      })
                    );

                    dispatch(
                      handleOfferModeller({
                        key: "offer_fixed_cash",
                        data: {
                          allowances: offer_fixed_cash?.allowances?.map(
                            allowance => {
                              return allowance?.is_percentage
                                ? {
                                    ...allowance,
                                    value: allowance?.percentage
                                      ? (val * allowance?.percentage) / 100
                                      : val,
                                  }
                                : allowance;
                            }
                          ),
                        },
                      })
                    );

                    dispatch(
                      handleOfferModeller({
                        key: "offer_sti",
                        data: {
                          bonuses: offer_sti?.bonuses?.map(bonus => {
                            return bonus?.is_percentage && bonus?.is_basic_pay
                              ? {
                                  ...bonus,
                                  value: bonus?.percentage
                                    ? (val * bonus?.percentage) / 100
                                    : val,
                                }
                              : bonus?.is_percentage && !bonus?.is_basic_pay
                              ? {
                                  ...bonus,
                                  value: bonus?.percentage
                                    ? (offer_fixed_cash?.proposed_total *
                                        bonus?.percentage) /
                                      100
                                    : val,
                                }
                              : bonus;
                          }),
                        },
                      })
                    );
                  }}
                  value={offer_fixed_cash?.proposed_annual_base || ""}
                  placeholder="Value here..."
                  size="large"
                />
              </div>
              <div
                className={`${
                  offer_sti?.is_sale_incentive ? "visible" : ""
                } salary-offer__content-row__field salary-offer__content-row__field--nested`}
              >
                <Input
                  disabled={!offer_sti?.is_sale_incentive}
                  contentEditable={offer_sti?.is_sale_incentive}
                  type="number"
                  name="proposed_base_pay_percentage"
                  value={offer_sti?.proposed_base_pay_percentage || ""}
                  onChange={handleChange}
                  placeholder="Value here..."
                  readOnly={readOnly}
                  size="large"
                />
              </div>
              <div
                className={`${
                  offer_sti?.is_sale_incentive ? "visible" : ""
                } salary-offer__content-row__field salary-offer__content-row__field--nested`}
              >
                <Input
                  type="number"
                  readOnly
                  disabled={!offer_sti?.is_sale_incentive}
                  contentEditable={offer_sti?.is_sale_incentive}
                  value={
                    offer_sti?.proposed_base_pay_percentage
                      ? 100 - offer_sti?.proposed_base_pay_percentage
                      : ""
                  }
                  placeholder="Value here..."
                  size="large"
                />
              </div>
              <div
                className={`${
                  offer_sti?.is_sale_incentive ? "visible" : ""
                } salary-offer__content-row__field salary-offer__content-row__field--nested`}
              >
                <Input
                  type="number"
                  readOnly
                  disabled={!offer_sti?.is_sale_incentive}
                  contentEditable={offer_sti?.is_sale_incentive}
                  value={
                    offer_fixed_cash?.proposed_annual_base &&
                    offer_sti?.proposed_base_pay_percentage
                      ? round(
                          (offer_fixed_cash?.proposed_annual_base *
                            ((offer_fixed_cash?.proposed_annual_base *
                              (100 - offer_sti?.proposed_base_pay_percentage)) /
                              100)) /
                            ((offer_fixed_cash?.proposed_annual_base *
                              offer_sti?.proposed_base_pay_percentage) /
                              100)
                        ) || ""
                      : ""
                  }
                  placeholder="Value here..."
                  size="large"
                />
              </div>

              {offer_sti?.bonuses
                ?.filter(({ is_current }) => !is_current)
                ?.map(({ name, value, id, is_percentage, is_basic_pay }) => {

                  // console.log(is_percentage, is_basic_pay, value)
                  // let updatedVal =
                  //   is_percentage && is_basic_pay
                  //     ? (offer_fixed_cash?.proposed_annual_base || 1 * value) /
                  //       100
                  //     : is_percentage && !is_basic_pay
                  //     ? (offer_fixed_cash?.proposed_total || 1 * value) / 100
                  //     : value;

                  return (
                    <div key={id} className="salary-offer__content-row__field">
                      <Input
                        name={name!}
                        readOnly={readOnly}
                        type="number"
                        value={
                          is_percentage &&
                          offer_fixed_cash?.proposed_annual_base
                            ? round(value)
                            : !is_percentage
                            ? round(value) || ""
                            : ""
                        }
                        onChange={e => {
                          if (!is_percentage) {
                            handleChangeNewField(id!, +e.target.value, "value");
                          }
                        }}
                        // placeholder="Value here..."
                        size="large"
                      />
                    </div>
                  );
                })}

              <div className="salary-offer__content-row__field--add-more" />
              {/* <div className="salary-offer__content-row__field salary-offer__content-row__field--totalBB">
                <Input
                  readOnly
                  value={offer_sti?.proposed_total_bonus || ""}
                  name="proposed_total_bonus"
                  placeholder="Value here..."
                  size="large"
                />
              </div> */}
              {/* <div className="salary-offer__content-row__field salary-offer__content-row__field">
                <Input
                  readOnly
                  value={
                    offer_fixed_cash?.proposed_total &&
                    offer_sti?.proposed_total_bonus
                      ? offer_fixed_cash?.proposed_total +
                        offer_sti?.proposed_total_bonus
                      : ""
                  }
                  name="target_total_cash"
                  placeholder="Value here..."
                  size="large"
                />
              </div> */}
            </Col>
            {currencyCode ? (
              <Col className="salary-offer__content-row__col" span={7}>
                <div className="salary-offer__content-row__field-upper">
                  {currencyCode}
                </div>
                <div className="salary-offer__content-row__field">
                  <Input
                    value={round(
                      offer_sti?.proposed_bonus_target * currencyRate!
                    )}
                    readOnly
                    placeholder="Value here..."
                    size="large"
                  />
                </div>
                <div className="salary-offer__content-row__field">
                  <Input
                    value={
                      proposed_bonus_target_amount
                        ? round(proposed_bonus_target_amount * currencyRate!) ||
                          ""
                        : ""
                    }
                    readOnly
                    placeholder="Value here..."
                    size="large"
                  />
                </div>

                <div className="salary-offer__content-row__field" />
                <div className="salary-offer__content-row__field" />
                <div className="salary-offer__content-row__field" />
                <div className="salary-offer__content-row__field" />
                <div className="salary-offer__content-row__field" />

                {offer_sti?.bonuses
                  ?.filter(({ is_current }) => !is_current)
                  ?.map(({ id, value, is_percentage, is_basic_pay }) => {
                    let updatedVal =
                      is_percentage && is_basic_pay
                        ? (offer_fixed_cash?.proposed_annual_base ||
                            1 * value) / 100
                        : is_percentage && !is_basic_pay
                        ? (offer_fixed_cash?.proposed_total || 1 * value) / 100
                        : value;
                    return (
                      <div
                        key={id}
                        className="salary-offer__content-row__field"
                      >
                        <Input
                          value={round(updatedVal! * currencyRate!) || ""}
                          readOnly
                          placeholder="Value here..."
                          size="large"
                        />
                      </div>
                    );
                  })}

                <div className="salary-offer__content-row__field--add-more" />
                {/* <div className="salary-offer__content-row__field salary-offer__content-row__field--totalBB ">
                    <Input
                      value={proposed_currency_total || ""}
                      readOnly
                      placeholder="Value here..."
                      size="large"
                    />
                  </div> */}
                {/* 
                <div className="salary-offer__content-row__field salary-offer__content-row__field--totalBB ">
                  <Input
                    value={current_currency_total || ""}
                    readOnly
                    placeholder="Value here..."
                    size="large"
                  />
                </div> */}
              </Col>
            ) : null}
          </Row>
        </Col>
      </Row>

      <Row className="salary-offer">
        <Col className="salary-offer__parent-col" span={12}>
          <Row className="salary-offer__content-row">
            <Col className="salary-offer__content-row__col" span={labelColSpan}>
              <div className="salary-offer__content-row__field salary-offer__content-row__field--totalBB">
                <label>Total Bonus</label>
              </div>
            </Col>
            <Col
              className="salary-offer__content-row__col"
              span={currencyColSpan}
            >
              <div className="salary-offer__content-row__field salary-offer__content-row__field--totalBB">
                <Input
                  readOnly
                  value={offer_sti?.current_total_bonus || ""}
                  name="current_total_bonus"
                  placeholder="Value here..."
                  size="large"
                />
              </div>
            </Col>

            {currencyCode ? (
              <Col className="salary-offer__content-row__col" span={7}>
                <div className="salary-offer__content-row__field salary-offer__content-row__field--totalBB ">
                  <Input
                    value={current_currency_total || ""}
                    readOnly
                    placeholder="Value here..."
                    size="large"
                  />
                </div>
              </Col>
            ) : null}
          </Row>
        </Col>
        <Col className="salary-offer__parent-col" span={12}>
          <Row className="salary-offer__content-row">
            <Col className="salary-offer__content-row__col" span={labelColSpan}>
              <div className="salary-offer__content-row__field salary-offer__content-row__field--totalBB">
                <label>Total Bonus/Incentive</label>
              </div>
            </Col>

            <Col
              className="salary-offer__content-row__col"
              span={currencyColSpan}
            >
              <div className="salary-offer__content-row__field salary-offer__content-row__field--totalBB">
                <Input
                  readOnly
                  value={
                    offer_sti?.is_sale_incentive
                      ? offer_fixed_cash?.proposed_annual_base &&
                        offer_sti?.proposed_base_pay_percentage
                        ? round(
                            (offer_fixed_cash?.proposed_annual_base *
                              ((offer_fixed_cash?.proposed_annual_base *
                                (100 -
                                  offer_sti?.proposed_base_pay_percentage)) /
                                100)) /
                              ((offer_fixed_cash?.proposed_annual_base *
                                offer_sti?.proposed_base_pay_percentage) /
                                100)
                          ) + offer_sti?.proposed_total_bonus ||
                          0 + offer_sti?.proposed_total_bonus
                        : 0 + offer_sti?.proposed_total_bonus
                      : offer_sti?.proposed_total_bonus
                  }
                  // value={ offer_sti?.is_sale_incentive  ? ( offer_fixed_cash?.proposed_annual_base +offer_sti?.proposed_total_bonus ) : offer_sti?.proposed_total_bonus || ""}
                  // value={ offer_sti?.is_sale_incentive  ? ( offer_fixed_cash?.proposed_annual_base +offer_sti?.proposed_total_bonus ) : offer_sti?.proposed_total_bonus || ""}
                  name="proposed_total_bonus"
                  placeholder="Value here..."
                  size="large"
                />
              </div>
            </Col>

            {currencyCode ? (
              <Col className="salary-offer__content-row__col" span={7}>
                <div className="salary-offer__content-row__field salary-offer__content-row__field--totalBB ">
                  <Input
                    value={proposed_currency_total || ""}
                    readOnly
                    placeholder="Value here..."
                    size="large"
                  />
                </div>
              </Col>
            ) : null}
          </Row>
        </Col>
      </Row>

      <Row className="salary-offer">
        <Col className="salary-offer__parent-col" span={12}>
          <Row className="salary-offer__content-row">
            <Col className="salary-offer__content-row__col" span={labelColSpan}>
              <div className="salary-offer__content-row__field salary-offer__content-row__field--totalBB"></div>
            </Col>
            <Col
              className="salary-offer__content-row__col"
              span={currencyCode ? 14 : currencyColSpan}
            >
              <div className="salary-offer__content-row__field salary-offer__content-row__field--totalBB"></div>
            </Col>
          </Row>
        </Col>
        <Col className="salary-offer__parent-col" span={12}>
          <Row className="salary-offer__content-row">
            <Col className="salary-offer__content-row__col" span={labelColSpan}>
              <div className="salary-offer__content-row__field salary-offer__content-row__field--totalBB">
                <label>Total Bonus Difference</label>
              </div>
            </Col>

            <Col
              className="salary-offer__content-row__col"
              span={currencyCode ? 14 : currencyColSpan}
            >
              <div className="salary-offer__content-row__field salary-offer__content-row__field--totalBB">
                <div
                  className={`w-full px-6 text-white shadow-md my-2 font-semibold text-center text-sm outline-none border border-themeInputBorder bg-themeBlue  py-2 rounded-md`}
                >
                  {offer_sti?.proposed_total_bonus &&
                  offer_sti?.current_total_bonus
                    ? `${round(
                        (  offer_sti?.proposed_total_bonus /
                          offer_sti?.current_total_bonus -
                          1) *
                          100
                      )}%` || ""
                    : "0%"}
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="salary-offer">
        <Col className="salary-offer__parent-col" span={12}>
          <Row className="salary-offer__content-row">
            <Col className="salary-offer__content-row__col" span={labelColSpan}>
              <div className="salary-offer__content-row__field salary-offer__content-row__field--totalBB">
                <label>Target Total Cash</label>
              </div>
            </Col>
            <Col
              className="salary-offer__content-row__col"
              span={currencyColSpan}
            >
              <div className="salary-offer__content-row__field salary-offer__content-row__field--totalBB">
                <Input
                  readOnly
                  value={currentTotal || ""}
                  name="current_total_bonus"
                  placeholder="Value here..."
                  size="large"
                />
              </div>
            </Col>

            {currencyCode ? (
              <Col className="salary-offer__content-row__col" span={7}>
                <div className="salary-offer__content-row__field salary-offer__content-row__field--totalBB ">
                  <Input
                    value={
                      Number(currentTotal * currencyRate!).toFixed(2) || ""
                    }
                    readOnly
                    placeholder="Value here..."
                    size="large"
                  />
                </div>
              </Col>
            ) : null}
          </Row>
        </Col>
        <Col className="salary-offer__parent-col" span={12}>
          <Row className="salary-offer__content-row">
            <Col className="salary-offer__content-row__col" span={labelColSpan}>
              <div className="salary-offer__content-row__field salary-offer__content-row__field--totalBB">
                <label>Target Total Cash</label>
              </div>
            </Col>

            <Col
              className="salary-offer__content-row__col"
              span={currencyColSpan}
            >
              <div className="salary-offer__content-row__field salary-offer__content-row__field">
                <Input
                  readOnly
                  value={proposedTotal || ""}
                  name="target_total_cash"
                  placeholder="Value here..."
                  size="large"
                />
              </div>
            </Col>

            {currencyCode ? (
              <Col className="salary-offer__content-row__col" span={7}>
                <div className="salary-offer__content-row__field salary-offer__content-row__field--totalBB ">
                  <Input
                    value={
                      Number(proposedTotal * currencyRate!).toFixed(2) || ""
                    }
                    readOnly
                    placeholder="Value here..."
                    size="large"
                  />
                </div>
              </Col>
            ) : null}
          </Row>
        </Col>
      </Row>

      <Row className="salary-offer">
        <Col className="salary-offer__parent-col" span={12}>
          <Row className="salary-offer__content-row">
            <Col className="salary-offer__content-row__col" span={labelColSpan}>
              <div className="salary-offer__content-row__field salary-offer__content-row__field--totalBB"></div>
            </Col>
            <Col
              className="salary-offer__content-row__col"
              span={currencyCode ? 14 : currencyColSpan}
            >
              <div className="salary-offer__content-row__field salary-offer__content-row__field--totalBB"></div>
            </Col>
          </Row>
        </Col>
        <Col className="salary-offer__parent-col" span={12}>
          <Row className="salary-offer__content-row">
            <Col className="salary-offer__content-row__col" span={labelColSpan}>
              <div className="salary-offer__content-row__field salary-offer__content-row__field--totalBB">
                <label>Target Cash Difference</label>
              </div>
            </Col>

            <Col
              className="salary-offer__content-row__col"
              span={currencyCode ? 14 : currencyColSpan}
            >
              <div className="salary-offer__content-row__field salary-offer__content-row__field--totalBB">
                <div
                  className={`w-full px-6 text-white shadow-md my-2 font-semibold text-center text-sm outline-none border border-themeInputBorder bg-themeBlue py-2 rounded-md`}
                >
                  {finalPercentage}%
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default ShortTermIncentives;
