import { Button, Col, Input, message, Row } from "antd";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { FC, useRef } from "react";

import "./uploadSalaryRange.less";
import Modal from "@components/Modal";
import { IModal } from "@types";
import { validateFile } from "@utils";
import CustomModal from "../customModel";

interface IUpload extends IModal {
  onSubmit: any;
  file: { file: File | null; active: boolean };
  title: string;
  setFile: React.Dispatch<
    React.SetStateAction<{
      file: null;
      active: boolean;
    }>
  >;
}

const Upload: FC<IUpload> = ({
  isVisible,
  setIsVisible,
  onSubmit,
  file,
  setFile,
  title,
}) => {
  const inputRef = useRef<any>(null);

  const uploadFile = (event: any) => {
    var file = event?.target?.files[0];
    if (validateFile(file?.type)) {
      setFile(prev => ({ ...prev, file }));
    } else {
      message.error("Only CVS and XLS file types are supported");
    }
  };

  return (
    <CustomModal
      width="w-5/12"
      isVisible={isVisible}
      title={title}
      onClose={() => setIsVisible(false)}
      footer={
        <>
          <button
            className="bg-themeGrey text-themeBlack font-medium text-lg py-2 px-14 rounded-md"
            onClick={() => setIsVisible(false)}
          >
            Cancel
          </button>
          <button
            className={`bg-themeBlue text-white font-medium text-lg py-2 px-14 rounded-md `}
            onClick={onSubmit}
            disabled={!file?.file}
          >
            Upload
          </button>
        </>
      }
    >
      <>
        <Row justify="space-between" className="modal__row">
          <Col className="uploadSalaryRange__uploadField" span={24}>
            <label className="font-semibold !text-themeInputTitleColor text-lg leading-10 ">
              Upload File
            </label>

            <Input
              size="large"
              name="name"
              readOnly
              accept=".csv, application/vnd.ms-excel"
              className="w-full shadow-sm font-semibold text-sm  outline-none border border-themeInputBorder bg-white rounded-md"
              value={file?.file?.name || ""}
              placeholder="CSV and XLS are supported"
              addonAfter={
                <button
                  className="bg-themeBlue text-white  py-2 px-7 rounded-md"
                  type="button"
                  onClick={() => inputRef?.current?.click()}
                >
                  Upload
                </button>
              }
            />
            <input
              id="myInput"
              type="file"
              ref={inputRef}
              hidden={true}
              onChange={uploadFile}
            />
          </Col>
        </Row>
        <Row className="mb-20">
          <Checkbox
            checked={file?.active}
            onChange={event =>
              setFile(prev => ({ ...prev, active: event.target.checked }))
            }
          >
            <span
              className={"font-semibold  text-lg text-themeInputTitleColor "}
            >
              Active
            </span>
          </Checkbox>
        </Row>
      </>
    </CustomModal>
  );
};
export default Upload;
