import { useEffect, useState } from "react";
import { Col, message, Row } from "antd";
import { useDispatch } from "react-redux";

import Button from "@components/Button";
import { getVersionsColumns } from "./config";
import {
  ErrorServices,
  internalPayrollDataApi,
  useFetchInternalPayrollDataVersionsQuery,
  useUpdateInternalPayrollDataVersionMutation,
  useUploadInternalPayrollDataMutation,
} from "@services";
import Table from "@components/Table";
import Upload from "@components/Upload";
import { IVersion } from "@types";
import { useTypedSelector } from "@hooks";
import { checkPermission } from "@/utils";
import { permissions } from "@/router";
import SearchBox from "@/components/searchInput";
import CustomButton from "@/components/customButton";
import { MdOutlineFileDownload, MdOutlineFileUpload } from "react-icons/md";

const InternalPayrollData = () => {
  const { company } = useTypedSelector(state => state.auth?.user!);
  const { id: company_id } = company!;
  const dispatch = useDispatch();
  const { data: versions, isLoading } =
    useFetchInternalPayrollDataVersionsQuery({
      page: 1,
      company_id,
    });
  const [updateInternalPayrollDataVersion] =
    useUpdateInternalPayrollDataVersionMutation();
  const { data } = versions || {};
  const [isUploadModal, setIsUploadModal] = useState(false);
  const [uploadFile, setUploadFile] = useState({
    file: null,
    active: false,
  });
  const [uploadInternalPayrollData, { isLoading: isUploading }] =
    useUploadInternalPayrollDataMutation();
  const [currentId, setCurrentId] = useState<null | number>(null);
  const [searchInput, setSearchInput] = useState("");

  useEffect(() => {
    if (data?.length) {
      const { id } = data?.find(
        (version: IVersion) => version?.is_active === "TRUE"
      );
      setCurrentId(id);
    }
  }, [data]);

  const handleActive = async (id: number) => {
    try {
      setCurrentId(id);
      await updateInternalPayrollDataVersion({ id, company_id }).unwrap();
    } catch (error) {
      ErrorServices(error);
      console.log(error);
    }
  };

  const handleUpload = async () => {
    const { file, active }: { file: any; active: boolean } = uploadFile;
    setIsUploadModal(false);
    try {
      const formData = new FormData();
      formData.append("attachment", file!, file?.name!);
      const res = await uploadInternalPayrollData({
        company_id,
        active,
        body: formData,
      }).unwrap();
      message.success(res?.message);
    } catch (error) {
      message.error(error?.message);
      console.log(error);
    }
  };

  const download = (id?: number) => {
    dispatch(
      internalPayrollDataApi.endpoints.downloadInternalPayrollData.initiate(
        {
          company_id,
          version_id: id || null,
        },
        {
          subscribe: false,
          forceRefetch: true,
        }
      )
    );
  };

  const versionsColumns = getVersionsColumns({
    handleActive,
    currentId,
    download,
  });

  return (
    <>
      {isUploadModal && (
        <Upload
          title="Upload Internal Payroll Data"
          onSubmit={handleUpload}
          isVisible={isUploadModal}
          setIsVisible={setIsUploadModal}
          file={uploadFile}
          setFile={setUploadFile}
        />
      )}

      <div className="bg-themeCore">
        <div className="jobCreationBar bg-white px-4 py-6 rounded-lg flex justify-between items-center ">
          <div className="title text-themeBlue  font-medium text-3xl">
            Internal Payroll Data
          </div>

          <div className="flex flex-row justify-between">
            {checkPermission(permissions.CREATE_INTERNAL_PAYROLL) ||
            checkPermission(permissions.UPDATE_INTERNAL_PAYROLL) ? (
              <CustomButton
                isLoading={isUploading}
                onClick={() => setIsUploadModal(true)}
                btnName="Upload"
                customClasses="shadow-md font-medium border py-1 px-6 !border-themeBorder"
                iconPosition="left"
              >
                <MdOutlineFileUpload
                  size={20}
                  className="text-white font-bold"
                />
              </CustomButton>
            ) : null}
            {checkPermission(permissions.VIEW_INTERNAL_PAYROLL) && (
              <CustomButton
                onClick={() => download()}
                btnName="Download"
                customClasses="shadow-md font-medium border py-1 px-6 !border-themeBorder ml-4"
                iconPosition="right"
              >
                <MdOutlineFileDownload
                  size={20}
                  className="text-white font-bold"
                />
              </CustomButton>
            )}
          </div>
        </div>
      </div>

      <div className="jobCreationListing mt-6 bg-white px-14 py-4 rounded-lg">
        <SearchBox
          placeholder="Type to search..."
          value={searchInput}
          onChange={value => setSearchInput(value)}
        />
        <Row>
          <Table
            isLoading={isLoading}
            data={data}
            columns={versionsColumns}
            pagination={false}
          />
        </Row>
      </div>
    </>
  );
};

export default InternalPayrollData;
