import { useDispatch } from "react-redux";
import { Row, Col, Input } from "antd";
import _ from "lodash";

import { ReactComponent as PlusIcon } from "@assets/images/plus-with-bg.svg";
import { useTypedSelector } from "@hooks";
import { handleOfferModeller } from "@store/offers";
import { getCurrencyTotal, round } from "@utils";

const OfferSummary = () => {
  const dispatch = useDispatch();

  const {
    offerModeller: {
      offer_benefits,
      offer_lti,
      offer_sti,
      offer_fixed_cash,
      offer_sign_on_bonus,
    },
    currencyRate,
    currencyName,
    offer,
  } = useTypedSelector(state => state.offers);
  const readOnly = offer && offer?.status !== "DRAFTED" ? true : false;
  const currencyCode = currencyName?.split("(")[1]?.replace(")", "") || "";

  const { current_currency_total, proposed_currency_total } = getCurrencyTotal(
    offer_benefits,
    currencyRate,
    []
  );

  const labelColSpan = currencyCode ? 10 : 12;
  const currencyColSpan = currencyCode ? 7 : 12;

  const proposedStiTotal =
    (offer_fixed_cash?.proposed_total || 0) +
    (offer_sti?.proposed_total_bonus || 0);
  const currentStiTotal =
    (offer_fixed_cash?.current_total || 0) +
    (offer_sti?.current_bonus_target || 0);

  let percentageStiDifference = 0;

  if (proposedStiTotal > 0 && currentStiTotal > 0) {
    percentageStiDifference = (proposedStiTotal / currentStiTotal - 1) * 100;
  }

  const finalStiPercentage =
    percentageStiDifference > 0 ? percentageStiDifference.toFixed(0) : 0;

  const proposedLtiTotal =
    (proposedStiTotal || 0) + (offer_lti?.proposed_total_lti || 0);
  const currentLtiTotal =
    (currentStiTotal || 0) + (offer_lti?.current_total_lti || 0);

  let percentageLtiDifference = 0;

  if (proposedLtiTotal > 0 && currentLtiTotal > 0) {
    percentageLtiDifference = (proposedLtiTotal / currentLtiTotal - 1) * 100;
  }

  const finalLtiPercentage =
    percentageLtiDifference > 0 ? percentageLtiDifference.toFixed(0) : 0;

  const proposedBenefitsTotal =
    (proposedLtiTotal || 0) + (offer_benefits?.proposed_total_bonus || 0);
  const currentBenefintsTotal =
    (currentLtiTotal || 0) + (offer_benefits?.current_total_bonus || 0);

  let percentageBenefitDifference = 0;

  if (proposedBenefitsTotal > 0 && currentBenefintsTotal > 0) {
    percentageBenefitDifference =
      (proposedBenefitsTotal / currentBenefintsTotal - 1) * 100;
  }

  const finalBenefitPercentage =
    percentageBenefitDifference > 0
      ? percentageBenefitDifference.toFixed(0)
      : 0;

  return (
    <>
      <Row className="salary-offer">
        <Col className="salary-offer__parent-col" span={12}>
          <Row className="salary-offer__content-row__col">
            <Col className="salary-offer__content-row__col" span={labelColSpan}>
              <div className="salary-offer__content-row__field salary-offer__content-row__field--total">
                <label>Total Guaranteed Cash</label>
              </div>
              {/* <div className="salary-offer__content-row__field salary-offer__content-row__field--total" /> */}
            </Col>

            <Col
              className="salary-offer__content-row__col"
              span={currencyColSpan}
            >
              <div className="salary-offer__content-row__field salary-offer__content-row__field--total">
                <Input
                  className={`w-full shadow-md my-2 font-semibold text-sm outline-none border border-themeInputBorder bg-white px-3 py-3 rounded-md`}
                  readOnly
                  name="current_total"
                  value={offer_fixed_cash?.current_total || ""}
                  placeholder="Value here..."
                  size="large"
                />
              </div>
              {/* <div className="salary-offer__content-row__field salary-offer__content-row__field--total" /> */}
            </Col>

            {currencyCode ? (
              <Col className="salary-offer__content-row__col" span={7}>
                <div className="salary-offer__content-row__field salary-offer__content-row__field--total">
                  <Input
                    className={`w-full shadow-md my-2 font-semibold text-sm outline-none border border-themeInputBorder bg-white px-3 py-3 rounded-md`}
                    value={current_currency_total || ""}
                    value={
                      Number(offer_fixed_cash?.current_total* currencyRate!).toFixed(2) || ""
                    }
                    readOnly
                    placeholder="Value here..."
                    size="large"
                  />
                </div>
                {/* <div className="salary-offer__content-row__field salary-offer__content-row__field--total" /> */}
              </Col>
            ) : null}
          </Row>
        </Col>

        <Col className="salary-offer__parent-col" span={12}>
          <Row className="salary-offer__content-row__col">
            <Col className="salary-offer__content-row__col" span={labelColSpan}>
              <div className="salary-offer__content-row__field salary-offer__content-row__field--total">
                <label>Total Guaranteed Cash</label>
              </div>
            </Col>

            <Col
              className="salary-offer__content-row__col"
              span={currencyColSpan}
            >
              <div className="salary-offer__content-row__field salary-offer__content-row__field--total">
                <Input
                  className={`w-full shadow-md my-2 font-semibold text-sm outline-none border border-themeInputBorder bg-white px-3 py-3 rounded-md`}
                  readOnly
                  name="proposed_total"
                  value={offer_fixed_cash?.proposed_total || ""}
                  placeholder="Value here..."
                  size="large"
                  type="number"
                />
              </div>
              {/* <div className="salary-offer__content-row__field salary-offer__content-row__field--total" /> */}

              {/* <div className="salary-offer__content-row__field salary-offer__content-row__field--total">
                <Input
                  className={`w-full shadow-md my-2 font-semibold text-sm outline-none border border-themeInputBorder bg-white px-3 py-3 rounded-md`}
                  readOnly
                  value={
                    offer_fixed_cash?.proposed_total &&
                    offer_fixed_cash?.current_total
                      ? `${round(
                          (offer_fixed_cash?.proposed_total /
                            offer_fixed_cash?.current_total -
                            1) *
                            100
                        )}%` || ""
                      : ""
                  }
                  placeholder="Value here..."
                  size="large"
                />
                <div
                  className={`w-6/12 ml-4  text-white shadow-md my-2 font-semibold text-sm outline-none border border-themeInputBorder bg-themeBlue px-3 py-2 rounded-md`}
                >
                  {offer_fixed_cash?.proposed_total &&
                  offer_fixed_cash?.current_total
                    ? `${round(
                        (offer_fixed_cash?.proposed_total /
                          offer_fixed_cash?.current_total -
                          1) *
                          100
                      )}%` || ""
                    : "0%"}
                </div>
              </div> */}
            </Col>

            {currencyCode ? (
              <Col className="salary-offer__content-row__col" span={7}>
                <div className="salary-offer__content-row__field salary-offer__content-row__field--total">
                  <Input
                    className={`w-full shadow-md my-2 font-semibold text-sm outline-none border border-themeInputBorder bg-white px-3 py-3 rounded-md`}
                    // value={proposed_currency_total || ""}
                    value={
                      Number(offer_fixed_cash?.proposed_total * currencyRate!).toFixed(2) || ""
                    }
                    readOnly
                    placeholder="Value here..."
                    size="large"
                  />
                </div>
              </Col>
            ) : null}
          </Row>
        </Col>
      </Row>

      <Row className="salary-offer">
        <Col className="salary-offer__parent-col" span={12}>
          <Row className="salary-offer__content-row__col">
            <Col className="salary-offer__content-row__col" span={labelColSpan}>
              <div className="salary-offer__content-row__field salary-offer__content-row__field--total"></div>
            </Col>

            <Col
              className="salary-offer__content-row__col"
              span={currencyCode ? 14 : currencyColSpan}
            >
              <div className="salary-offer__content-row__field salary-offer__content-row__field--total"></div>
            </Col>
          </Row>
        </Col>

        <Col className="salary-offer__parent-col" span={12}>
          <Row className="salary-offer__content-row__col">
            <Col className="salary-offer__content-row__col" span={labelColSpan}>
              <div className="salary-offer__content-row__field salary-offer__content-row__field--total">
                <label>Difference</label>
              </div>
            </Col>

            <Col
              className="salary-offer__content-row__col"
              span={currencyCode ? 14 : currencyColSpan}
            >
              <div className="salary-offer__content-row__field salary-offer__content-row__field--total">
                <div
                  className={`w-full px-6   text-white shadow-md my-2 font-semibold text-center text-sm outline-none border border-themeInputBorder bg-themeBlue  py-2 rounded-md`}
                >
                  {offer_fixed_cash?.proposed_total &&
                  offer_fixed_cash?.current_total
                    ? `${round(
                        (offer_fixed_cash?.proposed_total /
                          offer_fixed_cash?.current_total -
                          1) *
                          100
                      )}%` || ""
                    : "0%"}
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="salary-offer">
        <Col className="salary-offer__parent-col" span={12}>
          <Row className="salary-offer__content-row">
            <Col className="salary-offer__content-row__col" span={labelColSpan}>
              <div className="salary-offer__content-row__field salary-offer__content-row__field--totalBB">
                <label>Target Total Cash</label>
              </div>
            </Col>
            <Col
              className="salary-offer__content-row__col"
              span={currencyColSpan}
            >
              <div className="salary-offer__content-row__field salary-offer__content-row__field--totalBB">
                <Input
                  readOnly
                  value={currentStiTotal || ""}
                  name="current_total_bonus"
                  placeholder="Value here..."
                  size="large"
                />
              </div>
            </Col>

            {currencyCode ? (
              <Col className="salary-offer__content-row__col" span={7}>
                <div className="salary-offer__content-row__field salary-offer__content-row__field--totalBB ">
                  <Input
                    value={
                      Number(currentStiTotal * currencyRate!).toFixed(2) || ""
                    }
                    readOnly
                    placeholder="Value here..."
                    size="large"
                  />
                </div>
              </Col>
            ) : null}
          </Row>
        </Col>
        <Col className="salary-offer__parent-col" span={12}>
          <Row className="salary-offer__content-row">
            <Col className="salary-offer__content-row__col" span={labelColSpan}>
              <div className="salary-offer__content-row__field salary-offer__content-row__field--totalBB">
                <label>Target Total Cash</label>
              </div>
            </Col>

            <Col
              className="salary-offer__content-row__col"
              span={currencyColSpan}
            >
              <div className="salary-offer__content-row__field salary-offer__content-row__field">
                <Input
                  readOnly
                  value={proposedStiTotal || ""}
                  name="target_total_cash"
                  placeholder="Value here..."
                  size="large"
                />
              </div>
            </Col>

            {currencyCode ? (
              <Col className="salary-offer__content-row__col" span={7}>
                <div className="salary-offer__content-row__field salary-offer__content-row__field--totalBB ">
                  <Input
                    value={
                      Number(proposedStiTotal * currencyRate!).toFixed(2) || ""
                    }
                    readOnly
                    placeholder="Value here..."
                    size="large"
                  />
                </div>
              </Col>
            ) : null}
          </Row>
        </Col>
      </Row>

      <Row className="salary-offer">
        <Col className="salary-offer__parent-col" span={12}>
          <Row className="salary-offer__content-row">
            <Col className="salary-offer__content-row__col" span={labelColSpan}>
              <div className="salary-offer__content-row__field salary-offer__content-row__field--totalBB"></div>
            </Col>
            <Col
              className="salary-offer__content-row__col"
              span={currencyCode ? 14 : currencyColSpan}
            >
              <div className="salary-offer__content-row__field salary-offer__content-row__field--totalBB"></div>
            </Col>
          </Row>
        </Col>
        <Col className="salary-offer__parent-col" span={12}>
          <Row className="salary-offer__content-row">
            <Col className="salary-offer__content-row__col" span={labelColSpan}>
              <div className="salary-offer__content-row__field salary-offer__content-row__field--totalBB">
                <label>Target Cash Difference</label>
              </div>
            </Col>

            <Col
              className="salary-offer__content-row__col"
              span={currencyCode ? 14 : currencyColSpan}
            >
              <div className="salary-offer__content-row__field salary-offer__content-row__field--totalBB">
                <div
                  className={`w-full px-6 text-white shadow-md my-2 font-semibold text-center text-sm outline-none border border-themeInputBorder bg-themeBlue py-2 rounded-md`}
                >
                  {finalStiPercentage}%
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="salary-offer">
        <Col className="salary-offer__parent-col" span={12}>
          <Row className="salary-offer__content-row">
            <Col className="salary-offer__content-row__col" span={labelColSpan}>
              <div className="salary-offer__content-row__field salary-offer__content-row__field--total">
                <label>Total Direct Comp</label>
              </div>
            </Col>

            <Col
              className="salary-offer__content-row__col"
              span={currencyColSpan}
            >
              <div className="salary-offer__content-row__field salary-offer__content-row__field--total">
                <Input
                  readOnly
                  name="total_direct_comp"
                  placeholder="Value here..."
                  value={currentLtiTotal || ""}
                  size="large"
                />
              </div>
            </Col>

            {currencyCode ? (
              <Col className="salary-offer__content-row__col" span={7}>
                <div className="salary-offer__content-row__field salary-offer__content-row__field--total">
                  <Input
                    value={round(currentLtiTotal * currencyRate!) || ""}
                    readOnly
                    placeholder="Value here..."
                    size="large"
                  />
                </div>
              </Col>
            ) : null}
          </Row>
        </Col>

        <Col className="salary-offer__parent-col" span={12}>
          <Row className="salary-offer__content-row">
            <Col className="salary-offer__content-row__col" span={labelColSpan}>
              <div className="salary-offer__content-row__field salary-offer__content-row__field--total">
                <label>Total Direct Comp</label>
              </div>
            </Col>
            <Col
              className="salary-offer__content-row__col"
              span={currencyColSpan}
            >
              <div className="salary-offer__content-row__field salary-offer__content-row__field--total">
                <Input
                  readOnly
                  name="total_direct_comp"
                  placeholder="Value here..."
                  value={proposedLtiTotal || ""}
                  size="large"
                />
              </div>
            </Col>

            {currencyCode ? (
              <Col className="salary-offer__content-row__col" span={7}>
                <div className="salary-offer__content-row__field salary-offer__content-row__field--total">
                  <Input
                    value={round(proposedLtiTotal * currencyRate!) || ""}
                    readOnly
                    placeholder="Value here..."
                    size="large"
                  />
                </div>
              </Col>
            ) : null}
          </Row>
        </Col>
      </Row>

      <Row className="salary-offer">
        <Col className="salary-offer__parent-col" span={12}>
          <Row className="salary-offer__content-row">
            <Col className="salary-offer__content-row__col" span={labelColSpan}>
              <div className="salary-offer__content-row__field salary-offer__content-row__field--total"></div>
            </Col>

            <Col
              className="salary-offer__content-row__col"
              span={currencyCode ? 14 : currencyColSpan}
            >
              <div className="salary-offer__content-row__field salary-offer__content-row__field--total"></div>
            </Col>
          </Row>
        </Col>

        <Col className="salary-offer__parent-col" span={12}>
          <Row className="salary-offer__content-row">
            <Col className="salary-offer__content-row__col" span={labelColSpan}>
              <div className="salary-offer__content-row__field salary-offer__content-row__field--total">
                <label>Direct Comp Difference</label>
              </div>
            </Col>
            <Col
              className="salary-offer__content-row__col"
              span={currencyCode ? 14 : currencyColSpan}
            >
              <div className="salary-offer__content-row__field salary-offer__content-row__field--total">
                <div
                  className={`w-full px-6 text-white shadow-md my-2 font-semibold text-center text-sm outline-none border border-themeInputBorder bg-themeBlue py-2 rounded-md`}
                >
                  {finalLtiPercentage}%
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="salary-offer">
        <Col className="salary-offer__parent-col" span={12}>
          <Row className="salary-offer__content-row salary-offer__content-row--last">
            {/* Current-Cols */}
            <Col className="salary-offer__content-row__col" span={labelColSpan}>
              <div className="salary-offer__content-row__field salary-offer__content-row__field--total">
                <label>Total Remuneration</label>
              </div>
            </Col>

            <Col
              className="salary-offer__content-row__col"
              span={currencyColSpan}
            >
              <div className="salary-offer__content-row__field salary-offer__content-row__field--total">
                <Input
                  readOnly
                  value={currentBenefintsTotal || ""}
                  name="current_total_lti"
                  placeholder="Value here..."
                  size="large"
                />
              </div>
            </Col>

            {currencyCode ? (
              <Col className="salary-offer__content-row__col" span={7}>
                <div className="salary-offer__content-row__field salary-offer__content-row__field--total">
                  <Input
                    value={round(currentBenefintsTotal * currencyRate!) || ""}
                    readOnly
                    placeholder="Value here..."
                    size="large"
                  />
                </div>
              </Col>
            ) : null}
          </Row>
        </Col>
        <Col className="salary-offer__parent-col" span={12}>
          <Row className="salary-offer__content-row salary-offer__content-row--last">
            {/* Current-Cols */}
            <Col className="salary-offer__content-row__col" span={labelColSpan}>
              <div className="salary-offer__content-row__field salary-offer__content-row__field--total">
                <label>Total Remuneration</label>
              </div>
            </Col>

            <Col
              className="salary-offer__content-row__col"
              span={currencyColSpan}
            >
              <div className="salary-offer__content-row__field salary-offer__content-row__field--total">
                <Input
                  readOnly
                  name="proposed_total_lti"
                  value={proposedBenefitsTotal || ""}
                  // value={offer_benefits?.proposed_total_bonus || ""}
                  placeholder="Value here..."
                  size="large"
                />
              </div>
            </Col>

            {currencyCode ? (
              <Col className="salary-offer__content-row__col" span={7}>
                <div className="salary-offer__content-row__field salary-offer__content-row__field--total">
                  <Input
                    value={round(proposedBenefitsTotal * currencyRate!) || ""}
                    readOnly
                    placeholder="Value here..."
                    size="large"
                  />
                </div>
              </Col>
            ) : null}
          </Row>
        </Col>
      </Row>

      <Row className="salary-offer">
        <Col className="salary-offer__parent-col" span={12}>
          <Row className="salary-offer__content-row salary-offer__content-row--last">
            {/* Current-Cols */}
            <Col className="salary-offer__content-row__col" span={labelColSpan}>
              <div className="salary-offer__content-row__field salary-offer__content-row__field--total"></div>
            </Col>

            <Col
              className="salary-offer__content-row__col"
              span={currencyCode ? 14 : currencyColSpan}
            >
              <div className="salary-offer__content-row__field salary-offer__content-row__field--total"></div>
            </Col>
          </Row>
        </Col>
        <Col className="salary-offer__parent-col" span={12}>
          <Row className="salary-offer__content-row salary-offer__content-row--last">
            {/* Current-Cols */}
            <Col className="salary-offer__content-row__col" span={labelColSpan}>
              <div className="salary-offer__content-row__field salary-offer__content-row__field--total">
                <label>Remuneration Difference</label>
              </div>
            </Col>

            <Col
              className="salary-offer__content-row__col"
              span={currencyCode ? 14 : currencyColSpan}
            >
              <div className="salary-offer__content-row__field salary-offer__content-row__field--total">
                <div
                  className={`w-full px-6   text-white shadow-md my-2 font-semibold text-center text-sm outline-none border border-themeInputBorder bg-themeBlue  py-2 rounded-md`}
                >
                  {finalBenefitPercentage}%
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row className="salary-offer">
        <Col className="salary-offer__parent-col" span={12}>
          <Row className="salary-offer__content-row salary-offer__content-row--last">
            {/* Current-Cols */}
            <Col className="salary-offer__content-row__col" span={labelColSpan}>
              <div className="salary-offer__content-row__field salary-offer__content-row__field--total">
                {/* <label>Total Package Payment</label> */}
                <label>One Time Payment</label>
              </div>
            </Col>

            <Col
              className="salary-offer__content-row__col"
              span={currencyColSpan}
            >
              <div className="salary-offer__content-row__field salary-offer__content-row__field--total">
                <Input
                  readOnly
                  value={offer_sign_on_bonus?.current_total_bonus || ""}
                  name="current_total_lti"
                  placeholder="Value here..."
                  size="large"
                />
              </div>
            </Col>

            {currencyCode ? (
              <Col className="salary-offer__content-row__col" span={7}>
                <div className="salary-offer__content-row__field salary-offer__content-row__field--total">
                  <Input
                    value={
                      Number(offer_sign_on_bonus?.current_total_bonus * currencyRate!).toFixed(2) || ""
                    }
                    readOnly
                    placeholder="Value here..."
                    size="large"
                  />
                </div>
              </Col>
            ) : null}
          </Row>
        </Col>
        <Col className="salary-offer__parent-col" span={12}>
          <Row className="salary-offer__content-row salary-offer__content-row--last">
            {/* Current-Cols */}
            <Col className="salary-offer__content-row__col" span={labelColSpan}>
              <div className="salary-offer__content-row__field salary-offer__content-row__field--total">
                {/* <label>Total Package Payment</label> */}
                <label>One Time Payment</label>
              </div>
            </Col>

            <Col
              className="salary-offer__content-row__col"
              span={currencyColSpan}
            >
              <div className="salary-offer__content-row__field salary-offer__content-row__field--total">
                <Input
                  readOnly
                  name="proposed_total_lti"
                  value={offer_sign_on_bonus?.proposed_total_bonus || ""}
                  placeholder="Value here..."
                  size="large"
                />
              </div>
            </Col>

            {currencyCode ? (
              <Col className="salary-offer__content-row__col" span={7}>
                <div className="salary-offer__content-row__field salary-offer__content-row__field--total">
                  <Input
                    value={
                      Number(offer_sign_on_bonus?.proposed_total_bonus * currencyRate!).toFixed(2) || ""
                    }
                    readOnly
                    placeholder="Value here..."
                    size="large"
                  />
                </div>
              </Col>
            ) : null}
          </Row>
        </Col>
      </Row>

      <Row className="salary-offer">
        <Col className="salary-offer__parent-col" span={12}>
          <Row className="salary-offer__content-row salary-offer__content-row--last">
            {/* Current-Cols */}
            <Col className="salary-offer__content-row__col" span={labelColSpan}>
              <div className="salary-offer__content-row__field salary-offer__content-row__field--total"></div>
            </Col>

            <Col
              className="salary-offer__content-row__col"
              span={currencyCode ? 14 : currencyColSpan}
            >
              <div className="salary-offer__content-row__field salary-offer__content-row__field--total"></div>
            </Col>
          </Row>
        </Col>
        <Col className="salary-offer__parent-col" span={12}>
          <Row className="salary-offer__content-row salary-offer__content-row--last">
            {/* Current-Cols */}
            <Col className="salary-offer__content-row__col" span={labelColSpan}>
              <div className="salary-offer__content-row__field salary-offer__content-row__field--total">
                {/* <label>Total Package Difference</label> */}
                <label> One Time Payment Difference</label>
              </div>
            </Col>

            <Col
              className="salary-offer__content-row__col"
              span={currencyCode ? 14 : currencyColSpan}
            >
              <div className="salary-offer__content-row__field salary-offer__content-row__field--total">
                <div
                  className={`w-full px-6   text-white shadow-md my-2 font-semibold text-center text-sm outline-none border border-themeInputBorder bg-themeBlue  py-2 rounded-md`}
                >
                  {offer_sign_on_bonus?.proposed_total_bonus &&
                  offer_sign_on_bonus?.current_total_bonus
                    ? `${round(
                        (offer_sign_on_bonus?.proposed_total_bonus /
                          offer_sign_on_bonus?.current_total_bonus -
                          1) *
                          100
                      )}%` || ""
                    : "0%"}
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default OfferSummary;
