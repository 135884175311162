import React from "react";
import {
  FaUsers,
  FaBuilding,
  FaUserShield,
  FaCog,
  FaIndustry,
  FaBriefcase,
  FaList,
  FaTachometerAlt,
  FaDollarSign,
  FaMoneyBillAlt,
  FaChartLine,
  FaFileInvoiceDollar,
  FaSitemap,
  FaGlobeAmericas,
  FaFileSignature,
  FaDraftingCompass,
} from "react-icons/fa";
import { paths, permissions } from "@router";
import { IJobGrade } from "@/types";

const {
  admin: {
    users: { companies, sub_admins },
    dashboard,
    settings: { sectors, job_function, grade_map_table },
  },
  client,
} = paths;

export interface IConfig {
  title: string;
  icon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  path: string | Array<string>;
  routingPath?: string;
  sub?: Array<IConfig>;
  permission?: string | Array<string>;
  client?: boolean;
  disabled?: boolean;
  fallbackMessage?: string;
  iconStyle?: React.CSSProperties;
  iconClassName?: string;
}

export const admin_config: Array<IConfig> = [
  {
    title: "Dashboard",
    icon: FaTachometerAlt,
    path: dashboard.dashboard,
  },
  {
    title: "Users",
    icon: FaUsers,
    path: [
      companies.listing,
      companies.create,
      sub_admins.create,
      sub_admins.listing,
    ],
    sub: [
      {
        title: "Companies",
        icon: FaBuilding,
        path: "/companies",
      },
      {
        title: "Sub-Admins",
        icon: FaUserShield,
        path: sub_admins.listing,
        permission: permissions.VIEW_TOM_USER,
      },
    ],
  },
  {
    title: "Settings",
    icon: FaCog,
    path: [
      sectors.listing,
      sectors.industry,
      job_function.listing,
      job_function.sub_job_function,
      grade_map_table.listing,
      grade_map_table.create_grade_company,
    ],
    sub: [
      {
        title: "Sectors, Industry & Sub-Industry",
        icon: FaIndustry,
        path: sectors.listing,
        permission: permissions.VIEW_SECTOR,
      },
      {
        title: "Job Function & Sub-Function",
        icon: FaBriefcase,
        path: job_function.listing,
        permission: permissions.VIEW_JOB_SUB_FUNCTION,
      },
      {
        title: "Grade map table",
        icon: FaList,
        path: grade_map_table.listing,
      },
    ],
  },
];

export const client_config = (
  jobGrades: IJobGrade[],
  company_id: string
): Array<IConfig> => [
  {
    title: "Dashboard",
    icon: FaTachometerAlt,
    path: client.dashboard,
    client: true,
  },
  {
    title: "Compensation System",
    icon: FaSitemap,
    client: true,
    path: [
      client.grade_setup,
      client.salary_range,
      client.cash_allowances,
      client.short_term_ip.plans,
      client.short_term_ip.incentives,
      client.long_term_ip.plans,
      client.long_term_ip.incentives,
      client.internal_payroll_data,
      client.market_data,
    ],
    sub: [
      {
        title: "Grade Setup",
        icon: FaList,
        path: client.grade_setup,
        permission: permissions.VIEW_JOB_GRADE,
      },
      {
        title: "Salary Range",
        icon: FaDollarSign,
        path: client.salary_range,
        permission: permissions.VIEW_SALARY_RANGE,
      },
      {
        title: "Cash Allowances",
        icon: FaMoneyBillAlt,
        path: client.cash_allowances,
        permission: permissions.VIEW_CASH_ALLOWANCE,
      },
      {
        title: `Short Term Incentives`,
        icon: FaChartLine,
        path: client.short_term_ip.plans,
        permission: permissions.VIEW_COMPANY_STI,
      },
      {
        title: "Long Term Incentives",
        icon: FaFileInvoiceDollar,
        path: client.long_term_ip.plans,
        permission: permissions.VIEW_COMPANY_LTI,
      },
      {
        title: "Internal Payroll",
        icon: FaFileSignature,
        path: client.internal_payroll_data,
        permission: permissions.VIEW_INTERNAL_PAYROLL,
      },
      {
        title: "Market Data",
        icon: FaGlobeAmericas,
        path: client.market_data,
        permission: permissions.VIEW_MARKET_DATA,
      },
    ],
  },
  {
    disabled: jobGrades?.length ? false : true,
    fallbackMessage: "Please create any grade to access offers.",
    title: "Offer Modeller",
    icon: FaDraftingCompass,
    client: true,
    path: [
      client.offers.active,
      client.offers.past,
      client.offers.drafts,
      client.offers.create,
    ],
    sub: [
      {
        title: "Active",
        icon: FaFileSignature,
        path: client.offers.active,
        permission: permissions.VIEW_OFFER,
      },
      // {
      //   title: "Past",
      //   icon: FaFileSignature,
      //   path: client.offers.past,
      //   permission: permissions.VIEW_OFFER,
      // },
      // {
      //   title: "Drafts",
      //   icon: FaFileSignature,
      //   path: client.offers.drafts,
      //   permission: permissions.VIEW_OFFER,
      // },
    ],
  },
];